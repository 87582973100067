import { memo, useCallback } from 'react';
import { FastField, useField } from 'formik';
import EditorRich from '../../../EditorRich';

function EditorRichFormik(props) {
  const { plainContentsFieldName, placeholder } = props;
  const [, meta, helpers] = useField(props);
  const { initialValue } = meta;
  const { setValue } = helpers;

  const [, , plainHelpers] = useField(plainContentsFieldName);
  const { setValue: setValuePlain } = plainHelpers;

  const onChange = useCallback(
    (lexicalContents, plainContents) => {
      setValue(lexicalContents, false);
      setValuePlain(plainContents);
    },
    [setValue, setValuePlain]
  );

  return (
    <>
      <EditorRich
        initialValue={initialValue}
        onChange={onChange}
        placeholder={placeholder}
      />

      {plainContentsFieldName ? (
        <FastField name={plainContentsFieldName} type="hidden" />
      ) : null}
    </>
  );
}

export default memo(EditorRichFormik);
