/* eslint-disable react/no-multi-comp */
import './index.css';
import { memo, useEffect, useState } from 'react';
import ExampleTheme from './themes/ExampleTheme';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { TRANSFORMERS } from '@lexical/markdown';
import { $rootTextContent } from '@lexical/text';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useDebouncedCallback } from 'use-debounce';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';

function Placeholder({ message }) {
  return <div className="editor-placeholder">{message}</div>;
}

const editorConfig = {
  // The editor theme
  theme: ExampleTheme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    LinkNode,
  ],
};

function LoadSaveHelperPlugin({ initialValue, onChange, debounceTime = 100 }) {
  const [editor] = useLexicalComposerContext();
  const [isFirstRender, setIsFirstRender] = useState(true);

  // Set initial value
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);

      if (initialValue) {
        const initialEditorState = editor.parseEditorState(initialValue);
        editor.setEditorState(initialEditorState);
      }
    }
  }, [isFirstRender, initialValue, editor]);

  // we use debounce to avoid too many updates (performance)
  const onChangeEditorState = useDebouncedCallback((editorState) => {
    if (onChange) {
      const plainContents = editorState.read($rootTextContent);
      const lexicalContents = JSON.stringify(editorState.toJSON());
      onChange(lexicalContents, plainContents);
    }
  }, debounceTime);

  return (
    <OnChangePlugin
      ignoreInitialChange={false}
      ignoreSelectionChange
      onChange={onChangeEditorState}
    />
  );
}

function EditorRich({
  placeholder,
  initialValue,
  onChange,
  debounceTime = 100,
}) {
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container">
        <ToolbarPlugin />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder message={placeholder} />}
          />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <ListPlugin />
          <LinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          <LoadSaveHelperPlugin
            debounceTime={debounceTime}
            initialValue={initialValue}
            onChange={onChange}
          />
        </div>
      </div>
    </LexicalComposer>
  );
}

export default memo(EditorRich);
