import { memo } from 'react';
import useAddContact from './useAddContact';

function ButtonAddContact() {
  const { addContact } = useAddContact();

  return (
    <button
      className="py-2 px-4 lg:px-6 bg-primary text-white rounded font-medium flex items-center justify-center focus:ring-2 focus:ring-blue-900"
      onClick={addContact}
      type="button"
    >
      Add Contact
    </button>
  );
}

export default memo(ButtonAddContact);
