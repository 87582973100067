import {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ErrorMessage, Form, Formik } from 'formik';
import * as yup from 'yup';

import Input from '../../../../shared-components/Form/Input';
import SelectFormik from '../../../../shared-components/Form/Input/SelectFormik';
import ModalInfo from '../../../../shared-components/ModalInfo';
import ChrevronLeft from './../../../../assets/images/icon-chevron-left.svg';
import useEffectOnce from '../../../../utils/useEffectOnce';
import ActionButtons from '../../../../shared-components/Form/ActionButtons';
import Tabs from '../../../../shared-components/Form/Tabs';
import RadioFormik from '../../../../shared-components/Form/Input/RadioFormik';
import DateFormik from '../../../../shared-components/Form/Input/DateFormik';
import statesUS from '../../../../utils/statesUS';
import { ApiContext } from '../../../../contexts/api';
import FormSkeleton from '../../../../shared-components/Form/FormSkeleton';
import AnimatedScreen from '../../../../shared-components/AnimatedScreen';
import AvatarInput from '../../../../shared-components/Form/AvatarInput';
import { GlobalContext } from '../../../../contexts/global';
import { getBoolean } from '../../../../utils/apiNormalization';
import EditorRichFormik from '../../../../shared-components/Form/Input/EditorRichFormik';
import QuestionGroupItem from './QuestionGroupItem';
import ChangelogCard from './ChangelogCard';
import { sleep } from '../../../../utils/helpers';
import ContactForm from './ContactForm';

function PatientFormScreen() {
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingNotes, setIsSavingNotes] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [questionGroups, setQuestionGroups] = useState([]);
  const [patient, setPatient] = useState();
  const [noteTemplates, setNoteTemplates] = useState([]);
  const [modal, setModal] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeNoteTabIndex, setActiveNoteTabIndex] = useState(0);
  const inputAutoFocusRef = useRef();
  const api = useContext(ApiContext);
  const globalContext = useContext(GlobalContext);

  const patientId = params.patientId ?? api?.user?.patient?.id;
  const agencyId = params.agencyId ?? api?.user?.agency?.id;
  const isEditing = !!patientId;
  const isPatientsMenu = !agencyId;

  const role = useMemo(() => api?.user.role, [api]);
  const isAdmin = useMemo(
    () => 'super-admin' === role || 'admin' === role,
    [role]
  );
  const isAgency = useMemo(() => 'agency-admin' === role, [role]);
  const isPatient = useMemo(() => 'patient' === role, [role]);

  const pageTitle = isEditing ? 'Edit Patient' : 'Add New Patient';

  const fetchPatient = async (id) => {
    const apiResult = await api.http.get(`/admin/patients/${id}?has-notes=1`);
    setPatient(apiResult);
  };

  const savePatient = async ({ id, ...params }) => {
    const baseUrl = '/admin/patients';
    const apiResult = id
      ? await api.http.put(`${baseUrl}/${id}`, params)
      : await api.http.post(baseUrl, params);
    setPatient(apiResult);
  };

  const fetchNoteTemplates = async () => {
    const apiResult = await api.http.get('/admin/note-templates');
    setNoteTemplates(apiResult);
  };

  const fetchAgencies = async () => {
    const apiResult = await api.http.get('/admin/agencies');
    setAgencies(apiResult);
  };

  const fetchAgency = async (agencyId) => {
    const apiResult = await api.http.get(`/admin/agencies/${agencyId}`);
    return apiResult;
  };

  const fetchQuestionGroups = async () => {
    const apiResult = await api.http.get('/admin/question-groups');
    setQuestionGroups(apiResult);
  };

  const saveNotes = useCallback(
    async (patientId, params) => {
      const baseUrl = `/admin/patients/${patientId}/notes`;
      const apiResult = await api.http.put(baseUrl, params);
      return apiResult;
    },
    [api]
  );

  const closeModal = () => {
    setModal(null);
    inputAutoFocusRef.current.focus();
  };

  const openErrorModal = useCallback((message) => {
    setModal({
      isError: true,
      message,
      onClose: closeModal,
    });
  }, []);

  const urlBack = useMemo(() => {
    if (isAdmin) {
      if (isPatientsMenu) {
        const url = isEditing
          ? `/admin/patients/${patientId}/details`
          : `/admin/patients`;
        return url;
      }

      const url = isEditing
        ? `/admin/agencies/${agencyId}/patients/${patientId}/details`
        : `/admin/agencies/${agencyId}/details`;
      return url;
    }

    if (isAgency) {
      const url = isEditing ? `/admin/patients/${patientId}/details` : `/`;
      return url;
    }

    if (isPatient) {
      return '/admin/patient';
    }

    return '/';
  }, [
    agencyId,
    isAdmin,
    isAgency,
    isEditing,
    isPatient,
    isPatientsMenu,
    patientId,
  ]);

  const goBack = () => {
    navigate(urlBack);
  };

  useEffect(() => {
    const setGlobalToolbar = () => {
      globalContext.setGlobalTitle(pageTitle);
      globalContext.setUrlBack(urlBack);
      globalContext.setEnabledSearch(false);
    };
    setGlobalToolbar();
  }, [globalContext, pageTitle, urlBack]);

  const contacts = useMemo(() => {
    if (isLoading) {
      return [];
    }

    if (isEditing) {
      return patient?.contacts.map((contact) => ({
        id: `${contact.id}`,
        name: contact.name ?? '',
        email: contact.email ?? '',
        phone: contact.phone ?? '',
        relationship: contact.relationship ?? '',
      }));
    }

    return [
      {
        newContactKey: 0,
        id: '',
        name: '',
        email: '',
        phone: '',
        relationship: '',
      },
    ];
  }, [isLoading, isEditing, patient?.contacts]);

  const onScreenLoad = async () => {
    try {
      if (isAdmin) {
        await fetchAgencies();
      }

      if (isAgency) {
        const agency = await fetchAgency(agencyId);
        setAgencies([agency]);
      }

      if (isPatient) {
        setAgencies([api?.user?.agency]);
      }

      await fetchQuestionGroups();
      await fetchNoteTemplates();

      if (isEditing) {
        await fetchPatient(patientId);
      }

      setIsLoading(false);
    } catch (message) {
      setModal({
        isError: true,
        message,
        onClose: goBack,
      });
    }
  };

  useEffectOnce(() => {
    onScreenLoad();

    return () => api.http.cancelAll();
  });

  const validationSchema = useMemo(() => {
    const requiredText = 'This is a required field.';
    return yup.object().shape({
      is_editing: yup.boolean(),
      id: yup.number().positive().integer(),
      agency_id: yup
        .number()
        .positive()
        .integer()
        .required(requiredText)
        .typeError(requiredText),
      // patient_code: yup.string().trim().min(2).required(requiredText),
      patient_code: yup
        .number()
        .positive()
        .integer()
        .required(requiredText)
        .typeError('You must specify a number.'),
      first_name: yup.string().trim().min(2).required(requiredText),
      last_name: yup.string().trim().min(2).required(requiredText),
      email: yup.string().email('Invalid Email!').required(requiredText),
      password: yup
        .string()
        .min(6)
        .when('is_editing', {
          is: false,
          then: yup.string().required('Must enter password!'),
        }),
      password_confirmation: yup
        .string()
        .when('is_editing', {
          is: false,
          then: yup.string().required('Must enter confirmation!'),
        })
        .test('passwords-match', 'Passwords must match!', function (value) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.parent.password === value;
        }),
      address_1: yup.string().trim().min(2).required(requiredText),
      address_2: yup.string().trim(),
      city: yup.string().trim().min(2).required(requiredText),
      state: yup.string().trim().min(2).max(2).required(requiredText),
      zipcode: yup
        .string()
        .trim()
        .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must be 5 or 9 digits')
        .required(requiredText),
      dob: yup.date('Invalid date!').required(requiredText),
      gender: yup.string().trim().min(2).required(requiredText),
      enabled_question_ids: yup
        .array()
        .min(1, 'You must assign at least one question to the patient.')
        .of(yup.number().positive().integer()),
      contacts: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().trim().min(2).required(requiredText),
            relationship: yup.string().trim().min(2).required(requiredText),
            email: yup.string().email('Invalid Email!').required(requiredText),
            phone: yup.string().trim().required(requiredText),
          })
        )
        .min(1, 'You must add at least one contact.'),
    });
  }, []);

  const questionsSettings = useMemo(() => {
    const questions = questionGroups.flatMap((group) => group.questions);

    const questionsWithIdKey = [];

    // global settings for questions
    questions.forEach((question) => {
      questionsWithIdKey[question.id] = {
        id: question.id,
        type: question.type,
        is_using_global_settings: true,
      };
    });

    // per-patient settings for assigned questions
    if (isEditing && patient?.patient_questions) {
      patient.patient_questions.forEach((patientQuestion) => {
        const { question } = patientQuestion;
        const isUsingGlobalSettings = patientQuestion.is_using_global_settings;

        const settings = isUsingGlobalSettings
          ? question.global_alert_settings
          : patientQuestion.custom_alert_settings;

        questionsWithIdKey[question.id] = {
          id: question.id,
          type: question.type,
          is_using_global_settings: isUsingGlobalSettings,
          is_alert_enabled: settings?.is_alert_enabled,
          alert_condition_operator: settings?.alert_condition_operator ?? '',
          alert_condition_value1: settings?.alert_condition_value1 ?? '',
          alert_condition_value2: settings?.alert_condition_value2 ?? '',
        };
      });
    }

    return questionsWithIdKey;
  }, [questionGroups, isEditing, patient?.patient_questions]);

  const notes = useMemo(() => {
    if (isLoading) {
      return null;
    }

    if (isEditing) {
      return patient?.notes;
    }

    // add missing "content_plain" and other fields
    return noteTemplates.map((template) => {
      // eslint-disable-next-line no-unused-vars
      const { id, ...rest } = template;

      return {
        ...rest,
        content_plain: '',
        changelogs: [],
      };
    });
  }, [isEditing, patient, noteTemplates, isLoading]);

  const initialValues = useMemo(
    () => ({
      is_editing: isEditing,
      id: patientId,
      agency_id: isEditing ? patient?.agency?.id : parseInt(agencyId),
      patient_code: isEditing ? patient?.patient_code : '',
      first_name: isEditing ? patient?.user?.first_name : '',
      last_name: isEditing ? patient?.user?.last_name : '',
      email: isEditing ? patient?.user?.email : '',
      password: '',
      password_confirmation: '',
      address_1: isEditing ? patient?.address_1 : '',
      address_2: patient?.address_2 ?? '',
      city: isEditing ? patient?.city : '',
      state: isEditing ? patient?.state : '',
      zipcode: isEditing ? patient?.zipcode : '',
      dob: isEditing ? new Date(`${patient?.dob}T00:00`) : '',
      gender: isEditing ? patient?.gender : 'other',
      contacts: contacts,
      enabled_question_ids: isEditing
        ? patient?.patient_questions.map((item) => item.question.id + '')
        : [],
      questions_settings: questionsSettings,
      notes: notes,
    }),
    [
      isEditing,
      patientId,
      agencyId,
      patient,
      questionsSettings,
      notes,
      contacts,
    ]
  );

  const submitForm = async (values, { setErrors }) => {
    console.log(values);

    const cloneValues = (values) => {
      const cloned = { ...values };
      cloned.questions_settings = { ...cloned.questions_settings };
      cloned.notes = [...cloned.notes];
      cloned.contacts = [...cloned.contacts];
      return cloned;
    };

    const validateValues = (values) => {
      return validationSchema.cast(values);
    };

    const removeValuesIfBlank = (values) => {
      for (const field in values) {
        const isBlank = '' === `${values[field]}`.trim();
        if (isBlank) {
          delete values[field];
        }
      }
      return values;
    };

    const removeUncheckedQuestionSettings = (values) => {
      const allQuestionIds = questionsSettings.map((item) => item.id);
      const enabledQuestionIds = values.enabled_question_ids;
      const disabledQuestionIds = allQuestionIds.filter(
        (id) => !enabledQuestionIds.includes(id)
      );
      disabledQuestionIds.forEach((id) => {
        delete values.questions_settings[id];
      });

      // remove gaps in keys
      values.questions_settings = Object.values(values.questions_settings);

      // fix: remove empty objects (it shouldn't happen, but we have "undefined" values)
      values.questions_settings = values.questions_settings.filter(
        (item) => item
      );
      values.questions_settings = Object.values(values.questions_settings);

      return values;
    };

    const appendValuesToQuestionSettings = (values) => {
      values.questions_settings.forEach((settings, index) => {
        const isWeight = settings.type === 'weight';
        if (isWeight) {
          values.questions_settings[index].alert_condition_operator =
            'diff_outside_range';
          values.questions_settings[index].alert_condition_value1 =
            -settings.alert_condition_value2;
          return;
        }

        const isBoolean = settings.type === 'boolean';
        if (isBoolean) {
          values.questions_settings[index].alert_condition_operator =
            'boolean_eq';
          values.questions_settings[index].alert_condition_value1 = getBoolean(
            values.questions_settings[index].alert_condition_value1
          );
          values.questions_settings[index].alert_condition_value2 = null;
          return;
        }

        const isBloodPressure = settings.type === 'blood_pressure';
        if (isBloodPressure) {
          values.questions_settings[index].alert_condition_operator =
            'uppervalue_greaterthan_or_lowervalue_lessthan';
          return;
        }
      });
      return values;
    };

    const removeEmptyIdsFromContacts = (values) => {
      values.contacts.forEach((contact, index) => {
        if (contact.id === '') {
          delete values.contacts[index].id;
        }
      });

      return values;
    };

    const addDisplayOrderToContacts = (values) => {
      values.contacts.forEach((contact, index) => {
        values.contacts[index].display_order = index + 1;
      });

      return values;
    };

    try {
      await sleep(200); // wait for debouncers to finish (ex. EditorRich)

      const validValues = validateValues(cloneValues(values));
      removeUncheckedQuestionSettings(validValues);
      removeValuesIfBlank(validValues);
      appendValuesToQuestionSettings(validValues);
      removeEmptyIdsFromContacts(validValues);
      addDisplayOrderToContacts(validValues);

      console.log(validValues);

      await savePatient(validValues);

      setModal({
        message: 'Success!',
        onClose: goBack,
      });
    } catch (errors) {
      console.log(errors);

      const firstErrorMessage =
        'string' === typeof errors ? errors : Object.values(errors)[0];
      const isInternalError = 500 === api.http.getLastHttpStatus();
      const message =
        isInternalError || !firstErrorMessage
          ? 'There was an error processing the form.'
          : firstErrorMessage;
      openErrorModal(message);

      const firstErrorField = Object.keys(errors)[0];
      changeTabToField(firstErrorField);

      setErrors(errors);
    }
  };

  const getAgencySelectOptions = (agencies) => {
    return agencies.map((agency) => ({
      label: agency.name,
      value: agency.id,
    }));
  };
  const agencyOptions = useMemo(
    () => getAgencySelectOptions(agencies),
    [agencies]
  );

  const QuestionGroupList = ({ questionGroups }) => {
    return questionGroups.map((group) => (
      <QuestionGroupItem group={group} key={`questiongroup_${group.id}`} />
    ));
  };

  const changeTabToField = (fieldName) => {
    const findFieldTab = (fieldName) => {
      if (!fieldName) {
        return null;
      }

      const fieldTabsLookup = [
        [
          'patient_code',
          'first_name',
          'last_name',
          'email',
          'password',
          'password_confirmation',
          'agency_id',
          'address_1',
          'address_2',
          'city',
          'state',
          'zipcode',
        ],
        ['notes'],
        ['gender', 'dob'],
        ['questions'],
        ['contacts'],
      ];

      for (let pageI = 0; pageI < fieldTabsLookup.length; pageI++) {
        const fields = fieldTabsLookup[pageI];
        const hasFound = fields.indexOf(fieldName) !== -1;
        if (hasFound) {
          return pageI;
        }
      }
      return null;
    };
    const tabIndex = findFieldTab(fieldName);
    const hasFoundTab = null !== tabIndex;
    if (hasFoundTab) {
      setActiveTabIndex(tabIndex);
    }
  };

  const blankPasswordLabelInfo = isEditing ? (
    <span className="italic text-gray-400">
      (leave it blank if not changing)
    </span>
  ) : null;

  const genderOptions = [
    { label: 'Female', value: 'female' },
    { label: 'Male', value: 'male' },
    { label: 'Other', value: 'other' },
  ];

  const notesPills = useMemo(() => {
    if (!notes) {
      return null;
    }

    const pills = notes.map((note, index) => {
      const isActive = activeNoteTabIndex === index;
      const noteTitle = note.template ? note.template.title : note.title;
      return (
        <div
          className={`${
            isActive ? 'bg-blue-500' : 'bg-blue-100'
          } py-2 px-4 rounded cursor-pointer`}
          key={`note-pill-${note.title}`}
          onClick={() => setActiveNoteTabIndex(index)}
        >
          <span
            className={`${
              isActive ? 'text-gray-50' : 'text-gray-900'
            } text-sm font-semibold`}
          >
            {noteTitle}
          </span>
        </div>
      );
    });

    return <div className="pillsContainer flex flex-row gap-4">{pills}</div>;
  }, [activeNoteTabIndex, notes]);

  const noteEditorList = useMemo(() => {
    if (!notes) {
      return null;
    }

    const noteEditors = notes.map((note, index) => {
      const isActive = activeNoteTabIndex === index;
      return (
        <div
          className={isActive ? '' : 'hidden'}
          key={`note-editor-${note.title}`}
        >
          <Input
            containerstyle="mt-4 flex flex-col"
            label2="About this note"
            name={`notes[${index}].observation`}
            placeholder="About this note..."
          />

          <Input
            as={EditorRichFormik}
            containerstyle=""
            name={`notes[${index}].content_lexical`}
            placeholder="write something..."
            plainContentsFieldName={`notes[${index}].content_plain`}
          />

          <Input name={`notes[${index}].id`} type="hidden" />
        </div>
      );
    });

    return <div className="noteEditorList">{noteEditors}</div>;
  }, [notes, activeNoteTabIndex]);

  const historyPanel = useCallback((changelogs) => {
    const cards = changelogs.map((changelog) => (
      <ChangelogCard
        changelog={changelog}
        key={`card-history-${changelog.id}`}
      />
    ));

    const noCards = (
      <div className="flex flex-1">
        <p className="text-gray-500 italic">No history yet</p>
      </div>
    );

    return (
      <div className="historyPanel flex flex-col gap-4">
        {cards.length ? cards : noCards}
      </div>
    );
  }, []);

  const historyPanels = useMemo(() => {
    if (!notes) {
      return null;
    }

    const panels = notes.map((note, index) => {
      const isActive = activeNoteTabIndex === index;
      return (
        <div
          className={isActive ? '' : 'hidden'}
          key={`note-history-${note.title}`}
        >
          {historyPanel(note.changelogs)}
        </div>
      );
    });
    return panels;
  }, [notes, activeNoteTabIndex, historyPanel]);

  const onClickSaveNotes = useCallback(
    async (notes) => {
      try {
        // disable button
        setIsSavingNotes(true);

        // wait for debouncers to finish (ex. EditorRich)
        await sleep(200);

        // save on server
        const updatedNotes = await saveNotes(patient.id, { notes });

        // update patient state
        const clonedPatient = { ...patient };
        clonedPatient.notes = updatedNotes;
        setPatient(clonedPatient);

        // success message
        setModal({
          message: 'Success!',
          onClose: closeModal,
        });
      } catch (errors) {
        // error message
        console.log(errors);
        openErrorModal('Error saving notes');
      } finally {
        // re-enable button
        setIsSavingNotes(false);
      }
    },
    [openErrorModal, patient, saveNotes]
  );

  const getSkeletonOrForm = () => {
    if (isLoading || !contacts.length) {
      return <FormSkeleton />;
    }

    const countTabs = 5;
    const isLastTab = countTabs - 1 === activeTabIndex;
    const labelSubmit = !isLastTab
      ? 'Next'
      : isEditing
      ? 'Save Changes'
      : 'Create Patient';

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {({ errors, isSubmitting, values }) => (
          <Form id="form-patient">
            {/* Tabs. */}
            <Tabs
              activeTabIndex={activeTabIndex}
              classActive="text-primary border-primary font-extrabold"
              onSelect={(index) => setActiveTabIndex(index)}
            >
              {/* Tab Content 1. */}
              <div
                className="flex pb-20 flex-col max-w-xl"
                label="Patient Information"
              >
                {/* Section */}
                <h2 className="pt-10 text-2xl">Patient Information</h2>

                {isEditing ? (
                  <AvatarInput
                    avatarUrl={patient?.user?.avatar_url}
                    name={`${patient?.user?.first_name?.[0]}${patient?.user?.last_name?.[0]}`}
                    uploadUrl={`/admin/users/${patient?.user?.id}/avatar`}
                  />
                ) : null}

                <Input
                  autoFocus
                  // type="number"
                  innerRef={inputAutoFocusRef}
                  label="Patient Number"
                  name="patient_code"
                  placeholder="ex. 12345"
                  required
                />

                <Input
                  label="First Name"
                  name="first_name"
                  placeholder="ex. John"
                  required
                />

                <Input
                  label="Last Name"
                  name="last_name"
                  placeholder="ex. Smith"
                />

                {/* Section */}
                <h2 className="pt-10 text-2xl">Account Details</h2>

                <Input
                  label="Email"
                  name="email"
                  placeholder="ex. john@smith.com"
                  required
                  type="email"
                />

                <Input
                  label={<span>Password {blankPasswordLabelInfo}</span>}
                  name="password"
                  required={!isEditing}
                  type="password"
                />

                <Input
                  label={<span>Confirm Password {blankPasswordLabelInfo}</span>}
                  name="password_confirmation"
                  required={!isEditing}
                  type="password"
                />

                <Input
                  as={SelectFormik}
                  label="Agency"
                  name="agency_id"
                  options={agencyOptions}
                  required
                />

                {/* Section */}
                <h2 className="pt-10 text-2xl">Patient Address</h2>

                <Input label="Address Line 1" name="address_1" required />

                <Input label="Address Line 2" name="address_2" />

                <Input label="City" name="city" required />

                <Input
                  as={SelectFormik}
                  label="State"
                  name="state"
                  options={statesUS}
                  placeholder="-- Select State --"
                  required
                />

                <Input label="ZIP Code" name="zipcode" />
              </div>
              {/* /Tab Content 1.  */}

              {/* Tab Content 2. */}
              <div className="flex pb-20 flex-col" label="Notes">
                {/* Section */}
                {/* <h2 className="pt-10 text-2xl">Notes</h2> */}

                {/* Sub-tabs (pills) */}
                <div className="mt-8">{notesPills}</div>

                {/* Editors + History */}
                <div className="noteWrapper flex flex-row gap-8">
                  {/* Editors */}
                  <div className="noteEditorList max-w-[66%] w-full">
                    {noteEditorList}

                    {/* "Save notes" button */}
                    {isEditing ? (
                      <div className="noteEditorList--saveButton mt-8 flex flex-1">
                        <button
                          className="h-12 px-4 lg:px-6 bg-primary text-white rounded font-medium flex items-center justify-center focus:ring-2 focus:ring-blue-900"
                          disabled={isSavingNotes}
                          onClick={() => onClickSaveNotes(values.notes)}
                          type="button"
                        >
                          {isSavingNotes ? 'Saving...' : 'Save Notes'}
                        </button>
                      </div>
                    ) : null}
                  </div>

                  {/* History panels */}
                  <div className="noteHistoryList max-w-[33%] w-full">
                    <h3 className="mt-6 text-xl">History of Changes</h3>

                    <div className="mt-2 p-4 border rounded h-96 overflow-auto">
                      {historyPanels}
                    </div>
                  </div>
                </div>
              </div>

              {/* Tab Content 3. */}
              <div
                className="flex pb-20 flex-col max-w-xl"
                label="Patient Details"
              >
                {/* Section */}
                <h2 className="pt-10 text-2xl">Patient Details</h2>

                <Input
                  as={DateFormik}
                  label="Date Of Birth"
                  name="dob"
                  placeholder="MM/DD/YYYY"
                  required
                />

                <Input
                  as={RadioFormik}
                  label="Sex"
                  name="gender"
                  options={genderOptions}
                  radioContainerStyle="flex flex-1 gap-12"
                  required
                />
              </div>

              {/* Tab Content 4. */}
              <div
                className="flex pb-20 flex-col"
                label="Voice Health Questions"
              >
                {/* Section */}
                <h2 className="pt-10 text-2xl">Voice Health Questions</h2>

                <ErrorMessage
                  className="mt-2 ml-1 text-red-500 text-xs italic"
                  component="div"
                  name="questions"
                />
                <div className="flex flex-col 2xl:max-w-[50%] gap-11">
                  {/* <QuestionGroups questionGroups={questionGroups} /> */}
                  <QuestionGroupList questionGroups={questionGroups} />
                </div>
              </div>

              {/* Tab Content 5. */}
              <div className="flex pb-16 flex-col" label="Contacts">
                <h2 className="pt-10 text-2xl">Contacts</h2>

                <ContactForm />
              </div>
            </Tabs>
            {/* /Tabs. */}

            {/* Submit button: do not show on "Notes" tab */}
            {activeTabIndex !== 1 && (
              <ActionButtons
                formId="form-patient"
                isSubmitting={isSubmitting}
                labelSubmit={labelSubmit}
                onClickCancel={goBack}
                onClickSubmit={(event) => {
                  if (!isLastTab) {
                    setActiveTabIndex(activeTabIndex + 1);
                    event.preventDefault();
                    return false;
                  }

                  const isErrorsEmpty = Object.keys(errors).length === 0;

                  if (isErrorsEmpty) {
                    return true;
                  }

                  const firstErrorField = Object.keys(errors)[0];

                  if (firstErrorField === 'contacts') {
                    setActiveTabIndex(4);

                    openErrorModal('There are errors in your contacts.');

                    return false;
                  }

                  changeTabToField(firstErrorField);

                  const firstErrorMessage = Object.values(errors)[0];

                  if ('string' === typeof firstErrorMessage) {
                    openErrorModal(firstErrorMessage);
                  }

                  return false;
                }}
              />
            )}
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <AnimatedScreen>
      <div className="md:my-8 md:pb-12">
        {/* Error and Success Modal. */}
        <ModalInfo
          isError={modal?.isError}
          isOpened={!!modal}
          onClose={modal?.onClose}
          title={modal?.message}
        />

        {/* Card. */}
        <div className="flex flex-col px-5 md:px-14 pt-8 pb-16 md:rounded-lg bg-white shadow-custom">
          {/* Back button. */}
          <Link className="hidden md:flex flex-row items-center" to={urlBack}>
            <img alt="Back button" className="h-5" src={ChrevronLeft} />
            <h1 className="pl-6 text-2xl font-black uppercase">{pageTitle}</h1>
          </Link>

          {/* Form. */}
          {getSkeletonOrForm()}
        </div>
        {/* /Card. */}
      </div>
    </AnimatedScreen>
  );
}

export default memo(PatientFormScreen);
