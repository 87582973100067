import { memo } from 'react';
import { Field, useField } from 'formik';

function RadioFormik(props) {
  const {
    options,
    radioContainerStyle: containerStyle,
    radioLabelStyle: labelStyle,
  } = props;

  const [field] = useField(props);
  const { name } = field;

  const radioItems = options.map((option) => {
    return (
      <label
        className={`${labelStyle ?? ''}`}
        key={`radio_${name}_${option.value}`}
      >
        <Field name={name} type="radio" value={option.value} />
        <span className="ml-3">{option.label}</span>
      </label>
    );
  });

  return (
    <div
      aria-labelledby={name}
      className={`mt-4 flex flex-row text-lg ${containerStyle ?? ''}`}
      role="group"
    >
      {radioItems}
    </div>
  );
}

export default memo(RadioFormik);
