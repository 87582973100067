import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Header from '../../../../shared-components/Header';
import ActionBar from '../../../../shared-components/Header/ActionBar';
import PrimaryButton from '../../../../shared-components/Header/ActionBar/PrimaryButton';
import TitleBar from '../../../../shared-components/Header/TitleBar';
import ModalInfo from '../../../../shared-components/ModalInfo';
import useEffectOnce from '../../../../utils/useEffectOnce';
import Table from '../../../../shared-components/Table';
import Avatar from '../../../../shared-components/Table/Avatar';
import ButtonEdit from '../../../../shared-components/Table/ButtonEdit';
import Agency from '../../../../shared-components/Table/Agency';
import { ApiContext } from '../../../../contexts/api';
import AnimatedScreen from '../../../../shared-components/AnimatedScreen';
import { GlobalContext } from '../../../../contexts/global';
import TableCellRole from './TableCellRole';

function UserListScreen() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [filtered, setFiltered] = useState([]);
  const api = useContext(ApiContext);
  const globalContext = useContext(GlobalContext);

  const pageTitle = 'User List';

  const fetchUsers = async () => {
    const apiResult = await api.http.get('/admin/users');
    setUsers(apiResult);
  };

  const closeModal = () => {
    setModal(null);
  };

  useEffect(() => {
    const setGlobalToolbar = () => {
      globalContext.setGlobalTitle(pageTitle);
      globalContext.setUrlBack(null);
      globalContext.setEnabledSearch(true);
      globalContext.setSearchValue('');
    };
    setGlobalToolbar();
  }, [globalContext, pageTitle]);

  const onScreenLoad = async () => {
    try {
      await fetchUsers();
      setIsLoading(false);
    } catch (message) {
      setModal({
        isError: true,
        message,
        onClose: closeModal,
      });
    }
  };

  useEffectOnce(() => {
    onScreenLoad();

    return () => api.http.cancelAll();
  });

  useEffect(() => {
    setSearchValue(globalContext.searchValue);
  }, [globalContext.searchValue]);

  const onChangeSearch = useCallback(() => {
    const filtered = users.filter((user) => {
      const lowerSearchValue = searchValue.toLowerCase();

      const isOnFirstName = user.first_name
        .toLowerCase()
        .includes(lowerSearchValue);
      if (isOnFirstName) {
        return true;
      }

      const isOnLastName = user.last_name
        .toLowerCase()
        .includes(lowerSearchValue);
      if (isOnLastName) {
        return true;
      }

      const isOnEmail = user.email.toLowerCase().includes(lowerSearchValue);
      if (isOnEmail) {
        return true;
      }

      const isOnAgency = user.agency?.name
        .toLowerCase()
        .includes(lowerSearchValue);
      if (isOnAgency) {
        return true;
      }

      return false;
    });
    setFiltered(filtered);
  }, [users, searchValue]);

  useEffect(() => {
    onChangeSearch();
  }, [onChangeSearch, searchValue, users]);

  const headerRightSide = (
    <ActionBar>
      <PrimaryButton title="New Super-Admin" url="/admin/users/new" />
    </ActionBar>
  );

  const headerTitle = (
    <TitleBar
      enabledFilter={false}
      enabledSearch
      onSearchChange={setSearchValue}
      searchPlaceholder="Search by name, email, agency."
      title={pageTitle}
    />
  );

  const getTableRows = useCallback((users) => {
    return users.map((user) => [
      <Avatar key={user?.id} user={user} />,
      `${user?.last_name}, ${user?.first_name}`,
      <TableCellRole key={user?.id} title={user.role} />,
      user.email,
      <Agency agency={user.agency} key={user?.id} />,
      <ButtonEdit key={user?.id} to={`/admin/users/${user.id}/edit`} />,
    ]);
  }, []);
  const tableData = useMemo(
    () => getTableRows(filtered),
    [filtered, getTableRows]
  );

  const headers = [
    { label: '', className: 'w-auto', tdClassName: 'py-3 px-6' },
    { label: 'Name', className: 'w-auto' },
    { label: 'Role', className: 'w-auto', tdClassName: 'capitalize px-8 py-4' },
    { label: 'Email', className: 'w-auto' },
    { label: 'Agency', className: 'w-auto', tdClassName: 'py-3 px-6 max-w-xs' },
    { label: 'Actions', className: 'w-auto text-center' },
  ];

  return (
    <AnimatedScreen>
      <div className="px-3 md:px-0 md:pb-12">
        <Header leftSide={headerTitle} rightSide={headerRightSide} />

        {/* Error and Success Modal. */}
        <ModalInfo
          isError={modal?.isError}
          isOpened={!!modal}
          onClose={modal?.onClose}
          title={modal?.message}
        />

        {/* Main Area. */}
        <div className="my-8 pb-12">
          <Table data={tableData} headers={headers} isLoading={isLoading} />
        </div>
      </div>
    </AnimatedScreen>
  );
}

export default memo(UserListScreen);
