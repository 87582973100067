import { memo, useCallback, useEffect, useRef } from 'react';

function MenuDialog(props) {
  const {
    children,
    isOpened,
    onClose,
    toggleRef,
    classWrapper,
    trianglePosition,
  } = props;

  const rootNodeRef = useRef();

  const onMouseDown = useCallback(
    (event) => {
      // Prevent double-closing
      const isClickOnToggle = toggleRef?.current?.contains(event.target);
      if (isClickOnToggle) {
        return;
      }

      setTimeout(() => {
        onClose();
      }, 200);
    },
    [onClose, toggleRef]
  );

  useEffect(() => {
    if (isOpened) {
      document.addEventListener('mousedown', onMouseDown);
    } else {
      document.removeEventListener('mousedown', onMouseDown);
    }

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, [isOpened, onMouseDown]);

  const classRoot = trianglePosition === 'right' ? 'right-0' : '';
  return (
    <div
      className={`${
        isOpened ? 'opacity-1 visible' : 'opacity-0 invisible'
      } z-30 flex flex-col absolute top-20 -mt-1 transition-all duration-300 ${classRoot}`}
      ref={rootNodeRef}
    >
      {/* Top triangle. */}
      <div className="h-7 overflow-hidden">
        <div className="h-8 w-12 rounded-md bg-white shadow-custom rotate-45 transform origin-bottom-left" />
      </div>

      {/* Wrapper. */}
      <div
        className={`${classWrapper} fixed right-8 mt-7 flex flex-col rounded-xl bg-white shadow-custom overflow-hidden`}
      >
        {children}
      </div>
    </div>
  );
}

export default memo(MenuDialog);
