import { memo } from 'react';

/* eslint-disable react/no-unescaped-entities */
function PrivacyPolicyScreen() {
  return (
    <div className="p-4 gap-8 flex flex-col container mx-auto">
      <h1 className="text-3xl font-bold text-center">
        Privacy Policy for Alexa Skills
      </h1>

      <section>
        <p className="mb-4">
          This privacy policy describes how Voice Health Care ("Voice Health
          Care", "us" or "we") protects your ("you", "the user") privacy and
          your data. Before using any of our Alexa Skills ("our skills"), please
          read this policy and the
          <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=201809740">
            Alexa Terms of Use
          </a>
          and the
          <a href="https://www.amazon.com/privacy">Privacy Policies</a>
          by the Amazon Digital Services LLC (with its affiliates, "Amazon").
        </p>

        <p className="mb-4">
          Regarding Amazon, "Alexa" means their Alexa Voice Service which
          includes third party services (like our skills) and other related
          Software.
        </p>

        <p className="mb-4">
          If you use one of our skills you fully agree to this privacy policy.
        </p>
      </section>

      <section>
        <h2 className="mb-2 text-xl font-bold">General</h2>
        <p className="mb-4">
          When you use our skills you have to talk to Alexa. This voice input is
          sent to Amazon and us where we use it to understand what our skill
          should do for you. This is absolutely necessary for our service to
          give you an appropriate answer.
        </p>
      </section>

      <section>
        <h2 className="mb-2 text-xl font-bold">Data</h2>

        <p className="mb-4">
          To improve our services we analyze automatically how often utterances
          are spoken and other analytics. This is done automatically by Amazon
          in the Amazon Developer Portal.
        </p>

        <p className="mb-4">
          For our skill it is necessary to save your inputs in our databases.
        </p>
      </section>

      <section>
        <h2 className="mb-2 text-xl font-bold">Changes</h2>
        <p className="mb-4">
          Our skill or parts of it may change or be updated at any time.
          Further, this Privacy Policy might change. You can find a link to this
          policy on the description page of the skill in the Alexa App or in the
          Alexa Store. Your continued use of our skill after changes of the
          Privacy Policy or the skill itself will be seen as your acceptance of
          both.
        </p>
      </section>
    </div>
  );
}

export default memo(PrivacyPolicyScreen);
