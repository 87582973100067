import { memo } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Navigate, Route, Routes, useLocation } from 'react-router';

import AgencyDetailsScreen from '../../../../screens/admin/agencies/Details';
import AgencyFormScreen from '../../../../screens/admin/agencies/Form';
import AgencyListScreen from '../../../../screens/admin/agencies/List';
import PatientDetailsScreen from '../../../../screens/admin/patients/Details';
import AgentFormScreen from '../../../../screens/admin/agents/Form';
import AgentListScreen from '../../../../screens/admin/agents/List';
import AgencyAdminFormScreen from '../../../../screens/admin/agency-admins/Form';
import AgencyAdminListScreen from '../../../../screens/admin/agency-admins/List';
import PatientListScreen from '../../../../screens/admin/patients/List';
import PatientFormScreen from '../../../../screens/admin/patients/Form';
import QuestionListScreen from '../../../../screens/admin/questions/List';
import QuestionFormScreen from '../../../../screens/admin/questions/Form';
import QuestionGroupListScreen from '../../../../screens/admin/question-groups/List';
import QuestionGroupFormScreen from '../../../../screens/admin/question-groups/Form';
import UserListScreen from '../../../../screens/admin/users/List';
import UserFormScreen from '../../../../screens/admin/users/Form';
import SettingsFormScreen from '../../../../screens/admin/settings/Form';
import NotificationListScreen from '../../../../screens/admin/notifications/List';
import Page404Screen from '../../../../screens/misc/Page404';

function UserRoutesAdmin() {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes key={location.pathname} location={location}>
        {/* Default: redirect. */}
        <Route element={<Navigate to="/admin/agencies" />} path="/" />

        {/* Notifications Menu: List. */}
        <Route element={<NotificationListScreen />} path="/notifications" />

        {/* User Menu: List. */}
        <Route element={<UserListScreen />} path="/users" />

        {/* User Menu: Form - New. */}
        <Route element={<UserFormScreen />} path="/users/new" />

        {/* User Menu: Form - Edit. */}
        <Route element={<UserFormScreen />} path="/users/:userId/edit" />

        {/* Question Menu: Group List. */}
        <Route element={<QuestionGroupListScreen />} path="/question-groups" />

        {/* Question Menu: Group Form - New. */}
        <Route
          element={<QuestionGroupFormScreen />}
          path="/question-groups/new"
        />

        {/* Question Menu: Group Form - Edit. */}
        <Route
          element={<QuestionGroupFormScreen />}
          path="/question-groups/:questionGroupId/edit"
        />

        {/* Question Menu: List. */}
        <Route element={<QuestionListScreen />} path="/questions" />

        {/* Question Menu: Form - New. */}
        <Route element={<QuestionFormScreen />} path="/questions/new" />

        {/* Question Menu: Form - Edit. */}
        <Route
          element={<QuestionFormScreen />}
          path="/questions/:questionId/edit"
        />

        {/* Patient Menu: List. */}
        <Route element={<PatientListScreen />} path="/patients" />

        {/* Patient Menu: Form - New. */}
        <Route element={<PatientFormScreen />} path="/patients/new" />

        {/* Patient Menu: Form - Edit. */}
        <Route
          element={<PatientFormScreen />}
          path="/patients/:patientId/edit"
        />

        {/* Patient Menu: Details. */}
        <Route
          element={<PatientDetailsScreen />}
          path="/patients/:patientId/details"
        />

        {/* Main Menu: Agency List. */}
        <Route element={<AgencyListScreen />} path="/agencies" />

        {/* Main Menu: Agency Form - New. */}
        <Route element={<AgencyFormScreen />} path="/agencies/new" />

        {/* Main Menu: Agency Form - Edit. */}
        <Route element={<AgencyFormScreen />} path="/agencies/:agencyId/edit" />

        {/* Main Menu: Agency Details. */}
        <Route
          element={<AgencyDetailsScreen />}
          path="/agencies/:agencyId/details"
        />

        {/* Main Menu: Agent List. */}
        <Route
          element={<AgentListScreen />}
          path="/agencies/:agencyId/agents"
        />

        {/* Main Menu: Agent Form - New. */}
        <Route
          element={<AgentFormScreen />}
          path="/agencies/:agencyId/agents/new"
        />

        {/* Main Menu: Agent Form - Edit. */}
        <Route
          element={<AgentFormScreen />}
          path="/agencies/:agencyId/agents/:agentId/edit"
        />

        {/* Main Menu: Agency Admin List. */}
        <Route
          element={<AgencyAdminListScreen />}
          path="/agencies/:agencyId/agency-admins"
        />

        {/* Main Menu: Agency Admin Form - New. */}
        <Route
          element={<AgencyAdminFormScreen />}
          path="/agencies/:agencyId/agency-admins/new"
        />

        {/* Main Menu: Agency Admin Form - Edit. */}
        <Route
          element={<AgencyAdminFormScreen />}
          path="/agencies/:agencyId/agency-admins/:agencyAdminId/edit"
        />

        {/* Main Menu: Patient Details. */}
        <Route
          element={<PatientDetailsScreen />}
          path="/agencies/:agencyId/patients/:patientId/details"
        />

        {/* Main Menu: Patient Form - New. */}
        <Route
          element={<PatientFormScreen />}
          path="/agencies/:agencyId/patients/new"
        />

        {/* Main Menu: Patient Form - Edit. */}
        <Route
          element={<PatientFormScreen />}
          path="/agencies/:agencyId/patients/:patientId/edit"
        />

        {/* Main Menu: Settings. */}
        <Route element={<SettingsFormScreen />} path="/settings" />

        {/* 404 */}
        <Route element={<Page404Screen />} />
      </Routes>
    </AnimatePresence>
  );
}

export default memo(UserRoutesAdmin);
