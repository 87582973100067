import { memo } from 'react';
import AnswerValue from '../AnswerValue';

function DayItem(props) {
  const { day, day_index, day_at } = props;

  const getFormattedAnswers = (answers) => {
    if (!answers) {
      return (
        <p className="italic pb-12 text-gray-500 text-bold">(Not reported)</p>
      );
    }

    const formattedAnswers = answers.map((item) => (
      <AnswerValue
        answer={item}
        key={`day_${day_index}_answer_${item.question.id}`}
        question={item.question}
      />
    ));

    return formattedAnswers;
  };

  const formattedAnswers = getFormattedAnswers(day ? day.answers : null);

  const isToday = day_at.isToday();
  const dayLabelColor = isToday ? 'primary' : 'gray-300';
  const dayLabel = isToday ? 'Today' : day_at.format('MMM D');

  return (
    <div className="flex flex-row pb-12 items-start">
      <div className="flex flex-row items-center">
        <div
          className={`flex h-6 w-6 border-4 rounded-full border-${dayLabelColor}`}
        />
        <p className={`pl-5 text-${dayLabelColor} font-extrabold`}>
          {dayLabel}
        </p>
      </div>
      <ul className="pl-8 md:pl-12">{formattedAnswers}</ul>
    </div>
  );
}

export default memo(DayItem);
