import { memo, useState } from 'react';
import ModalInfo from '../../ModalInfo';
import UploadAvatarArea from '../../UploadAvatarArea';

function AvatarInput(props) {
  const {
    uploadUrl,
    avatarUrl,
    name,
    header: headerDefault,
    headerModal: headerModalDefault,
    roundedClass: roundedDefault,
    onSuccess,
  } = props;
  const [isUploadOpened, setIsUploadOpened] = useState(false);
  const [isSuccessOpened, setIsSuccessOpened] = useState(false);
  const [currentAvatarUrl, setCurrentAvatarUrl] = useState(avatarUrl);

  const onSuccessClose = () => {
    setIsSuccessOpened(false);
  };

  const header = headerDefault ?? 'Upload Avatar';
  const headerModal = headerModalDefault ?? 'Upload a profile photo';
  const roundedClass = roundedDefault ?? 'rounded-full';

  return (
    <>
      <div className="flex mt-8 space-x-6 items-center ">
        {/* Left column. */}
        <div
          className={`flex w-28 h-28 p-1 bg-white items-center justify-center ${roundedClass} border border-gray-300 overflow-hidden`}
        >
          {currentAvatarUrl ? (
            <img
              alt="patient avatar"
              className={`w-full h-full ${roundedClass} object-contain`}
              src={currentAvatarUrl}
            />
          ) : (
            <div className="m-1 block m-auto h-full w-full">
              <span
                className={`flex h-full w-full ${roundedClass} bg-green-600 text-white font-bold text-2xl justify-center items-center`}
              >
                {name}
              </span>
            </div>
          )}
        </div>

        {/* Right column. */}
        <div className="flex flex-col">
          <p className="text-xl">{header}</p>
          <button
            className="w-24 h-10 mt-4 rounded bg-gray-300 text-white font-medium"
            onClick={() => setIsUploadOpened(!isUploadOpened)}
            type="button"
          >
            Upload
          </button>
        </div>
      </div>

      {/* Upload Modal. */}
      <UploadAvatarArea
        header={headerModal}
        isOpened={isUploadOpened}
        onClose={() => setIsUploadOpened(false)}
        onSuccess={(newAvatarUrl) => {
          setIsSuccessOpened(true);
          setIsUploadOpened(false);
          setCurrentAvatarUrl(newAvatarUrl);
          if (onSuccess) {
            onSuccess();
          }
        }}
        uploadUrl={uploadUrl}
      />

      {/* Success Modal. */}
      <ModalInfo
        isError={false}
        isOpened={isSuccessOpened}
        onClose={onSuccessClose}
        subtitle="Avatar successfully saved."
        title="Success"
      />
    </>
  );
}

export default memo(AvatarInput);
