import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import Input from '../../../../shared-components/Form/Input';
import SelectFormik from '../../../../shared-components/Form/Input/SelectFormik';
import ModalInfo from '../../../../shared-components/ModalInfo';
import ChrevronLeft from './../../../../assets/images/icon-chevron-left.svg';
import useEffectOnce from '../../../../utils/useEffectOnce';
import ActionButtons from '../../../../shared-components/Form/ActionButtons';
import statesUS from '../../../../utils/statesUS';
import { ApiContext } from '../../../../contexts/api';
import FormSkeleton from '../../../../shared-components/Form/FormSkeleton';
import AnimatedScreen from '../../../../shared-components/AnimatedScreen';
import AvatarInput from '../../../../shared-components/Form/AvatarInput';
import { GlobalContext } from '../../../../contexts/global';

function AgencyFormScreen() {
  const params = useParams();
  const navigate = useNavigate();
  const inputAutoFocusRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(null);
  const [agency, setAgency] = useState();
  const api = useContext(ApiContext);
  const globalContext = useContext(GlobalContext);

  const agencyId = params.agencyId ?? api?.user?.agency?.id;
  const isEditing = !!agencyId;

  const role = useMemo(() => api?.user.role, [api]);
  const isAdmin = useMemo(
    () => 'super-admin' === role || 'admin' === role,
    [role]
  );

  const pageTitle = isEditing ? 'Edit Agency' : 'Add New Agency';

  const fetchAgency = async (id) => {
    const apiResult = await api.http.get(`/admin/agencies/${id}`);
    setAgency(apiResult);
  };

  const saveAgency = async ({ id, ...params }) => {
    const baseUrl = '/admin/agencies';
    const apiResult = id
      ? await api.http.put(`${baseUrl}/${id}`, params)
      : await api.http.post(baseUrl, params);
    setAgency(apiResult);
  };

  const closeModal = () => {
    setModal(null);
    inputAutoFocusRef.current.focus();
  };

  const urlBack = useMemo(() => {
    if (!isAdmin) {
      return '/';
    }

    const url = isEditing
      ? `/admin/agencies/${agency?.id}/details`
      : `/admin/agencies`;
    return url;
  }, [agency, isAdmin, isEditing]);

  const goBack = () => {
    navigate(urlBack);
  };

  useEffect(() => {
    const setGlobalToolbar = () => {
      globalContext.setGlobalTitle(pageTitle);
      globalContext.setUrlBack(urlBack);
      globalContext.setEnabledSearch(false);
    };
    setGlobalToolbar();
  }, [globalContext, pageTitle, urlBack]);

  const onScreenLoad = async () => {
    try {
      if (isEditing) {
        await fetchAgency(agencyId);
      }

      setIsLoading(false);
    } catch (message) {
      setModal({
        isError: true,
        message,
        onClose: goBack,
      });
    }
  };

  useEffectOnce(() => {
    onScreenLoad();

    return () => api.http.cancelAll();
  });

  const validationSchema = useMemo(() => {
    const requiredText = 'This is a required field.';
    return yup.object().shape({
      is_editing: yup.boolean(),
      id: yup.number().positive().integer(),
      name: yup.string().trim().min(2).required(requiredText),
      contact_person: yup.string().trim().min(2).required(requiredText),
      contact_phone: yup.string().trim().min(2).required(requiredText),
      contact_email: yup
        .string()
        .email('Invalid Email!')
        .required(requiredText),
      address_1: yup.string().trim().min(2).required(requiredText),
      address_2: yup.string().trim(),
      city: yup.string().trim().min(2).required(requiredText),
      state: yup.string().trim().min(2).max(2).required(requiredText),
      zipcode: yup
        .string()
        .trim()
        .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must be 5 or 9 digits')
        .required(requiredText),
    });
  }, []);

  const initialValues = {
    is_editing: isEditing,
    id: agencyId,
    name: isEditing ? agency?.name : '',
    contact_person: isEditing ? agency?.contact_person : '',
    contact_phone: isEditing ? agency?.contact_phone : '',
    contact_email: isEditing ? agency?.contact_email : '',
    address_1: isEditing ? agency?.address_1 : '',
    address_2: isEditing ? agency?.address_2 : '',
    city: isEditing ? agency?.city : '',
    state: isEditing ? agency?.state : '',
    zipcode: isEditing ? agency?.zipcode : '',
  };

  const validateValues = (values) => {
    const clonedValues = { ...values };
    return validationSchema.cast(clonedValues);
  };

  const removeValuesIfBlank = (values) => {
    for (const field in values) {
      const isBlank = '' === `${values[field]}`.trim();
      if (isBlank) {
        delete values[field];
      }
    }
    return values;
  };

  const submitForm = async (values, { setErrors }) => {
    try {
      const validValues = validateValues(values);
      removeValuesIfBlank(validValues);

      await saveAgency(validValues);
      setModal({
        message: 'Success!',
        onClose: goBack,
      });
    } catch (errors) {
      const isInternalError = 500 === api.http.getLastHttpStatus();
      const message = isInternalError
        ? errors
        : 'There are errors on the form.';
      setModal({
        isError: true,
        message: message,
        onClose: closeModal,
      });
      setErrors(errors);
    }
  };

  const getMainArea = () => {
    if (isLoading) {
      return <FormSkeleton />;
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form id="form-agency">
            {/* Tab Content.  */}
            <div className="flex pb-20 flex-col max-w-xl">
              {/* Section */}
              <h2 className="md:pt-10 text-2xl">Agency Details</h2>

              {isEditing ? (
                <AvatarInput
                  avatarUrl={agency?.avatar_url}
                  header="Upload Logo"
                  headerModal="Upload the agency logo"
                  name="N/A"
                  roundedClass="rounded"
                  uploadUrl={`/admin/agencies/${agency?.id}/avatar`}
                />
              ) : null}

              <Input
                autoFocus
                innerRef={inputAutoFocusRef}
                label="Agency Name"
                name="name"
                required
              />

              {/* Section */}
              <h2 className="pt-10 text-2xl">Agency Address</h2>

              <Input label="Address Line 1" name="address_1" required />

              <Input label="Address Line 2" name="address_2" />

              <Input label="City" name="city" required />

              <Input
                as={SelectFormik}
                label="State"
                name="state"
                options={statesUS}
                placeholder="-- Select State --"
                required
              />

              <Input label="ZIP Code" name="zipcode" required />

              {/* Section */}
              <h2 className="pt-10 text-2xl">Agency Contact Details</h2>

              <Input label="Contact Person" name="contact_person" required />

              <Input
                label="Contact Phone"
                name="contact_phone"
                placeholder="ex. (310) 111-2222"
                required
              />

              <Input
                label="Contact Email"
                name="contact_email"
                placeholder="ex. john@smith.com"
                required
                type="email"
              />
            </div>
            {/* /Tab Content.  */}

            <ActionButtons
              formId="form-agency"
              isSubmitting={isSubmitting}
              labelSubmit={isEditing ? 'Save Changes' : 'Create Agency'}
              onClickCancel={goBack}
            />
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <AnimatedScreen>
      <div className="md:my-8 md:pb-12">
        {/* Error and Success Modal. */}
        <ModalInfo
          isError={modal?.isError}
          isOpened={!!modal}
          onClose={modal?.onClose}
          title={modal?.message}
        />

        {/* Card. */}
        <div className="flex flex-col px-5 md:px-14 pt-8 pb-16 md:rounded-lg bg-white shadow-custom">
          {/* Back button. */}
          <Link className="hidden md:flex flex-row items-center" to={urlBack}>
            <img alt="Back button" className="h-5" src={ChrevronLeft} />
            <h1 className="pl-6 text-2xl font-black uppercase">{pageTitle}</h1>
          </Link>

          {getMainArea()}
        </div>
        {/* /Card. */}
      </div>
    </AnimatedScreen>
  );
}

export default memo(AgencyFormScreen);
