import { memo } from 'react';

function CardNotificationSkeleton() {
  return (
    <div className="animate-pulse flex flex-row h-32 py-6 px-12 gap-11 rounded-lg shadow-custom overflow-hidden bg-white items-center hover:bg-gray-200 cursor-pointer">
      {/* Avatar. */}
      <div className="h-full w-1/5">
        <div className="h-20 w-20 bg-gray-100 rounded-full" />
      </div>

      {/* Name/Message. */}
      <div className="h-full w-3/5 flex flex-col justify-center gap-4">
        <div className="h-2 bg-gray-100 rounded w-3/4" />
        <div className="h-2 bg-gray-100 rounded w-3/4" />
      </div>

      {/* Time. */}
      <div className="ml-auto w-1/6 self-center text-xs text-gray-400 italic">
        <div className="w-1/2 h-2 bg-gray-100 rounded" />
      </div>
    </div>
  );
}

export default memo(CardNotificationSkeleton);
