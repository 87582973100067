import { memo } from 'react';
import ButtonAddContact from './ButtonAddContact';
import ContactFormList from './ContactFormList';
import ContactFormPills from './ContactFormPills';
import { ContactFormProvider } from './useContactForm';

function ContactForm() {
  return (
    <ContactFormProvider>
      {/* Toolbar */}
      <div className="pillsContainer mt-8 flex flex-row gap-4">
        <ButtonAddContact />

        <ContactFormPills />
      </div>

      {/* Form */}
      <div className="contactFormList max-w-xl">
        <ContactFormList />
      </div>
    </ContactFormProvider>
  );
}

export default memo(ContactForm);
