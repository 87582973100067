import { memo } from 'react';
import Input from '../../../../../../shared-components/Form/Input';
import SelectFormik from '../../../../../../shared-components/Form/Input/SelectFormik';
import ButtonRemoveContact from '../ButtonRemoveContact';

const contactRelationshipOptions = [
  { label: 'Self', value: 'Self' },
  { label: 'Daughter/Son', value: 'Daughter/Son' },
  { label: 'Spouse', value: 'Spouse' },
  { label: 'Significant Other', value: 'Significant Other' },
  { label: 'Brother/Sister', value: 'Brother/Sister' },
  { label: 'Friend', value: 'Friend' },
  { label: 'Neighbor', value: 'Neighbor' },
  { label: 'Local Agency', value: 'Local Agency' },
  {
    label: 'Professional in the Community',
    value: 'Professional in the Community',
  },
  { label: 'Other', value: 'Other' },
];

function ContactFormItem({ index, ...rest }) {
  return (
    <div {...rest}>
      <Input
        label="Contact Person"
        name={`contacts.${index}.name`}
        placeholder="ex. John Smith"
        required
      />

      <Input
        as={SelectFormik}
        label="Relationship"
        name={`contacts.${index}.relationship`}
        options={contactRelationshipOptions}
        placeholder="ex. Daughter"
        required
      />

      <Input
        label="Phone Number"
        name={`contacts.${index}.phone`}
        placeholder="ex. (311) 111-2222"
      />

      <Input
        label="Email Address"
        name={`contacts.${index}.email`}
        placeholder="ex. john@smith.com"
        type="email"
      />

      <Input name={`contacts.${index}.id`} type="hidden" />

      <ButtonRemoveContact
        className="mt-12 text-red-400 italic underline text-sm"
        index={index}
      />
    </div>
  );
}

export default memo(ContactFormItem);
