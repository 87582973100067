import { memo } from 'react';
import { Link } from 'react-router-dom';

function NormalButton(props) {
  const { url, title } = props;
  return (
    <Link
      className="flex flex-auto md:flex-initial items-center justify-center h-12 px-4 lg:px-6 mx-2 border-2 border-primary text-primary rounded font-medium hover:text-opacity-70 hover:border-opacity-70"
      to={url ?? '/'}
    >
      {title}
    </Link>
  );
}

export default memo(NormalButton);
