import { memo } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { ReactComponent as IconChecked } from './../../../../../assets/images/icon-user-checked.svg';
import { ReactComponent as IconAlert } from './../../../../../assets/images/icon-alert.svg';
import Avatar from './Avatar';

function CardNotification(props) {
  const { alertDay } = props;
  const { patient } = alertDay;

  const isChecked = !!alertDay.alert_checked_at;
  const checkedAt = dayjs(alertDay.alert_checked_at);

  const classChecked = !isChecked
    ? 'bg-white hover:bg-blue-50'
    : 'filter grayscale bg-gray-200';
  return (
    <Link
      className={`${classChecked} border-primary relative flex flex-row h-44 md:h-32 pb-3 pt-12 md:py-6 px-4 md:px-12 gap-3 md:gap-11 rounded-lg shadow-custom overflow-hidden items-center cursor-pointer`}
      to={`/admin/patients/${patient.id}/details`}
    >
      {/* Avatar. */}
      <Avatar user={patient} />

      {/* Name/Message. */}
      <div className="flex flex-col">
        <p className="text-xl font-bold text-primary">
          {patient.first_name} {patient.last_name}
        </p>
        <p className="mt-2 font-medium text-gray-400 whitespace-normal line-clamp-3 truncate overflow-ellipsis">
          {getMessages(alertDay)}
        </p>
      </div>

      {/* Time. */}
      <p className="ml-auto self-center text-xs text-gray-400 italic">
        {alertDay.answered_ago}
      </p>

      {/* Badge - checked on "MM dd, Y" */}
      {isChecked ? (
        <div className="w-full md:w-40 absolute left-0 md:left-auto md:right-4 top-0 flex flex-row text-xs bg-green-200 p-2 px-4 md:m-2 md:rounded items-center">
          <IconChecked className="w-7 h-5 fill-current text-green-400 stroke-current" />
          <p className="mx-auto font-bold">{checkedAt.format('MMM D, YYYY')}</p>
        </div>
      ) : (
        <div className="w-full md:w-40 absolute left-0 md:left-auto md:right-4 top-0 flex flex-row text-xs bg-yellow-200 p-2 px-4 md:m-2 md:rounded items-center">
          <IconAlert className="w-5 h-5 fill-current text-yellow-400 stroke-current" />
          <p className="mx-auto font-bold">Pending</p>
        </div>
      )}
    </Link>
  );
}

const getPronoun = (gender) => {
  switch (gender) {
    case 'female':
      return 'her';
    case 'male':
      return 'his';
    default:
      return 'their';
  }
};

const getMessages = (alertDay) => {
  const messages = alertDay.answers.map((answer) => {
    const hasThresholdHit = answer.is_threshold_hit;
    if (!hasThresholdHit) {
      return null;
    }

    const isWeight = 1 === answer.question.id;
    if (isWeight) {
      return `Weight to ${answer.value}lbs`;
    }

    const isBoolean = 'boolean' === answer.question.type;
    if (isBoolean) {
      const value = answer.value ? 'Yes' : 'No';
      return `${answer.question.label} to ${value}`;
    }

    return `${answer.question.label} to ${answer.value}`;
  });

  const pronoun = getPronoun(alertDay.patient.gender);
  return `Updated ${pronoun} ` + messages.filter((n) => n).join(', ');
  /*
   * return (
   *     <ul className="list-disc flex flex-wrap">
   *         <li className="ml-8">Weight: <span className="font-bold text-green-600">156lbs</span></li>
   *         <li className="ml-8">Weight: <span className="font-bold text-red-600">156lbs</span></li>
   *         <li className="ml-8">Weight: <span className="font-bold text-red-600">156lbs</span></li>
   *         <li className="ml-8">Weight: <span className="font-bold text-red-600">156lbs</span></li>
   *         <li className="ml-8">Weight: <span className="font-bold text-green-600">156lbs</span></li>
   *         <li className="ml-8">Weight: <span className="font-bold text-red-600">156lbs</span></li>
   *         <li className="ml-8">Weight: <span className="font-bold text-red-600">156lbs</span></li>
   *         <li className="ml-8">Weight: <span className="font-bold text-red-600">156lbs</span></li>
   *     </ul>
   * );
   */
};

export default memo(CardNotification);
