/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './app/assets/css/custom.css';
import './app/assets/fonts/avenir/font.css';
import './app/assets/fonts/noto-sans/font.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

// Extend dayjs()
dayjs.extend(isToday);

// Render app
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

/**
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
