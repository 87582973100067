import { useCallback } from 'react';
import { useContactForm } from '../useContactForm';

function useRemoveContact() {
  const { contacts, setContacts, setActiveContactTabIndex } = useContactForm();

  const resetContacts = useCallback(() => {
    setContacts([
      {
        newContactKey: 0,
        id: '',
        name: '',
        email: '',
        phone: '',
        relationship: '',
      },
    ]);
  }, [setContacts]);

  const removeContact = useCallback(
    (index) => {
      const isOutOfBounds = index > contacts.length - 1 || index < 0;

      if (isOutOfBounds) {
        return;
      }

      const hasOnlyOneContact = contacts.length <= 1;

      if (hasOnlyOneContact) {
        resetContacts();

        return;
      }

      const newContacts = [...contacts];

      newContacts.splice(index, 1);

      setContacts(newContacts);

      const wasLastContact = index >= newContacts.length;

      if (wasLastContact) {
        setActiveContactTabIndex(index - 1);
      }
    },
    [contacts, resetContacts, setContacts, setActiveContactTabIndex]
  );

  return { removeContact };
}

export default useRemoveContact;
