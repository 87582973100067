import { useFormikContext } from 'formik';
import { memo, useCallback, useState } from 'react';
import Input from '../../../../../shared-components/Form/Input';
import CheckboxSingleFormik from '../../../../../shared-components/Form/Input/CheckboxSingleFormik';
import SelectFormik from '../../../../../shared-components/Form/Input/SelectFormik';
import IconCollapsed from './../../../../../assets/images/icon-down-arrow.svg';
import IconExpanded from './../../../../../assets/images/icon-up-arrow.svg';

function QuestionCard(props) {
  const { question } = props;
  const [isExpanded, setIsExpanded] = useState(true);
  const { values } = useFormikContext();
  const settings = values.questions_settings[question.id];

  const clickExpand = useCallback(() => {
    setIsExpanded((value) => !value);
  }, []);

  const hasNoData = !settings;
  if (hasNoData) {
    return null;
  }

  const isWeight = question.type === 'weight';
  const isInteger = question.type === 'integer';
  const isBoolean = question.type === 'boolean';
  const isBloodPressure = question.type === 'blood_pressure';

  const isEnabled = values.enabled_question_ids.includes(question.id + '');

  const enabledOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  const integerConditionOptions = [
    { label: 'More than', value: 'greater_than' },
    { label: 'Less than', value: 'less_than' },
    { label: 'In the range', value: 'inside_range' },
    { label: 'Out the range', value: 'outside_range' },
  ];

  return (
    <div className="questionCard mt-4 py-4 px-6 flex flex-col rounded-lg shadow-custom-40 overflow-hidden bg-white">
      <Input
        as={CheckboxSingleFormik}
        containerstyle="questionCard__enabledCheckbox flex flex-row-reverse items-center self-start"
        disableError
        inputstyle="mr-3"
        labelstyle="text-lg font-bold"
        name="enabled_question_ids"
        option={{ label: question.label, value: question.id + '' }}
      />

      <div
        className={`alertSettings mt-4 p-2 pt-4 border-t ${
          isEnabled ? '' : 'hidden'
        }`}
      >
        <div className="alertSettings__header flex flex-1 flex-row select-none cursor-pointer">
          <div
            className="alertSettings__titleWrapper flex flex-1 items-center"
            onClick={clickExpand}
          >
            <div className={settings.is_using_global_settings ? 'hidden' : ''}>
              {isExpanded ? (
                <img alt="collapse" className="h-5 mr-3" src={IconExpanded} />
              ) : (
                <img alt="expand" className="h-5 mr-3" src={IconCollapsed} />
              )}
            </div>

            <h2 className="alertSettings__title text-lg">
              Notification Settings
            </h2>
          </div>

          <Input
            as={CheckboxSingleFormik}
            checkboxLabelStyle="text-base font-normal italic"
            containerstyle="questionCard__toggleGlobalSettings ml-auto flex items-center self-start"
            inputstyle="ml-3"
            name={`questions_settings[${question.id}].is_using_global_settings`}
            option={{ label: 'Use global settings' }}
          />
        </div>

        <div
          className={`alertSettings__inputs grid lg:grid-cols-2 grid-cols-1 flex-row gap-4 pt-4 font-normal text-base ${
            isExpanded ? '' : 'hidden'
          } ${settings.is_using_global_settings ? 'hidden' : ''}`}
        >
          <Input
            as={SelectFormik}
            containerstyle="flex flex-col"
            label="Trigger Notification?"
            labelstyle="text-base font-normal"
            name={`questions_settings[${question.id}].is_alert_enabled`}
            options={enabledOptions}
            required
          />

          {settings.is_alert_enabled ? (
            <>
              {isWeight ? (
                <Input
                  containerstyle=""
                  inputstyle="h-12 w-full mt-3 px-6 rounded-lg border focus:ring-2"
                  label="When difference is greater than (lbs)"
                  labelstyle="text-base font-normal"
                  name={`questions_settings[${question.id}].alert_condition_value2`}
                  type="number"
                />
              ) : null}

              {isBoolean ? (
                <Input
                  as={SelectFormik}
                  containerstyle="flex flex-col"
                  label="Notify when answer is"
                  labelstyle="text-base font-normal"
                  name={`questions_settings[${question.id}].alert_condition_value1`}
                  options={enabledOptions}
                />
              ) : null}

              {isBloodPressure ? (
                <>
                  <Input
                    containerstyle="flex flex-col"
                    label="Upper value (systolic) is greater than"
                    labelstyle="text-base font-normal"
                    name={`questions_settings[${question.id}].alert_condition_value1`}
                    type="number"
                  />

                  <Input
                    containerstyle="flex flex-col"
                    label="OR Lower value (diastolic) is less than"
                    labelstyle="text-base font-normal"
                    name={`questions_settings[${question.id}].alert_condition_value2`}
                    type="number"
                  />
                </>
              ) : null}

              {isInteger ? (
                <>
                  <Input
                    as={SelectFormik}
                    containerstyle="flex flex-col"
                    label="Notify when answer is"
                    labelstyle="text-base font-normal"
                    name={`questions_settings[${question.id}].alert_condition_operator`}
                    options={integerConditionOptions}
                  />

                  {settings.alert_condition_operator === 'greater_than' && (
                    <Input
                      containerstyle="flex flex-col"
                      label="More than"
                      labelstyle="text-base font-normal"
                      name={`questions_settings[${question.id}].alert_condition_value1`}
                      type="number"
                    />
                  )}

                  {settings.alert_condition_operator === 'less_than' && (
                    <Input
                      containerstyle="flex flex-col"
                      label="Less than"
                      labelstyle="text-base font-normal"
                      name={`questions_settings[${question.id}].alert_condition_value1`}
                      type="number"
                    />
                  )}

                  {settings.alert_condition_operator === 'inside_range' && (
                    <>
                      <Input
                        containerstyle="flex flex-col"
                        label="More than"
                        labelstyle="text-base font-normal"
                        name={`questions_settings[${question.id}].alert_condition_value2`}
                        type="number"
                      />

                      <Input
                        containerstyle="flex flex-col"
                        label="AND Less than"
                        labelstyle="text-base font-normal"
                        name={`questions_settings[${question.id}].alert_condition_value1`}
                        type="number"
                      />
                    </>
                  )}

                  {settings.alert_condition_operator === 'outside_range' && (
                    <>
                      <Input
                        containerstyle="flex flex-col"
                        label="Less than"
                        labelstyle="text-base font-normal"
                        name={`questions_settings[${question.id}].alert_condition_value1`}
                        type="number"
                      />

                      <Input
                        containerstyle="flex flex-col"
                        label="OR More than"
                        labelstyle="text-base font-normal"
                        name={`questions_settings[${question.id}].alert_condition_value2`}
                        type="number"
                      />
                    </>
                  )}
                </>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default memo(QuestionCard);
