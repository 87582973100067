import { memo } from 'react';

function SubmitButton(props) {
  const { isSending } = props;
  if (isSending) {
    return (
      <button
        className="bg-gray-400 rounded-full h-12 w-52 font-medium text-white"
        disabled
        type="button"
      >
        Sending...
      </button>
    );
  }

  return (
    <button
      className="bg-primary rounded-full h-12 w-52 font-medium text-white focus:ring-2 focus:ring-blue-900"
      form="form-sign-in"
      type="submit"
    >
      Login
    </button>
  );
}

export default memo(SubmitButton);
