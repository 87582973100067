import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Header from '../../../../shared-components/Header';
import ActionBar from '../../../../shared-components/Header/ActionBar';
import PrimaryButton from '../../../../shared-components/Header/ActionBar/PrimaryButton';
import TitleBar from '../../../../shared-components/Header/TitleBar';
import ModalInfo from '../../../../shared-components/ModalInfo';
import useEffectOnce from '../../../../utils/useEffectOnce';
import Table from '../../../../shared-components/Table';
import BackBar from '../../../../shared-components/Header/BackBar';
import { ApiContext } from '../../../../contexts/api';
import ButtonEdit from '../../../../shared-components/Table/ButtonEdit';
import AnimatedScreen from '../../../../shared-components/AnimatedScreen';
import { GlobalContext } from '../../../../contexts/global';

function QuestionGroupListScreen() {
  const [questionGroups, setQuestionGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [filtered, setFiltered] = useState([]);
  const api = useContext(ApiContext);
  const globalContext = useContext(GlobalContext);

  const pageTitle = 'Question Groups';

  const fetchQuestionGroups = async () => {
    const apiResult = await api.http.get('/admin/question-groups');
    setQuestionGroups(apiResult);
  };

  const closeModal = () => {
    setModal(null);
  };

  const urlBack = useMemo(() => {
    return `/admin/questions`;
  }, []);

  useEffect(() => {
    const setGlobalToolbar = () => {
      globalContext.setGlobalTitle(pageTitle);
      globalContext.setUrlBack(urlBack);
      globalContext.setEnabledSearch(false);
    };
    setGlobalToolbar();
  }, [globalContext, urlBack, pageTitle]);

  const onScreenLoad = async () => {
    try {
      await fetchQuestionGroups();
      setIsLoading(false);
    } catch (message) {
      setModal({
        isError: true,
        message,
        onClose: closeModal,
      });
    }
  };

  useEffectOnce(() => {
    onScreenLoad();

    return () => api.http.cancelAll();
  });

  const onChangeSearch = useCallback(() => {
    const filtered = questionGroups.filter((group) => {
      const lowerSearchValue = searchValue.toLowerCase();

      const isOnLabel = group.label.toLowerCase().includes(lowerSearchValue);
      if (isOnLabel) {
        return true;
      }

      return false;
    });
    setFiltered(filtered);
  }, [questionGroups, searchValue]);

  useEffect(() => {
    onChangeSearch();
  }, [onChangeSearch, searchValue, questionGroups]);

  const headerLeftSide = (
    <div className="hidden md:flex">
      <BackBar title="Back to Questions" url={urlBack} />
    </div>
  );

  const headerRightSide = (
    <ActionBar>
      <PrimaryButton title="New Group" url="/admin/question-groups/new" />
    </ActionBar>
  );

  const headerTitle = (
    <TitleBar
      enabledFilter={false}
      enabledSearch
      onSearchChange={setSearchValue}
      searchPlaceholder="Search by group name."
      title={pageTitle}
    />
  );

  const getTableRows = (groups) => {
    return groups.map((group) => [
      group.label,
      group.questions.length,
      <ButtonEdit
        key={group.id}
        to={`/admin/question-groups/${group.id}/edit`}
      />,
    ]);
  };
  const tableData = useMemo(() => getTableRows(filtered), [filtered]);

  const headers = [
    { label: 'Label', className: 'w-auto' },
    { label: 'Questions', className: 'w-auto' },
    { label: 'Actions', className: 'w-auto text-center' },
  ];

  return (
    <AnimatedScreen>
      <div className="px-3 md:px-0 md:pb-12">
        <Header
          leftSide={headerLeftSide}
          rightSide={headerRightSide}
          title={headerTitle}
        />

        {/* Error and Success Modal. */}
        <ModalInfo
          isError={modal?.isError}
          isOpened={!!modal}
          onClose={modal?.onClose}
          title={modal?.message}
        />

        {/* Main Area. */}
        <div className="my-8 pb-12">
          <Table data={tableData} headers={headers} isLoading={isLoading} />
        </div>
      </div>
    </AnimatedScreen>
  );
}

export default memo(QuestionGroupListScreen);
