import { memo } from 'react';
import { useField } from 'formik';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

function DateFormik(props) {
  const { className } = props;
  const [, meta, helpers] = useField(props);
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <DatePicker
      className={className}
      onChange={(date) => setValue(date)}
      selected={value}
    />
  );
}

export default memo(DateFormik);
