import { memo } from 'react';

import Logo from './../../../assets/images/logo-voice-health-with-smart-companion.svg';
import IconLoading from './../../../assets/images/icon-loading-dual-ring.svg';

function SplashScreen() {
  return (
    <>
      <div className="animate-pulse flex justify-center md:items-center min-h-screen font-medium text-base text-gray-700">
        <img alt="Splash Screen Logo" className="pb-20" src={Logo} />
      </div>

      {/* Spinner. */}
      <div className="flex absolute py-2 px-8 bottom-5 right-5 bg-purple-100 rounded justify-center items-center ">
        <span className="ml-3 font-bold text-purple-600">Loading ...</span>
        <img
          alt="loading"
          className="ml-4 h-10 w-10 animate-spin"
          src={IconLoading}
        />
      </div>
    </>
  );
}

export default memo(SplashScreen);
