import { memo } from 'react';
import { Link } from 'react-router-dom';

function AgentList(props) {
  const { avatarUrls: allAvatarUrls, url } = props;

  const agentCount = allAvatarUrls ? allAvatarUrls.length : 0;

  const hasNoneAgents = 0 === agentCount;
  const hasMoreThanFive = agentCount > 5;
  const getAvatarUrlsPaginated = () => {
    if (hasNoneAgents) {
      return [];
    }

    if (hasMoreThanFive) {
      return allAvatarUrls.slice(0, 4);
    }

    return allAvatarUrls;
  };
  const avatarUrls = getAvatarUrlsPaginated();

  const agentImgs = avatarUrls.map((url, index) => {
    const hasNoAvatar = !url;
    if (hasNoAvatar) {
      return (
        <span
          className="-ml-4 w-9 h-9 text-xs border-2 border-gray-100 rounded-full bg-green-600 text-white font-bold flex justify-center items-center"
          // eslint-disable-next-line react/no-array-index-key
          key={'avatar_none_' + index}
        >
          N/A
        </span>
      );
    }

    return (
      <span
        className="-ml-4 w-9 h-9 border-2 border-gray-100 rounded-full bg-white"
        // eslint-disable-next-line react/no-array-index-key
        key={'avatar_none_' + index}
      >
        <img
          alt="Avatar of user"
          className="w-full h-full rounded-full object-cover"
          key={'avatar_' + url}
          src={url}
        />
      </span>
    );
  });

  const andMore = hasMoreThanFive ? (
    <div className="-ml-4 h-11 w-11 flex flex-col border-dashed border-2 border-gray-400 rounded-full bg-white text-gray-400 text-center justify-center text-xs leading-3 font-bold">
      <span>+{agentCount - 4}</span>
      <span>more</span>
    </div>
  ) : null;

  const noneAgents = hasNoneAgents ? (
    <div className="-ml-4 h-11 w-11 flex flex-col border-dashed border-2 border-gray-400 rounded-full bg-white text-gray-400 text-center justify-center text-xs leading-3 font-bold">
      <span>None</span>
    </div>
  ) : null;

  return (
    <Link
      className="flex flex-row pl-2 pr-2 md:pr-8 items-center flex-wrap justify-end md:justify-start"
      to={url ?? '/'}
    >
      {/* Label. */}
      <p className="text-gray-400 pr-3">Agents</p>
      {/* /Label. */}

      {/* List. */}
      <div className="flex flex-row items-center ml-4">
        {agentImgs}
        {andMore}
        {noneAgents}
      </div>
      {/* /List. */}
    </Link>
  );
}

export default memo(AgentList);
