/* eslint-disable react/no-multi-comp */
import { memo, useContext, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import AuthLayout from './layouts/AuthLayout';
import AdminLayout from './layouts/AdminLayout';
import Page404 from './screens/misc/Page404';
import ApiProvider, { ApiContext } from './contexts/api';
import SplashScreen from './screens/misc/SplashScreen';
import useEffectOnce from './utils/useEffectOnce';
import GlobalProvider from './contexts/global';
import PrivacyPolicyScreen from './screens/misc/PrivacyPolicyScreen';

const AppRoutes = memo(() => {
  const [isSplash, setIsSplash] = useState(true);
  const api = useContext(ApiContext);

  const checkLoggedIn = async () => {
    return await api.auth.refresh();
  };

  useEffectOnce(() => {
    const fetchData = async () => {
      await checkLoggedIn();
      setIsSplash(false);
    };
    fetchData();
  });

  if (isSplash) {
    return <SplashScreen />;
  }

  if (api.isLoggedIn) {
    return (
      <Routes>
        {/* Root: redirect to admin */}
        <Route element={<Navigate to="/admin" />} path="/" />

        {/* Sign in: redirect to admin */}
        <Route element={<Navigate to="/" />} path="/sign-in" />

        {/* Admin */}
        <Route element={<AdminLayout />} path="/admin/*" />

        {/* Privacy Policy */}
        <Route element={<PrivacyPolicyScreen />} path="/policy-privacy" />

        {/* 404 */}
        <Route element={<Page404 />} />
      </Routes>
    );
  }

  return (
    <Routes>
      {/* Root: redirect to sign-in */}
      <Route element={<Navigate to="/sign-in" />} path="/" />

      {/* Auth */}
      <Route element={<AuthLayout />} path="/sign-in/*" />

      {/* Admin: redirect to sign-in */}
      <Route element={<Navigate to="/sign-in" />} path="/admin/*" />

      {/* Privacy Policy */}
      <Route element={<PrivacyPolicyScreen />} path="/policy-privacy" />

      {/* 404 */}
      <Route element={<Page404 />} />
    </Routes>
  );
});

function App() {
  return (
    <BrowserRouter>
      <ApiProvider>
        <GlobalProvider>
          <div className="App font-avenir">
            <AppRoutes />
          </div>
        </GlobalProvider>
      </ApiProvider>
    </BrowserRouter>
  );
}

export default App;
