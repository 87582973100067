import { memo, useCallback, useLayoutEffect, useRef } from 'react';

import { ReactComponent as IconSearch } from './../../assets/images/icon-search-dialog.svg';
// import IconClose from './../../assets/images/icon-close-big.svg';

function ModalSearch(props) {
  const { isOpened, onOkay, onCancel } = props;

  const cancelButtonRef = useRef();
  const okayButtonRef = useRef();
  const inputRef = useRef();

  const focusInput = useCallback(() => {
    const isHiddenOrUnitialized = !isOpened || !inputRef.current;
    if (isHiddenOrUnitialized) {
      return;
    }
    inputRef.current.focus();
  }, [isOpened]);

  useLayoutEffect(() => {
    focusInput();
  }, [isOpened, focusInput]);

  const onKeyDown = useCallback(
    (event) => {
      const hasPressedEscape = 'escape' === event.key.toLowerCase();
      if (hasPressedEscape) {
        onCancel();
      }

      const hasPressedEnter = 'enter' === event.key.toLowerCase();
      if (hasPressedEnter) {
        onOkay(inputRef.current.value);
      }
    },
    [onCancel, onOkay]
  );

  const onClickOkay = useCallback(() => {
    onOkay(inputRef.current.value);
  }, [onOkay]);

  if (!isOpened) {
    return null;
  }

  return (
    <div className="h-screen w-screen top-0 left-0 z-50 fixed flex items-center justify-center pb-14 bg-gray-800 bg-opacity-80">
      {/*  Modal. */}
      <div className="flex flex-col flex-grow md:max-w-xl bg-white shadow rounded-md px-11 pt-12 pb-14 m-3 mb-28 items-center justify-center">
        {/* Icon */}
        <div className="h-24 w-24 rounded-full flex items-center justify-center bg-primary">
          <IconSearch className="w-10 fill-current text-white" />
        </div>

        {/* Title */}
        <p className="pt-8 text-2xl">Search</p>

        {/* Input. */}
        <input
          className="w-full h-12 mt-3 px-6 rounded-lg border focus:ring-2 bg-blue-50"
          onKeyDown={onKeyDown}
          ref={inputRef}
        />

        {/* Buttons */}
        <div className="flex flex-row gap-4 w-full">
          <button
            className="h-12 w-1/2 mt-12 px-4 lg:px-6 bg-gray-400 text-white rounded font-medium flex flex-1 items-center justify-center"
            onClick={onCancel}
            onKeyDown={onKeyDown}
            ref={cancelButtonRef}
            type="button"
          >
            Cancel
          </button>

          <button
            className="h-12 w-1/2 mt-12 px-4 lg:px-6 bg-primary text-white rounded font-medium flex flex-1 items-center justify-center"
            onClick={onClickOkay}
            onKeyDown={onKeyDown}
            ref={okayButtonRef}
            type="button"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
}

export default memo(ModalSearch);
