import { memo } from 'react';
import iconNoData from './../../../../../../assets/images/icon-close.svg';

function HistoryFormattedValue(props) {
  const { answer, question } = props;

  const isNull = undefined === answer?.value;
  const isBloodPressureNull =
    question?.type === 'blood_pressure' && !answer?.value2;
  if (isNull || isBloodPressureNull) {
    return (
      <p className="h-12 w-12 mx-auto text-sm flex rounded-full bg-gray-300 text-white font-black items-center justify-center uppercase">
        <img alt="No data" src={iconNoData} />
      </p>
    );
  }

  const isBoolean = question.type === 'boolean';
  if (isBoolean) {
    const value = answer.value ? 'Yes' : 'No';
    const bg_color = answer.is_threshold_hit ? 'bg-red-500' : 'bg-primary';
    return (
      <p
        className={`h-12 w-12 mx-auto text-sm flex rounded-full ${bg_color} text-white font-black items-center justify-center uppercase`}
      >
        {value}
      </p>
    );
  }

  const isInteger = question.type === 'integer';
  if (isInteger) {
    const value = answer.value;
    const bg_color = answer.is_threshold_hit ? 'bg-red-500' : 'bg-primary';
    return (
      <p
        className={`flex flex-col h-10 w-24 mx-auto text-sm flex rounded ${bg_color} text-white font-black items-center justify-center`}
      >
        <span>{value}</span>
      </p>
    );
  }

  const isBloodPressure = question.type === 'blood_pressure';
  if (isBloodPressure) {
    const value_upper = answer.value;
    const value_lower = answer.value2;
    const bg_color = answer.is_threshold_hit ? 'bg-red-500' : 'bg-primary';
    return (
      <p
        className={`flex flex-col h-10 w-24 mx-auto text-sm flex rounded ${bg_color} text-white font-black items-center justify-center`}
      >
        <span>
          {value_upper}/{value_lower}
        </span>
      </p>
    );
  }

  const isWeight = question.type === 'weight';
  if (isWeight) {
    const value = `${answer.value} lbs`;
    const bg_color = answer.is_threshold_hit ? 'bg-red-500' : 'bg-primary';
    const value_diff = answer?.diff_from_previous ?? 0;
    const diff_formatted = value_diff > 0 ? `+${value_diff}` : value_diff;
    return (
      <p
        className={`flex flex-col h-10 w-24 mx-auto text-sm flex rounded ${bg_color} text-white font-black items-center justify-center`}
      >
        <span className="italic text-xs font-medium">({diff_formatted})</span>
        <span>{value}</span>
      </p>
    );
  }

  return answer.value;
}

export default memo(HistoryFormattedValue);
