import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import Input from '../../../../shared-components/Form/Input';
import ModalInfo from '../../../../shared-components/ModalInfo';
import ChrevronLeft from './../../../../assets/images/icon-chevron-left.svg';
import useEffectOnce from '../../../../utils/useEffectOnce';
import ActionButtons from '../../../../shared-components/Form/ActionButtons';
import { ApiContext } from '../../../../contexts/api';
import FormSkeleton from '../../../../shared-components/Form/FormSkeleton';
import AnimatedScreen from '../../../../shared-components/AnimatedScreen';
import AvatarInput from '../../../../shared-components/Form/AvatarInput';
import { userNormalization } from '../../../../utils/apiNormalization';
import SelectFormik from '../../../../shared-components/Form/Input/SelectFormik';
import { GlobalContext } from '../../../../contexts/global';

function UserFormScreen() {
  const { userId } = useParams();
  const isEditing = !!userId;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState();
  const [modal, setModal] = useState(null);
  const inputAutoFocusRef = useRef();
  const api = useContext(ApiContext);
  const globalContext = useContext(GlobalContext);

  const pageTitle = isEditing ? 'Edit User' : 'Add Super-User';

  const fetchUser = async (id) => {
    const apiResult = await api.http.get(`/admin/users/${id}`);
    const processedResult = userNormalization.fromAPI(apiResult);
    setUser(processedResult);
  };

  const saveUser = async ({ id, ...params }) => {
    const baseUrl = '/admin/users';
    const apiResult = id
      ? await api.http.put(`${baseUrl}/${id}`, params)
      : await api.http.post(baseUrl, params);
    const processedResult = userNormalization.fromAPI(apiResult);
    setUser(processedResult);
  };

  const closeModal = () => {
    setModal(null);
    inputAutoFocusRef.current.focus();
  };

  const urlBack = useMemo(() => {
    return `/admin/users`;
  }, []);

  const goBack = () => {
    navigate(urlBack);
  };

  useEffect(() => {
    const setGlobalToolbar = () => {
      globalContext.setGlobalTitle(pageTitle);
      globalContext.setUrlBack(urlBack);
      globalContext.setEnabledSearch(false);
    };
    setGlobalToolbar();
  }, [globalContext, pageTitle, urlBack]);

  const onScreenLoad = async () => {
    try {
      if (isEditing) {
        await fetchUser(userId);
      }

      setIsLoading(false);
    } catch (message) {
      setModal({
        isError: true,
        message,
        onClose: goBack,
      });
    }
  };

  useEffectOnce(() => {
    onScreenLoad();

    return () => api.http.cancelAll();
  });

  const validationSchema = useMemo(() => {
    const requiredText = 'This is a required field.';
    return yup.object().shape({
      is_editing: yup.boolean(),
      id: yup.number().positive().integer(),
      first_name: yup.string().trim().min(2).required(requiredText),
      last_name: yup.string().trim().min(2).required(requiredText),
      email: yup.string().email('Invalid Email!').required(requiredText),
      alert_phone: yup.string().trim(),
      is_alert_enabled: yup.string().trim().min(2).required(requiredText),
      password: yup
        .string()
        .min(6)
        .when('is_editing', {
          is: false,
          then: yup.string().required('Must enter password!'),
        }),
      password_confirmation: yup
        .string()
        .when('is_editing', {
          is: false,
          then: yup.string().required('Must enter confirmation!'),
        })
        .test('passwords-match', 'Passwords must match!', function (value) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.parent.password === value;
        }),
    });
  }, []);

  const initialValues = {
    is_editing: isEditing,
    id: userId,
    first_name: isEditing ? user?.first_name : '',
    last_name: isEditing ? user?.last_name : '',
    email: isEditing ? user?.email : '',
    password: '',
    password_confirmation: '',
    alert_phone: isEditing ? user?.alert_phone : '',
    is_alert_enabled: isEditing ? user?.is_alert_enabled : '',
  };

  const submitForm = async (values, { setErrors }) => {
    const validateValues = (values) => {
      const clonedValues = { ...values };
      return validationSchema.cast(clonedValues);
    };

    const removeValuesIfBlank = (values) => {
      for (const field in values) {
        const isBlank = '' === `${values[field]}`.trim();
        if (isBlank) {
          delete values[field];
        }
      }
      return values;
    };

    try {
      const validValues = validateValues(values);
      removeValuesIfBlank(validValues);
      userNormalization.toAPI(validValues);

      await saveUser(validValues);

      setModal({
        message: 'Success!',
        onClose: goBack,
      });
    } catch (errors) {
      const isInternalError = 500 === api.http.getLastHttpStatus();
      const message = isInternalError
        ? errors
        : 'There are errors on the form.';
      setModal({
        isError: true,
        message,
        onClose: closeModal,
      });
      setErrors(errors);
    }
  };

  const blankPasswordLabelInfo = isEditing ? (
    <span className="italic text-gray-400">
      (leave it blank if not changing)
    </span>
  ) : null;

  const enabledAlertOptions = [
    { label: 'No', value: 'no' },
    { label: 'Email and SMS', value: 'email-and-sms' },
    { label: 'Email Only', value: 'only-email' },
    { label: 'SMS Only', value: 'only-sms' },
  ];

  const getMainArea = () => {
    if (isLoading) {
      return <FormSkeleton />;
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, values }) => (
          <Form id="form-user">
            {/* Tab Content. */}
            <div className="flex pb-20 flex-col max-w-xl">
              {/* Section */}
              <h2 className="pt-10 text-2xl">User Information</h2>

              {isEditing ? (
                <AvatarInput
                  avatarUrl={user?.avatar_url}
                  name={`${user?.first_name?.[0]}${user?.last_name?.[0]}`}
                  uploadUrl={`/admin/users/${user?.id}/avatar`}
                />
              ) : null}

              <Input
                autoFocus
                innerRef={inputAutoFocusRef}
                label="First Name"
                name="first_name"
                placeholder="ex. John"
                required
              />

              <Input
                label="Last Name"
                name="last_name"
                placeholder="ex. Smith"
              />

              {/* Section */}
              <h2 className="pt-10 text-2xl">Account Details</h2>

              <Input
                label="Email"
                name="email"
                placeholder="ex. john@smith.com"
                required
                type="email"
              />

              <Input
                label={<span>Password {blankPasswordLabelInfo}</span>}
                name="password"
                required={!isEditing}
                type="password"
              />

              <Input
                label={<span>Confirm Password {blankPasswordLabelInfo}</span>}
                name="password_confirmation"
                required={!isEditing}
                type="password"
              />

              {/* Section */}
              <h2 className="pt-10 text-2xl">Notifications</h2>

              <Input
                as={SelectFormik}
                label="Receive notifications?"
                name="is_alert_enabled"
                options={enabledAlertOptions}
                required
              />

              <div
                className={
                  'email-and-sms' === values.is_alert_enabled ||
                  'only-sms' === values.is_alert_enabled
                    ? ''
                    : 'hidden'
                }
              >
                <Input
                  label="Phone to receive SMS"
                  name="alert_phone"
                  placeholder="(310) 123-4455"
                />
              </div>
            </div>
            {/* /Tab Content.  */}

            <ActionButtons
              formId="form-user"
              isSubmitting={isSubmitting}
              labelSubmit={isEditing ? 'Save Changes' : 'Create User'}
              onClickCancel={goBack}
            />
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <AnimatedScreen>
      <div className="md:my-8 md:pb-12">
        {/* Error and Success Modal. */}
        <ModalInfo
          isError={modal?.isError}
          isOpened={!!modal}
          onClose={modal?.onClose}
          title={modal?.message}
        />

        {/* Card. */}
        <div className="flex flex-col px-5 md:px-14 pt-8 pb-16 md:rounded-lg bg-white shadow-custom">
          {/* Back button. */}
          <Link className="hidden md:flex flex-row items-center" to={urlBack}>
            <img alt="Back button" className="h-5" src={ChrevronLeft} />
            <h1 className="pl-6 text-2xl font-black uppercase">{pageTitle}</h1>
          </Link>

          {getMainArea()}
        </div>
        {/* /Card. */}
      </div>
    </AnimatedScreen>
  );
}

export default memo(UserFormScreen);
