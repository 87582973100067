import { memo } from 'react';
import { motion } from 'framer-motion';

const pageVariants = {
  in: {
    opacity: 0,
    x: 10,
  },
  out: {
    opacity: 1,
    x: 0,
  },
};

const pageTransition = {
  type: 'linear',
  duration: 0.125,
};

function AnimatedScreen(props) {
  const { children } = props;
  return (
    <motion.div
      animate="out"
      exit="in"
      initial="in"
      transition={pageTransition}
      variants={pageVariants}
    >
      {children}
    </motion.div>
  );
}

export default memo(AnimatedScreen);
