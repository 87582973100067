import { memo } from 'react';

function Header(props) {
  const { leftSide, rightSide, title } = props;
  return (
    <>
      {/* Row 1. */}
      <div className="flex flex-col md:flex-row md:h-28 mb-6 md:mb-0 items-center">
        {/* Header Left. */}
        <div className="flex flex-col flex-grow my-4">{leftSide}</div>

        {/* Header Right. */}
        <div className="flex flex-col md:flex-row md:items-center md:ml-auto">
          {rightSide}
        </div>
      </div>
      {/* /Row 1. */}

      {/* Row 2: Title. */}
      {title}

      <hr />
    </>
  );
}

export default memo(Header);
