import { memo } from 'react';

function TableCellQuestionType(props) {
  const { title } = props;

  const getTypeClasses = (type) => {
    switch (type) {
      case 'boolean':
        return 'bg-red-400 text-white';
      case 'integer':
        return 'bg-green-400 text-white';
      case 'blood_pressure':
        return 'bg-orange-400 text-white';
      default:
        return 'bg-blue-400 text-white';
    }
  };
  const typeClasses = getTypeClasses(title);

  const getTypeTitle = (type) => {
    switch (type) {
      case 'boolean':
        return 'Yes/No';
      case 'integer':
        return 'Numerical';
      case 'blood_pressure':
        return 'Blood Pressure';
      default:
        return type;
    }
  };
  const typeTitle = getTypeTitle(title);

  return (
    <div
      className={`py-1 px-3 w-24 text-center capitalize rounded ${typeClasses}`}
    >
      {typeTitle}
    </div>
  );
}

export default memo(TableCellQuestionType);
