import { memo } from 'react';

function ActionButtons(props) {
  const { isSubmitting, labelSubmit, onClickCancel, onClickSubmit, formId } =
    props;

  if (isSubmitting) {
    return (
      <div className="flex flex-row max-w-xl">
        <button
          className="h-12 w-full px-4 lg:px-6 bg-gray-300 text-white rounded font-medium flex items-center justify-center uppercase"
          disabled
          type="button"
        >
          Sending...
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-row max-w-xl gap-4">
      <button
        className="flex-1 md:flex-initial h-12 px-4 lg:px-6 bg-gray-300 text-white rounded font-medium flex items-center justify-center focus:ring-2 focus:ring-blue-600"
        onClick={onClickCancel}
        type="button"
      >
        Cancel
      </button>
      <button
        className="flex-1 md:flex-initial h-12 w-1/2 px-4 lg:px-6 ml-auto bg-primary text-white rounded font-medium flex items-center justify-center focus:ring-2 focus:ring-blue-900"
        form={formId}
        onClick={onClickSubmit}
        type="submit"
      >
        {labelSubmit}
      </button>
    </div>
  );
}

export default memo(ActionButtons);
