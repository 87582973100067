import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import Header from '../../../../shared-components/Header';
import ActionBar from '../../../../shared-components/Header/ActionBar';
import PrimaryButton from '../../../../shared-components/Header/ActionBar/PrimaryButton';
import TitleBar from '../../../../shared-components/Header/TitleBar';
import CardsArea from './CardsArea';
import ModalInfo from '../../../../shared-components/ModalInfo';
import BackBar from '../../../../shared-components/Header/BackBar';
import AgencyLogo from '../../../../shared-components/AgencyLogo';
import useEffectOnce from '../../../../utils/useEffectOnce';
import { ApiContext } from '../../../../contexts/api';
import AnimatedScreen from '../../../../shared-components/AnimatedScreen';
import { GlobalContext } from '../../../../contexts/global';

function AgentListScreen() {
  const params = useParams();
  const [agents, setAgents] = useState([]);
  const [agency, setAgency] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modalError, setModalError] = useState(null);
  const api = useContext(ApiContext);
  const globalContext = useContext(GlobalContext);

  const agencyId = params.agencyId ?? api?.user?.agency?.id;
  const role = useMemo(() => api?.user.role, [api]);
  const isAdmin = useMemo(
    () => 'super-admin' === role || 'admin' === role,
    [role]
  );

  const pageTitle = 'Agency Agents';

  const fetchAgency = async (id) => {
    const apiResult = await api.http.get(`/admin/agencies/${id}`);
    setAgency(apiResult);
  };

  const fetchAgents = async () => {
    const apiResult = await api.http.get(`/admin/agencies/${agencyId}/agents`);
    setAgents(apiResult);
  };

  const urlBack = useMemo(() => {
    return isAdmin ? `/admin/agencies/${agencyId}/details` : '/';
  }, [agencyId, isAdmin]);

  useEffect(() => {
    const setGlobalToolbar = () => {
      globalContext.setGlobalTitle(pageTitle);
      globalContext.setUrlBack(urlBack);
      globalContext.setEnabledSearch(false);
    };
    setGlobalToolbar();
  }, [globalContext, pageTitle, urlBack]);

  const onScreenLoad = async () => {
    try {
      await fetchAgency(agencyId);
      await fetchAgents();
      setIsLoading(false);
    } catch (message) {
      setModalError(message);
    }
  };

  const scrollToTop = () => {
    document.getElementById('main-area').scrollTop = 0;
  };

  useEffectOnce(() => {
    scrollToTop();
    onScreenLoad();

    return () => api.http.cancelAll();
  });

  const agencyLogo = <AgencyLogo agency={agency} textNotAvailable="N/A" />;

  const headerLeftSide = (
    <div className="hidden md:flex">
      <BackBar logo={agencyLogo} title={agency?.name} url={urlBack} />
    </div>
  );

  const urlAddMember = useMemo(() => {
    return isAdmin
      ? `/admin/agencies/${agencyId}/agents/new`
      : '/admin/agents/new';
  }, [agencyId, isAdmin]);

  const headerRightSide = (
    <ActionBar>
      <PrimaryButton title="Add Agent" url={urlAddMember} />
    </ActionBar>
  );

  const headerTitle = <TitleBar title={pageTitle} />;

  return (
    <AnimatedScreen>
      <div className="px-3 md:px-0 md:pb-12">
        <Header
          leftSide={headerLeftSide}
          rightSide={headerRightSide}
          title={headerTitle}
        />

        {/* Error Modal. */}
        <ModalInfo
          isError
          isOpened={!!modalError}
          onClose={() => setModalError(null)}
          title={modalError}
        />

        {/* Main Area. */}
        <div className="my-8 pb-12">
          <CardsArea agents={agents} isAdmin={isAdmin} isLoading={isLoading} />
        </div>
      </div>
    </AnimatedScreen>
  );
}

export default memo(AgentListScreen);
