import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Header from '../../../../shared-components/Header';
import ActionBar from '../../../../shared-components/Header/ActionBar';
import PrimaryButton from '../../../../shared-components/Header/ActionBar/PrimaryButton';
import TitleBar from '../../../../shared-components/Header/TitleBar';
import ModalInfo from '../../../../shared-components/ModalInfo';
import useEffectOnce from '../../../../utils/useEffectOnce';
import Table from '../../../../shared-components/Table';
import NormalButton from '../../../../shared-components/Header/ActionBar/NormalButton';
import ButtonEdit from '../../../../shared-components/Table/ButtonEdit';
import { ApiContext } from '../../../../contexts/api';
import AnimatedScreen from '../../../../shared-components/AnimatedScreen';
import { GlobalContext } from '../../../../contexts/global';
import TableCellQuestionType from './TableCellQuestionType';

function QuestionListScreen() {
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [filtered, setFiltered] = useState([]);
  const api = useContext(ApiContext);
  const globalContext = useContext(GlobalContext);

  const pageTitle = 'Questions';

  const fetchQuestions = async () => {
    const apiResult = await api.http.get('/admin/questions');
    setQuestions(apiResult);
  };

  const closeModal = () => {
    setModal(null);
  };

  useEffect(() => {
    const setGlobalToolbar = () => {
      globalContext.setGlobalTitle(pageTitle);
      globalContext.setUrlBack(null);
      globalContext.setEnabledSearch(true);
      globalContext.setSearchValue('');
    };
    setGlobalToolbar();
  }, [globalContext, pageTitle]);

  const onScreenLoad = async () => {
    try {
      await fetchQuestions();
      setIsLoading(false);
    } catch (message) {
      setModal({
        isError: true,
        message,
        onClose: closeModal,
      });
    }
  };

  useEffectOnce(() => {
    onScreenLoad();

    return () => api.http.cancelAll();
  });

  useEffect(() => {
    setSearchValue(globalContext.searchValue);
  }, [globalContext.searchValue]);

  const onChangeSearch = useCallback(() => {
    const filtered = questions.filter((question) => {
      const lowerSearchValue = searchValue.toLowerCase();

      const isOnLabel = question.label.toLowerCase().includes(lowerSearchValue);
      if (isOnLabel) {
        return true;
      }

      const isOnGroupLabel = question.group_label
        .toLowerCase()
        .includes(lowerSearchValue);
      if (isOnGroupLabel) {
        return true;
      }

      return false;
    });
    setFiltered(filtered);
  }, [questions, searchValue]);

  useEffect(() => {
    onChangeSearch();
  }, [onChangeSearch, searchValue, questions]);

  const headerRightSide = (
    <ActionBar>
      <NormalButton title="Question Groups" url="/admin/question-groups" />
      <PrimaryButton title="New Question" url="/admin/questions/new" />
    </ActionBar>
  );

  const headerTitle = (
    <TitleBar
      enabledFilter={false}
      enabledSearch
      onSearchChange={setSearchValue}
      searchPlaceholder="Search by Alexa question, label, group name."
      title={pageTitle}
    />
  );

  const getTableRows = useCallback((questions) => {
    return questions.map((question) => [
      question.label,
      question.assistant_label,
      <TableCellQuestionType key={question.id} title={question.type} />,
      question.group_label,
      <ButtonEdit
        key={question.id}
        to={`/admin/questions/${question.id}/edit`}
      />,
    ]);
  }, []);

  const tableData = useMemo(
    () => getTableRows(filtered),
    [filtered, getTableRows]
  );

  const headers = [
    { label: 'Label', className: 'w-auto', isSortable: true },
    { label: 'Alexa Question', className: 'w-auto', isSortable: true },
    { label: 'Type', className: 'w-auto' },
    { label: 'Group', className: 'w-auto', isSortable: true },
    { label: 'Actions', className: 'w-auto text-center' },
  ];

  return (
    <AnimatedScreen>
      <div className="px-3 md:px-0 md:pb-12">
        <Header leftSide={headerTitle} rightSide={headerRightSide} />

        {/* Error and Success Modal. */}
        <ModalInfo
          isError={modal?.isError}
          isOpened={!!modal}
          onClose={modal?.onClose}
          title={modal?.message}
        />

        {/* Main Area. */}
        <div className="my-8 pb-12">
          <Table
            data={tableData}
            headers={headers}
            isLoading={isLoading}
            isSortable
          />
        </div>
      </div>
    </AnimatedScreen>
  );
}

export default memo(QuestionListScreen);
