import { memo } from 'react';
import IconLoading from './../../../../../assets/images/icon-loading-dual-ring.svg';
import Avatar from './Avatar';

function CardNotification(props) {
  const { alertDay, onConfirm, isProcessing } = props;
  const { patient } = alertDay;

  return (
    <li className="flex flex-wrap h-22 py-6 px-10 gap-6 justify-center items-center bg-gray-50 rounded">
      {/* Avatar. */}
      {/* <img className="h-16 rounded-full" src={patient.avatar_url} alt="patient avatar" /> */}
      <Avatar user={patient} />

      {/* Name/Message. */}
      <div className="flex flex-col">
        <p className="text-xl font-extrabold text-primary">
          {patient.first_name} {patient.last_name}
        </p>
        <p className="mt-1.5 font-medium">{getAlertMessages(alertDay)}</p>
      </div>

      {/* "Confirm" button. */}
      {!isProcessing ? (
        <button
          className="ml-auto w-full md:w-36 p-3 text-primary font-bold border-2 border-primary rounded text-center"
          onClick={() => onConfirm(alertDay)}
          type="button"
        >
          Confirm
        </button>
      ) : (
        <div className="filter grayscale flex ml-auto w-36 p-2 py-3 bg-purple-100 border-2 border-purple-600 rounded justify-center items-center cursor-not-allowed">
          <img
            alt="loading"
            className="flex h-6 w-6 animate-spin"
            src={IconLoading}
          />
          <span className="ml-2 text-purple-600 font-bold">Saving ...</span>
        </div>
      )}

      {/* Time. */}
      <p className="flex w-20 text-right text-xs text-gray-400 italic">
        {alertDay.answered_ago}
      </p>
    </li>
  );
}

const getAlertMessages = (alertDay) => {
  const messages = alertDay.answers.map((answer) => {
    const hasHitThreshold = answer.is_threshold_hit;
    if (!hasHitThreshold) {
      return null;
    }

    const isWeight = 'weight' === answer.question.type;
    if (isWeight) {
      return `Weight Change`;
    }

    const isBoolean = 'boolean' === answer.question.type;
    if (isBoolean) {
      const isTrue = !!answer.value;
      if (isTrue) {
        return answer.question.label;
      }
      return `not ${answer.question.label}`;
    }

    const isBloodPressure = 'blood_pressure' === answer.question.type;
    if (isBloodPressure) {
      return `${answer.question.label} of ${answer.value}/${answer.value2}`;
    }

    return `${answer.question.label} to ${answer.value}`;
  });

  return `Has ` + messages.filter((n) => n).join(', ');
};

export default memo(CardNotification);
