import {
  useContext,
  useCallback,
  useState,
  useMemo,
  createContext,
} from 'react';
import { useFormikContext } from 'formik';

const ContactFormContext = createContext();

function useContactForm() {
  const context = useContext(ContactFormContext);

  if (!context) {
    throw new Error('useContactForm must be used within a ContactFormProvider');
  }

  return context;
}

export function ContactFormProvider({ children }) {
  const [activeContactTabIndex, setActiveContactTabIndex] = useState(0);

  const { values, setFieldValue } = useFormikContext();

  const setContacts = useCallback(
    (contacts) => {
      setFieldValue('contacts', contacts);
    },
    [setFieldValue]
  );

  const contextValue = useMemo(
    () => ({
      contacts: values.contacts,
      setContacts,
      activeContactTabIndex,
      setActiveContactTabIndex,
    }),
    [
      values.contacts,
      setContacts,
      activeContactTabIndex,
      setActiveContactTabIndex,
    ]
  );

  return (
    <ContactFormContext.Provider value={contextValue}>
      {children}
    </ContactFormContext.Provider>
  );
}

export { ContactFormContext, useContactForm };
