import { memo } from 'react';
import dayjs from 'dayjs';

import DayItem from '../DayItem';

function DayList(props) {
  const { days, today_index, history_size: propsHistorySize } = props;
  const one_week = 7;
  const history_size = propsHistorySize ?? one_week;
  const history_range = [...Array(history_size).keys()];

  const has_no_days = 0 === days.length;
  if (has_no_days) {
    return (
      <p className="flex flex-1 italic pb-12 text-gray-500 text-bold self-center items-center">
        (No Data)
      </p>
    );
  }

  const list = history_range.map((i) => {
    const day_index = today_index - i;
    const day = days.find((element) => element.day_index === day_index);
    const day_at = dayjs().subtract(i, 'days');
    return (
      <DayItem
        day={day}
        day_at={day_at}
        day_index={day_index}
        key={`day_${i}`}
      />
    );
  });

  return list;
}

export default memo(DayList);
