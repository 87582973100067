import { memo } from 'react';
import AgencyLogo from '../../AgencyLogo';

function Agency(props) {
  const { agency } = props;

  const hasNoAgency = !agency?.id;
  if (hasNoAgency) {
    return <p className="italic text-gray-400">None</p>;
  }

  return (
    <div className="flex">
      <div className="flex h-12 w-12 bg-white p-1 items-center justify-center rounded border border-gray-300">
        <AgencyLogo agency={agency} textNotAvailable="N/A" />
      </div>
      <span className="pl-4 self-center">{agency.name}</span>
    </div>
  );
}

export default memo(Agency);
