import { memo } from 'react';

import IconGenderMale from './../../assets/images/icon-male.svg';
import IconGenderFemale from './../../assets/images/icon-female.png';
import IconGenderOther from './../../assets/images/icon-close-rounded.svg';

function IconGender(props) {
  const { gender, className } = props;

  const getIconGender = () => {
    const isFemale = 'female' === gender;
    if (isFemale) {
      return IconGenderFemale;
    }

    const isMale = 'male' === gender;
    if (isMale) {
      return IconGenderMale;
    }

    return IconGenderOther;
  };
  const IconGender = getIconGender();

  return (
    <img
      alt={`Gender ${gender}`}
      className={className ?? 'h5'}
      src={IconGender}
    />
  );
}

export default memo(IconGender);
