import { memo } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Navigate, Route, Routes, useLocation } from 'react-router';

import PatientDetailsScreen from '../../../../screens/admin/patients/Details';
import PatientFormScreen from '../../../../screens/admin/patients/Form';
import SettingsFormScreen from '../../../../screens/admin/settings/Form';
import Page404Screen from '../../../../screens/misc/Page404';

function UserRoutesPatient() {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes key={location.pathname} location={location}>
        {/* Default: redirect. */}
        <Route element={<Navigate to="/admin/patient" />} path="/" />

        {/* Main Menu: Patient Details. */}
        <Route element={<PatientDetailsScreen />} path="/patient" />

        {/* Main Menu: Patient Form - Edit. */}
        <Route element={<PatientFormScreen />} path="/patient/edit" />

        {/* Main Menu: Settings. */}
        <Route element={<SettingsFormScreen />} path="/settings" />

        {/* 404 */}
        <Route element={<Page404Screen />} />
      </Routes>
    </AnimatePresence>
  );
}

export default memo(UserRoutesPatient);
