import { memo } from 'react';

function AnswerValue(props) {
  const { question, answer } = props;

  const getFormattedAnswer = (type, value) => {
    if ('boolean' === type) {
      const is_yes = true === value;
      if (is_yes) {
        return <strong className="text-red-300">Yes</strong>;
      }
      return <strong className="text-primary-light">No</strong>;
    }

    return <strong className="text-green-300">{value}</strong>;
  };

  const formattedValue = getFormattedAnswer(question.type, answer.value);

  return (
    <li>
      {question.label}: {formattedValue}
    </li>
  );
}

export default memo(AnswerValue);
