import { memo } from 'react';
import FormattedValue from '../FormattedValue';

function QuestionCard(props) {
  const { question, today_index } = props;

  const daysHtml = [0, 1, 2].map((i) => {
    const day_index = today_index - 3 + i + 1;
    const day = question.days.find(
      (element) => element.day_index === day_index
    );

    return (
      <li key={`day_answer_${question.id}_${i}`}>
        <FormattedValue answer={day?.answer} question={question} />
        <p className="mt-4 text-gray-600 text-xs uppercase text-center">
          Day {day_index + 1}
        </p>
      </li>
    );
  });

  return (
    <div className="py-6 px-8 flex flex-col rounded-lg shadow-custom bg-white">
      {/* Title. */}
      <h3 className="text-2xl font-black">{question.label}</h3>

      {/* Three-day. */}
      <ul className="pt-6 flex flex-row place-content-between">
        {/* Days. */}
        {daysHtml}
      </ul>
    </div>
  );
}

export default memo(QuestionCard);
