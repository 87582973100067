import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import dayjs from 'dayjs';

import ActionBar from '../../../../shared-components/Header/ActionBar';
import PrimaryButton from '../../../../shared-components/Header/ActionBar/PrimaryButton';
import Header from '../../../../shared-components/Header';
import BackBar from '../../../../shared-components/Header/BackBar';
import ChartBar from './ChartBar';
import ModalInfo from '../../../../shared-components/ModalInfo';
import AgencyLogo from '../../../../shared-components/AgencyLogo';
import useEffectOnce from '../../../../utils/useEffectOnce';
import { ApiContext } from '../../../../contexts/api';
import MainAreaSkeleton from './MainAreaSkeleton';
import IconGender from '../../../../shared-components/IconGender';
import AnimatedScreen from '../../../../shared-components/AnimatedScreen';
import HistoryTable from './HistoryTable';
import { GlobalContext } from '../../../../contexts/global';
import QuestionCard from './QuestionCard';
import NotificationsArea from './NotificationsArea';

function PatientDetails() {
  const { agencyId, ...params } = useParams();
  const isPatientsMenu = !agencyId;
  const [patient, setPatient] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [modalError, setModalError] = useState(null);
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const [fullHistory, setFullHistory] = useState([]);
  const api = useContext(ApiContext);
  const globalContext = useContext(GlobalContext);
  const [alertDays, setAlertDays] = useState([]);

  const patientId = params.patientId ?? api?.user?.patient?.id;
  const role = useMemo(() => api?.user.role, [api]);
  const isAdmin = useMemo(
    () => 'super-admin' === role || 'admin' === role,
    [role]
  );
  const isAgency = useMemo(() => 'agency-admin' === role, [role]);
  const isPatient = useMemo(() => 'patient' === role, [role]);

  const pageTitle = 'Patient Details';

  const fetchPatient = async (id) => {
    const url = `/admin/patients/${id}`;
    const params = {
      'history-mode': 'questions',
      'weight-days': 7,
    };
    const apiResult = await api.http.get(url, { params });
    setPatient(apiResult);
    setAlertDays(apiResult.active_alerts);
  };

  const fetchFullHistory = async (patientId) => {
    const url = `/admin/patients/${patientId}`;
    const params = {
      'history-mode': 'full-days',
    };
    const apiResult = await api.http.get(url, { params });
    setFullHistory(apiResult.days);
  };

  const urlBack = useMemo(() => {
    if (isAdmin) {
      return isPatientsMenu
        ? `/admin/patients`
        : `/admin/agencies/${patient?.agency.id}/details`;
    }

    if (isAgency) {
      return '/';
    }

    if (isPatient) {
      return null;
    }

    return '/';
  }, [isAdmin, isAgency, isPatient, isPatientsMenu, patient]);

  useEffect(() => {
    const setGlobalToolbar = () => {
      globalContext.setGlobalTitle(pageTitle);
      globalContext.setUrlBack(urlBack);
      globalContext.setEnabledSearch(false);
    };
    setGlobalToolbar();
  }, [globalContext, pageTitle, urlBack]);

  const onScreenLoad = async () => {
    try {
      await fetchPatient(patientId);
      setIsLoading(false);

      await fetchFullHistory(patientId);
      setIsLoadingHistory(false);
    } catch (error) {
      setModalError(error);
    }
  };

  useEffectOnce(() => {
    onScreenLoad();

    return () => api.http.cancelAll();
  });

  const chartData = useMemo(() => {
    const isPatientUndefined = !patient;
    if (isPatientUndefined) {
      return [];
    }

    const todayIndex = patient?.today_index;
    const weights = patient?.weight_history;

    const labels = [];
    const values = [];
    const colors = [];
    [0, 1, 2, 3, 4, 5, 6].forEach((i) => {
      const day_index = todayIndex - 7 + i + 1;
      const is_today = 6 === i;
      const date = dayjs()
        .subtract(7 - i - 1, 'day')
        .format('MMM D');
      labels.push([is_today ? 'Today' : date, `(Day ${day_index + 1})`]);

      const weight = weights.find((element) => element.day_index === day_index);
      const is_not_found = !weight;
      values.push(is_not_found ? null : weight.answer.value);

      const is_threshold_hit = weight?.answer?.is_threshold_hit;
      const color = is_threshold_hit ? 'red' : '#10b981';
      colors.push(is_not_found ? null : color);
    });

    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Weight',
          data: values,
          backgroundColor: colors,
        },
      ],
    };

    return data;
  }, [patient]);

  const mainArea = useMemo(() => {
    if (isLoading) {
      return <MainAreaSkeleton />;
    }

    const questionCards = patient.questions.map((question) => (
      <QuestionCard
        key={`questions_${question.id}`}
        question={question}
        today_index={patient.today_index}
      />
    ));

    return (
      <div>
        {/* Notifications. */}
        {isAdmin || isAgency ? (
          <NotificationsArea
            alertDays={alertDays}
            setAlertDays={setAlertDays}
          />
        ) : null}

        {/* Header Card. */}
        <div className="flex pt-6 pb-9 md:py-9 px-7 flex-col md:flex-row text-xl md:text-2xl rounded-lg flex-grow items-center bg-white">
          {/* Avatar. */}
          {patient.user.avatar_url ? (
            <img
              alt="patient avatar"
              className="h-20 w-20 mt-4 md:mt-0 rounded-full"
              src={patient.user.avatar_url}
            />
          ) : (
            <div className="w-20 h-20 block m-auto">
              <span className="w-20 h-20 rounded-full bg-green-600 text-white font-bold flex justify-center items-center">
                {patient.user?.first_name[0]}
                {patient.user?.last_name[0]}
              </span>
            </div>
          )}

          {/* Columns. */}
          <ul className="w-full flex px-6 my-4 md:my-0 flex-col md:flex-row flex-wrap justify-between flex-grow">
            {/* Patient ID. */}
            <li className="md:py-0 py-2 px-2">
              <p>Patient ID#</p>
              <p className="text-primary-light font-extrabold">
                {patient.patient_code}
              </p>
            </li>

            <li className="hidden md:block border-l border-gray-200" />

            {/* Name. */}
            <li className="md:py-0 py-2 px-2">
              <p>Name</p>
              <p className="text-primary-light font-extrabold">
                {patient.user.last_name}, {patient.user.first_name}
              </p>
            </li>

            <li className="hidden md:block border-l border-gray-200" />

            {/* Gender. */}
            <li className="md:py-0 py-2 px-2">
              <p>Gender</p>
              <div className="flex items-center">
                <IconGender className="h-5 pr-2" gender={patient.gender} />
                <p className="text-primary-light font-extrabold capitalize">
                  {patient.gender}
                </p>
              </div>
            </li>

            <li className="hidden md:block border-l border-gray-200" />

            {/* Birthday. */}
            <li className="md:py-0 py-2 px-2">
              <p>Birthday</p>
              <p className="text-primary-light font-extrabold">
                {dayjs(patient.dob).format('MMM D, YYYY')}
              </p>
            </li>

            <li className="hidden md:block border-l border-gray-200" />

            {/* Age. */}
            <li className="md:py-0 py-2 px-2">
              <p>Age</p>
              <p className="text-primary-light font-extrabold">{patient.age}</p>
            </li>

            <li className="" />
          </ul>
          {/* Columns. */}
        </div>
        {/* /Header Card. */}

        {/* Cards Area. */}
        <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 w-full my-9 place-content-start md:gap-11 gap-y-11">
          {/* Main Card. */}
          <div className="col-span-2 row-span-3 p-8 flex flex-col rounded-lg shadow-custom bg-white">
            {/* Card Title. */}
            <h3 className="text-2xl">
              <span className="font-black uppercase">7 Day Weight</span>
              <span className="px-3">|</span>
              <span className="text-primary">
                Day {patient.today_index + 1}
              </span>
            </h3>

            {/* Graph Bars. */}
            <div className="flex pt-7 items-center justify-center">
              <ChartBar
                data={chartData}
                options={{
                  legend: {
                    display: true,
                    labels: {
                      generateLabels: () => {
                        return [
                          {
                            text: 'Weight',
                            fillStyle: '#10b981',
                          },
                        ];
                      },
                    },
                  },
                }}
              />
            </div>
          </div>

          {/* Cards. */}
          {questionCards}
        </div>
        {/* /Cards Area. */}

        {/* Patient Full History */}
        <HistoryTable
          dayRows={fullHistory}
          isLoading={isLoadingHistory}
          questionsHeader={patient.patient_questions.map(
            (patientQuestion) => patientQuestion.question
          )}
          todayIndex={patient.today_index}
        />
      </div>
    );
  }, [
    alertDays,
    chartData,
    fullHistory,
    isAdmin,
    isAgency,
    isLoading,
    isLoadingHistory,
    patient,
  ]);

  const agencyLogo = (
    <AgencyLogo agency={patient?.agency} textNotAvailable="N/A" />
  );

  const titleBack = useMemo(() => {
    if (isAdmin) {
      const title = isPatientsMenu ? 'Back to Patients' : 'Back to Agency';
      return title;
    }

    if (isAgency) {
      return 'Back to Patients';
    }

    if (isPatient) {
      return 'My Profile';
    }

    return '';
  }, [isAdmin, isAgency, isPatient, isPatientsMenu]);

  const headerLeftSide = (
    <div className="hidden md:flex">
      <BackBar logo={agencyLogo} title={titleBack} url={urlBack} />
    </div>
  );

  const urlEdit = useMemo(() => {
    if (isAdmin) {
      return isPatientsMenu
        ? `/admin/patients/${patient?.id}/edit`
        : `/admin/agencies/${patient?.agency.id}/patients/${patient?.id}/edit`;
    }

    if (isAgency) {
      return `/admin/patients/${patient?.id}/edit`;
    }

    if (isPatient) {
      return '/admin/patient/edit';
    }

    return '/';
  }, [isAdmin, isAgency, isPatient, isPatientsMenu, patient]);

  const headerRightSide = (
    <ActionBar>
      <PrimaryButton title="Edit Patient" url={urlEdit} />
    </ActionBar>
  );

  return (
    <AnimatedScreen>
      <div className="px-3 md:px-0 md:pb-12">
        <Header leftSide={headerLeftSide} rightSide={headerRightSide} />

        {/* Error Modal. */}
        <ModalInfo
          isError
          isOpened={!!modalError}
          onClose={() => setModalError(null)}
          title={modalError}
        />

        {/* Main Area. */}
        <div className="my-8 pb-12">{mainArea}</div>
      </div>
    </AnimatedScreen>
  );
}

export default memo(PatientDetails);
