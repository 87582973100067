import { memo, useContext, useState } from 'react';
import CardNotification from '../CardNotification';
import { ApiContext } from '../../../../../contexts/api';
import ModalInfo from '../../../../../shared-components/ModalInfo';

function NotificationsArea(props) {
  const { alertDays, setAlertDays } = props;
  const [isSavingAlertIds, setIsSavingAlertIds] = useState([]);
  const api = useContext(ApiContext);
  const [modalError, setModalError] = useState(null);

  const checkinAlert = async (patientId) => {
    const url = `/admin/alerts/${patientId}/check-in`;
    const apiResult = await api.http.post(url);
    setAlertDays(apiResult.active_alerts);
    await api.alerts.refresh();
  };

  const confirmNotification = async (alertDay) => {
    try {
      const setIsSavingAlert = (id) => {
        const newIsSavingAlertIds = [...isSavingAlertIds];
        newIsSavingAlertIds.push(id);
        setIsSavingAlertIds(newIsSavingAlertIds);
      };

      setIsSavingAlert(alertDay.id);
      await checkinAlert(alertDay.id);
    } catch (error) {
      setModalError(error);
    }
  };

  const hasNoAlerts = 0 === alertDays.length;
  if (hasNoAlerts) {
    return null;
  }

  const alertCards = alertDays.map((alertDay) => {
    const isSaving = isSavingAlertIds.find((id) => id === alertDay.id);
    return (
      <CardNotification
        alertDay={alertDay}
        isProcessing={isSaving}
        key={`notification-item-${alertDay.id}`}
        onConfirm={confirmNotification}
      />
    );
  });

  return (
    <div
      className="flex mb-9 px-3 md:px-11 pb-6 md:pb-11 flex-col rounded-lg flex-grow bg-white"
      id="notifications-area"
    >
      {/* Error Modal. */}
      <ModalInfo
        isError
        isOpened={!!modalError}
        onClose={() => setModalError(null)}
        title={modalError}
      />

      {/* Title. */}
      <h3 className="flex p-7 w-full text-xl font-extrabold justify-center border-b border-gray-200">
        New Notifications ({alertDays.length})
      </h3>

      {/* Items. */}
      <ul className="flex flex-col mt-5 gap-3 w-full">{alertCards}</ul>
    </div>
  );
}

export default memo(NotificationsArea);
