import { memo } from 'react';
import { Link } from 'react-router-dom';

function CardAgencyAdmin(props) {
  const { agencyAdmin, url } = props;

  return (
    <Link
      className="flex flex-col h-36 rounded-lg shadow-custom overflow-hidden bg-white"
      to={url}
    >
      {/* Three Squares. */}
      <div className="flex flex-row px-7 py-6 space-x-4">
        {/* Avatar. */}
        <div className="flex h-24 w-24 p-1 items-center justify-center rounded border border-gray-300">
          {agencyAdmin.user.avatar_url ? (
            <img
              alt="Agent Avatar"
              className="w-full h-full object-cover"
              src={agencyAdmin.user.avatar_url}
            />
          ) : (
            <div className="w-full h-full bg-green-600 text-white text-2xl font-bold flex justify-center items-center">
              {agencyAdmin.user?.first_name[0]}
              {agencyAdmin.user?.last_name[0]}
            </div>
          )}
        </div>

        {/* Text. */}
        <div className="flex flex-col">
          <h3 className="capitalize text-xl font-bold">
            {agencyAdmin.user.last_name}, {agencyAdmin.user.first_name}
          </h3>
          <p className="mt-3 italic text-gray-500 break-all">
            {agencyAdmin.user.email}
          </p>
        </div>
      </div>
      {/* /Three Squares. */}
    </Link>
  );
}

export default memo(CardAgencyAdmin);
