import { memo } from 'react';
import { Field, useField } from 'formik';

function CheckboxSingleFormik(props) {
  const { option, checkboxLabelStyle: labelStyle } = props;
  const [field] = useField(props);
  const { name } = field;

  return (
    <label className={labelStyle} key={`checkbox_${name}_${option.value}`}>
      <Field name={name} type="checkbox" value={option.value} />
      <span className="ml-3">{option.label}</span>
    </label>
  );
}

export default memo(CheckboxSingleFormik);
