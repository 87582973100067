import { createContext, useMemo, useState } from 'react';

const GlobalContext = createContext({});

function GlobalProvider(props) {
  const { children } = props;
  const [globalTitle, setGlobalTitle] = useState(null);
  const [urlBack, setUrlBack] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  // TO-DO: rename `setEnabledSearch` to `setEnabledSearchBar`
  // eslint-disable-next-line react/hook-use-state
  const [enabledSearchBar, setEnabledSearch] = useState(false);

  const initialValues = useMemo(
    () => ({
      globalTitle,
      setGlobalTitle,
      urlBack,
      setUrlBack,
      enabledSearchBar,
      setEnabledSearch,
      searchValue,
      setSearchValue,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <GlobalContext.Provider value={initialValues}>
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalProvider;
export { GlobalContext };
