import { memo } from 'react';
import { ErrorMessage, FastField, useField } from 'formik';

import SelectFormik from './SelectFormik';

function Input(props) {
  const { containerstyle, labelstyle, inputstyle, errorstyle, type } = props;
  const { name, value, label, required, as, disableError } = props;
  const { dontRender, ...rest } = props;
  const [, meta] = useField(props);
  const { error, touched } = meta;

  const colorFocusRing = touched && error ? 'red' : 'blue';
  const inputBgColor =
    touched && error ? 'focus:bg-red-100' : 'focus:bg-blue-100';
  const isSelectFormik = as && as === SelectFormik;

  const defaultContainerStyle = 'flex flex-col mt-8';
  const defaultLabelStyle = '';
  const defaultInputStyle = `h-12 mt-3 px-6 rounded-lg border focus:ring-2 focus:ring-${colorFocusRing}-600 ${inputBgColor}`;
  const defaultSelectStyle = `h-12 mt-3 px-0 rounded-lg border focus:ring-2 focus:ring-${colorFocusRing}-600 ${inputBgColor}`;
  const defaultErrorStyle = 'mt-2 ml-1 text-red-500 text-xs italic';

  const containerStyle = containerstyle ?? defaultContainerStyle;
  const labelStyle = labelstyle ?? defaultLabelStyle;
  const inputStyle =
    inputstyle ?? (isSelectFormik ? defaultSelectStyle : defaultInputStyle);
  const errorStyle = errorstyle ?? defaultErrorStyle;

  const requiredText = required ? (
    <span className="text-red-500"> *</span>
  ) : null;

  if (dontRender) {
    return null;
  }

  if (type === 'hidden') {
    return <FastField name={name} type="hidden" value={value} />;
  }

  return (
    <div className={containerStyle}>
      <label className={labelStyle} htmlFor={name}>
        {label}
        {requiredText}
      </label>
      <FastField className={inputStyle} type="text" {...rest} />
      {true === disableError ? null : (
        <ErrorMessage className={errorStyle} component="div" name={name} />
      )}
    </div>
  );
}

export default memo(Input);
