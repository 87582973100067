import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import Input from '../../../../shared-components/Form/Input';
import ModalInfo from '../../../../shared-components/ModalInfo';
import ChrevronLeft from './../../../../assets/images/icon-chevron-left.svg';
import useEffectOnce from '../../../../utils/useEffectOnce';
import ActionButtons from '../../../../shared-components/Form/ActionButtons';
import { ApiContext } from '../../../../contexts/api';
import FormSkeleton from '../../../../shared-components/Form/FormSkeleton';
import AnimatedScreen from '../../../../shared-components/AnimatedScreen';
import { GlobalContext } from '../../../../contexts/global';

function QuestionGroupFormScreen() {
  const { questionGroupId } = useParams();
  const isEditing = !!questionGroupId;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [questionGroup, setQuestionGroup] = useState();
  const [modal, setModal] = useState(null);
  const inputAutoFocusRef = useRef();
  const api = useContext(ApiContext);
  const globalContext = useContext(GlobalContext);

  const pageTitle = isEditing ? 'Edit Question Group' : 'Add Question Group';

  const fetchQuestionGroup = async (id) => {
    const apiResult = await api.http.get(`/admin/question-groups/${id}`);
    setQuestionGroup(apiResult);
  };

  const saveQuestionGroup = async ({ id, ...params }) => {
    const baseUrl = '/admin/question-groups';
    const apiResult = id
      ? await api.http.put(`${baseUrl}/${id}`, params)
      : await api.http.post(baseUrl, params);
    setQuestionGroup(apiResult);
  };

  const closeModal = () => {
    setModal(null);
    inputAutoFocusRef.current.focus();
  };

  const urlBack = useMemo(() => {
    return `/admin/question-groups`;
  }, []);

  const goBack = () => {
    navigate(urlBack);
  };

  useEffect(() => {
    const setGlobalToolbar = () => {
      globalContext.setGlobalTitle(pageTitle);
      globalContext.setUrlBack(urlBack);
      globalContext.setEnabledSearch(false);
    };
    setGlobalToolbar();
  }, [globalContext, pageTitle, urlBack]);

  const onScreenLoad = async () => {
    try {
      if (isEditing) {
        await fetchQuestionGroup(questionGroupId);
      }

      setIsLoading(false);
    } catch (message) {
      setModal({
        isError: true,
        message,
        onClose: goBack,
      });
    }
  };

  useEffectOnce(() => {
    onScreenLoad();

    return () => api.http.cancelAll();
  });

  const validationSchema = useMemo(() => {
    const requiredText = 'This is a required field.';
    return yup.object().shape({
      is_editing: yup.boolean(),
      id: yup.number().positive().integer(),
      label: yup.string().trim().min(2).required(requiredText),
    });
  }, []);

  const initialValues = {
    is_editing: isEditing,
    id: questionGroupId,
    label: isEditing ? questionGroup?.label : '',
  };

  const submitForm = async (values, { setErrors }) => {
    const validateValues = (values) => {
      const clonedValues = { ...values };
      return validationSchema.cast(clonedValues);
    };

    try {
      const validValues = validateValues(values);
      await saveQuestionGroup(validValues);

      setModal({
        message: 'Success!',
        onClose: goBack,
      });
    } catch (errors) {
      const isInternalError = 500 === api.http.getLastHttpStatus();
      const message = isInternalError
        ? errors
        : 'There are errors on the form.';
      setModal({
        isError: true,
        message: message,
        onClose: closeModal,
      });
      setErrors(errors);
    }
  };

  const getMainArea = () => {
    if (isLoading) {
      return <FormSkeleton />;
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form id="form-question-group">
            {/* Tab Content. */}
            <div className="flex pb-20 flex-col max-w-xl">
              {/* Section */}
              <h2 className="md:pt-10 text-2xl">Group Information</h2>

              <Input
                autoFocus
                innerRef={inputAutoFocusRef}
                label="Label"
                name="label"
                placeholder="ex. General"
                required
              />
            </div>
            {/* /Tab Content.  */}

            <ActionButtons
              formId="form-question-group"
              isSubmitting={isSubmitting}
              labelSubmit={isEditing ? 'Save Changes' : 'Create Group'}
              onClickCancel={goBack}
            />
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <AnimatedScreen>
      <div className="md:my-8 md:pb-12">
        {/* Error and Success Modal. */}
        <ModalInfo
          isError={modal?.isError}
          isOpened={!!modal}
          onClose={modal?.onClose}
          title={modal?.message}
        />

        {/* Card. */}
        <div className="flex flex-col px-5 md:px-14 pt-8 pb-16 md:rounded-lg bg-white shadow-custom">
          {/* Back button. */}
          <Link className="hidden md:flex flex-row items-center" to={urlBack}>
            <img alt="Back button" className="h-5" src={ChrevronLeft} />
            <h1 className="pl-6 text-2xl font-black uppercase">{pageTitle}</h1>
          </Link>

          {getMainArea()}
        </div>
        {/* /Card. */}
      </div>
    </AnimatedScreen>
  );
}

export default memo(QuestionGroupFormScreen);
