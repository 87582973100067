import { memo, useContext, useEffect, useState } from 'react';

import Header from '../../../../shared-components/Header';
import TitleBar from '../../../../shared-components/Header/TitleBar';
import ModalInfo from '../../../../shared-components/ModalInfo';
import useEffectOnce from '../../../../utils/useEffectOnce';
import { ApiContext } from '../../../../contexts/api';
import AnimatedScreen from '../../../../shared-components/AnimatedScreen';
import CardsArea from './CardsArea';
import { GlobalContext } from '../../../../contexts/global';

function NotificationListScreen() {
  const [alertDaysPages, setAlertDaysPages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [modal, setModal] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const api = useContext(ApiContext);
  const globalContext = useContext(GlobalContext);

  const pageTitle = 'Notifications';

  const fetchAlertDays = async (page_i = 1) => {
    const apiResult = await api.http.get(`/admin/alerts?page=${page_i}`);
    return apiResult?.data ?? [];
  };

  const closeModal = () => {
    setModal(null);
  };

  useEffect(() => {
    const setGlobalToolbar = () => {
      globalContext.setGlobalTitle(pageTitle);
      globalContext.setUrlBack(null);
      globalContext.setEnabledSearch(false);
    };
    setGlobalToolbar();
  }, [globalContext, pageTitle]);

  const onScreenLoad = async () => {
    try {
      await loadMore();
      setIsLoading(false);
    } catch (message) {
      setModal({
        isError: true,
        message,
        onClose: closeModal,
      });
    }
  };

  useEffectOnce(() => {
    onScreenLoad();

    return () => api.http.cancelAll();
  });

  const insertElementAt = (ary, index, element) => {
    const clonedAry = [...ary];
    const clonedElement = [...element];
    clonedAry[index] = clonedElement;
    return clonedAry;
  };

  const loadMore = async () => {
    try {
      setIsLoadingMore(true);

      const page = await fetchAlertDays(currentPage + 1);
      const newAlertDaysPages = insertElementAt(
        alertDaysPages,
        currentPage,
        page
      );
      setAlertDaysPages(newAlertDaysPages);

      setCurrentPage(currentPage + 1);
      setIsLoading(false);
      setIsLoadingMore(false);
    } catch (message) {
      setModal({
        isError: true,
        message,
        onClose: closeModal,
      });
    }
  };

  const headerTitle = (
    <TitleBar enabledFilter={false} enabledSearch={false} title={pageTitle} />
  );

  return (
    <AnimatedScreen>
      <div className="px-3 md:px-0 md:pb-12">
        <div className="hidden md:flex">
          <Header leftSide={headerTitle} />
        </div>

        {/* Error and Success Modal. */}
        <ModalInfo
          isError={modal?.isError}
          isOpened={!!modal}
          onClose={modal?.onClose}
          title={modal?.message}
        />

        {/* Main Area. */}
        <div className="my-8 pb-12 flex flex-col">
          <CardsArea alertDaysPages={alertDaysPages} isLoading={isLoading} />

          {/* Load More. */}
          <div className="flex items-center justify-center pt-10">
            <button
              className="py-3 px-5 rounded font-bold text-gray-400 border-2 border-gray-400 hover:bg-gray-200"
              disabled={isLoadingMore}
              onClick={() => loadMore()}
              type="button"
            >
              {isLoadingMore ? 'Loading ...' : 'Load More Notifications'}
            </button>
          </div>
        </div>
      </div>
    </AnimatedScreen>
  );
}

export default memo(NotificationListScreen);
