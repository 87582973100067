import { memo, useCallback, useContext, useEffect, useState } from 'react';

import Header from '../../../../shared-components/Header';
import ActionBar from '../../../../shared-components/Header/ActionBar';
import PrimaryButton from '../../../../shared-components/Header/ActionBar/PrimaryButton';
import TitleBar from '../../../../shared-components/Header/TitleBar';
import CardsArea from './CardsArea';
import ModalInfo from '../../../../shared-components/ModalInfo';
import useEffectOnce from '../../../../utils/useEffectOnce';
import { ApiContext } from '../../../../contexts/api';
import AnimatedScreen from '../../../../shared-components/AnimatedScreen';
import { GlobalContext } from '../../../../contexts/global';

function AgencyListScreen() {
  const [agencies, setAgencies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalError, setModalError] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [filtered, setFiltered] = useState([]);
  const api = useContext(ApiContext);
  const globalContext = useContext(GlobalContext);

  const pageTitle = 'Agency List';

  const fetchAgencies = async () => {
    const apiResult = await api.http.get('/admin/agencies');
    setAgencies(apiResult);
  };

  useEffect(() => {
    const setGlobalToolbar = () => {
      globalContext.setGlobalTitle(pageTitle);
      globalContext.setUrlBack(null);
      globalContext.setEnabledSearch(true);
      globalContext.setSearchValue('');
    };
    setGlobalToolbar();
  }, [globalContext, pageTitle]);

  useEffect(() => {
    setSearchValue(globalContext.searchValue);
  }, [globalContext.searchValue]);

  const onScreenLoad = async () => {
    try {
      await fetchAgencies();
      setIsLoading(false);
    } catch (message) {
      setModalError(message);
    }
  };

  useEffectOnce(() => {
    onScreenLoad();

    return () => api.http.cancelAll();
  });

  const onChangeSearch = useCallback(() => {
    const filtered = agencies.filter((agency) => {
      const lowerSearchValue = searchValue.toLowerCase();

      const isOnName = agency.name.toLowerCase().includes(lowerSearchValue);
      if (isOnName) {
        return true;
      }

      return false;
    });
    setFiltered(filtered);
  }, [agencies, searchValue]);

  useEffect(() => {
    onChangeSearch();
  }, [onChangeSearch, searchValue, agencies]);

  const headerRightSide = (
    <ActionBar>
      <PrimaryButton title="Create New Agency" url="/admin/agencies/new" />
    </ActionBar>
  );

  const headerTitle = (
    <TitleBar
      enabledFilter={false}
      enabledSearch
      onSearchChange={setSearchValue}
      searchPlaceholder="Search by agency name"
      title={pageTitle}
    />
  );

  return (
    <AnimatedScreen>
      <div className="px-3 md:px-0 md:pb-12">
        <Header leftSide={headerTitle} rightSide={headerRightSide} />

        {/* Error Modal. */}
        <ModalInfo
          isError
          isOpened={!!modalError}
          onClose={() => setModalError(null)}
          title={modalError}
        />

        {/* Main Area. */}
        <div className="my-8 pb-12">
          <CardsArea agencies={filtered} isLoading={isLoading} />
        </div>
      </div>
    </AnimatedScreen>
  );
}

export default memo(AgencyListScreen);
