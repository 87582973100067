import { memo } from 'react';
import CardNotification from '../CardNotification';
import CardNotificationSkeleton from '../CardNotificationSkeleton';

function CardsArea(props) {
  const { alertDaysPages, isLoading } = props;

  const getCardsSkeleton = () => {
    return (
      <>
        <CardNotificationSkeleton />
        <CardNotificationSkeleton />
        <CardNotificationSkeleton />
        <CardNotificationSkeleton />
        <CardNotificationSkeleton />
        <CardNotificationSkeleton />
      </>
    );
  };

  const getCardsList = () => {
    const pages = alertDaysPages.map((alertDays) => {
      const cardsList = alertDays.map((item) => (
        <CardNotification alertDay={item} key={`notification_${item.id}`} />
      ));
      return cardsList;
    });

    return pages;
  };

  const getNoNotifications = () => {
    return (
      <p className="flex h-60 items-center justify-center text-gray-500 text-2xl">
        You have no notifications!
      </p>
    );
  };

  const hasNoNotifications =
    0 === alertDaysPages.length || 0 === alertDaysPages[0].length;
  const cardsList = isLoading
    ? getCardsSkeleton()
    : hasNoNotifications
    ? getNoNotifications()
    : getCardsList();
  return (
    <div className="flex flex-col max-w-5xl w-full gap-4 mx-auto">
      {cardsList}
    </div>
  );
}

export default memo(CardsArea);
