import { memo } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
} from 'chart.js';

function ChartBar(props) {
  const { data, options: propOptions } = props;

  const options = {
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
        },
      },
    },
    animation: {
      duration: 0,
    },
  };

  ChartJS.register(LinearScale, CategoryScale, BarElement);

  return (
    <Bar data={data} options={{ ...options, ...propOptions }} redraw={false} />
  );
}

export default memo(ChartBar);
