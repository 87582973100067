import { memo, useMemo } from 'react';
import CardPatient from '../CardPatient';
import CardPatientSkeleton from '../CardPatientSkeleton';

function CardsArea(props) {
  const { patients, isLoading, isAdmin } = props;

  const cardsList = useMemo(() => {
    return patients.map((patient) => {
      const url = isAdmin
        ? `/admin/agencies/${patient.agency.id}/patients/${patient.id}/details`
        : `/admin/patients/${patient.id}/details`;
      return (
        <CardPatient
          key={'patient_' + patient.id}
          patient={patient}
          url={url}
        />
      );
    });
  }, [isAdmin, patients]);

  if (isLoading) {
    return (
      <div className="grid min-h-screen place-content-start gap-4 2xl:grid-cols-3 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 grid-cols-1 justify-center">
        <CardPatientSkeleton />
        <CardPatientSkeleton />
        <CardPatientSkeleton />
      </div>
    );
  }

  const hasNoPatients = 0 === patients.length;
  if (hasNoPatients) {
    return (
      <h2 className="text-center my-auto text-xl mt-8">
        This agency has no patients.
      </h2>
    );
  }

  return (
    <div className="grid min-h-screen place-content-start gap-4 2xl:grid-cols-3 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 grid-cols-1 justify-center">
      {cardsList}
    </div>
  );
}

export default memo(CardsArea);
