import { memo } from 'react';
import { Link } from 'react-router-dom';

import AgencyLogo from '../../../../../shared-components/AgencyLogo';
import IconMore from './../../../../../assets/images/icon-more-horizontal.svg';

function CardAgency(props) {
  const { agency } = props;

  return (
    <Link
      className="flex flex-col h-48 rounded-lg shadow-custom overflow-hidden bg-white"
      to={`/admin/agencies/${agency.id}/details`}
    >
      {/* Header. */}
      <div className="flex flex-row h-12 px-6 border-b border-gray-100 items-center">
        <p>{agency.name}</p>
        <img alt="More icon" className="w-7 ml-auto" src={IconMore} />
      </div>
      {/* /Header. */}

      {/* Three Squares. */}
      <div className="flex flex-row place-content-between px-7 py-6 space-x-5">
        {/* Logo. */}
        <div className="flex h-24 w-24 bg-white p-1 items-center justify-center rounded border border-gray-300">
          <AgencyLogo agency={agency} />
        </div>

        {/* Patients #. */}
        <div className="flex flex-col h-24 w-24 bg-white p-1 items-center justify-center rounded border border-gray-300">
          <p className="text-gray-600">Patients</p>
          <p className="text-3xl font-extrabold">{agency.patients_count}</p>
        </div>

        {/* Agents #. */}
        <div className="flex flex-col h-24 w-24 bg-white p-1 items-center justify-center rounded border border-gray-300">
          <p className="text-gray-600">Agents</p>
          <p className="text-3xl font-extrabold">{agency.agents_count}</p>
        </div>
      </div>
      {/* /Three Squares. */}
    </Link>
  );
}

export default memo(CardAgency);
