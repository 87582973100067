import { memo } from 'react';

function AgencyLogo(props) {
  const { agency, textNotAvailable: textNotAvailableDefault } = props;

  const isLoading = !agency;
  if (isLoading) {
    return (
      <span className="animate-pulse flex h-full w-full text-2xl font-bold justify-center items-center bg-gray-100" />
    );
  }

  const hasLogo = !!agency.avatar_url;
  if (hasLogo) {
    return (
      <img
        alt="Agency Logo"
        className="flex h-full w-full rounded"
        src={agency.avatar_url}
      />
    );
  }

  const textNotAvailable = textNotAvailableDefault ?? 'Logo N/A';
  return (
    <span className="flex h-full w-full text-lg font-bold justify-center items-center text-center">
      {textNotAvailable}
    </span>
  );
}

export default memo(AgencyLogo);
