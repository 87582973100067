import { memo } from 'react';

const InputSkeleton = memo((props) => {
  const { inputIndex } = props;

  const widthClasses = [
    'w-2/3',
    'w-1/2',
    'w-4/5',
    'w-1/3',
    'w-full',
    'w-3/4',
    'w-1/4',
    'w-2/4',
    'w-1/5',
    'w-3/5',
  ];
  const width = widthClasses[(inputIndex ?? 0) % widthClasses.length];
  return (
    <div className="h-12 mt-8">
      <div className={`mt-3 h-2 bg-gray-100 rounded ${width}`} />
      <div className="mt-3 h-12 bg-gray-100 rounded" />
    </div>
  );
});

export default memo(InputSkeleton);
