import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import Header from '../../../../shared-components/Header';
import ActionBar from '../../../../shared-components/Header/ActionBar';
import PrimaryButton from '../../../../shared-components/Header/ActionBar/PrimaryButton';
import TitleBar from '../../../../shared-components/Header/TitleBar';
import CardAgencyAdmin from './CardAgencyAdmin';
import ModalInfo from '../../../../shared-components/ModalInfo';
import BackBar from '../../../../shared-components/Header/BackBar';
import AgencyLogo from '../../../../shared-components/AgencyLogo';
import useEffectOnce from '../../../../utils/useEffectOnce';
import { ApiContext } from '../../../../contexts/api';
import CardAgencyAdminSkeleton from './CardAgencyAdminSkeleton';
import AnimatedScreen from '../../../../shared-components/AnimatedScreen';
import { GlobalContext } from '../../../../contexts/global';

function AgencyAdminListScreen() {
  const params = useParams();
  const [agencyAdmins, setAgencyAdmins] = useState([]);
  const [agency, setAgency] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modalError, setModalError] = useState(null);
  const api = useContext(ApiContext);
  const globalContext = useContext(GlobalContext);

  const agencyId = params.agencyId ?? api?.user?.agency?.id;
  const role = useMemo(() => api?.user.role, [api]);
  const isAdmin = useMemo(
    () => 'super-admin' === role || 'admin' === role,
    [role]
  );

  const pageTitle = 'Agency Admins';

  const fetchAgency = async (agencyId) => {
    const apiResult = await api.http.get(`/admin/agencies/${agencyId}`);
    setAgency(apiResult);
  };

  const fetchAgencyAdmins = async (agencyId) => {
    const apiResult = await api.http.get(
      `/admin/agencies/${agencyId}/agency-admins`
    );
    setAgencyAdmins(apiResult);
  };

  const urlBack = useMemo(() => {
    if (isAdmin) {
      return `/admin/agencies/${agencyId}/details`;
    }
    return '/';
  }, [agencyId, isAdmin]);

  useEffect(() => {
    const setGlobalToolbar = () => {
      globalContext.setGlobalTitle(pageTitle);
      globalContext.setUrlBack(urlBack);
      globalContext.setEnabledSearch(false);
    };
    setGlobalToolbar();
  }, [globalContext, pageTitle, urlBack]);

  const onScreenLoad = async () => {
    try {
      await fetchAgency(agencyId);
      await fetchAgencyAdmins(agencyId);
      setIsLoading(false);
    } catch (message) {
      setModalError(message);
    }
  };

  const scrollToTop = () => {
    document.getElementById('main-area').scrollTop = 0;
  };

  useEffectOnce(() => {
    scrollToTop();
    onScreenLoad();

    return () => api.http.cancelAll();
  });

  const cardsList = useMemo(() => {
    const items = agencyAdmins.map((agencyAdmin) => {
      const url = isAdmin
        ? `/admin/agencies/${agencyAdmin.agency.id}/agency-admins/${agencyAdmin.id}/edit`
        : `/admin/agency-admins/${agencyAdmin.id}/edit`;

      return (
        <CardAgencyAdmin
          agencyAdmin={agencyAdmin}
          key={'agencyadmin_' + agencyAdmin.id}
          url={url}
        />
      );
    });
    return items;
  }, [agencyAdmins, isAdmin]);

  const main = useMemo(() => {
    const cardsSkeleton = (
      <>
        <CardAgencyAdminSkeleton />
        <CardAgencyAdminSkeleton />
        <CardAgencyAdminSkeleton />
      </>
    );

    return (
      <div
        className="grid min-h-screen place-content-start gap-4 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 justify-center"
        style={{ 'justify-content': 'stretch' }}
      >
        {isLoading ? cardsSkeleton : cardsList}
      </div>
    );
  }, [cardsList, isLoading]);

  const agencyLogo = <AgencyLogo agency={agency} textNotAvailable="N/A" />;

  const headerLeftSide = (
    <div className="hidden md:flex">
      <BackBar logo={agencyLogo} title={agency?.name} url={urlBack} />
    </div>
  );

  const urlAddAdmin = isAdmin
    ? `/admin/agencies/${agencyId}/agency-admins/new`
    : '/admin/agency-admins/new';

  const headerRightSide = (
    <ActionBar>
      <PrimaryButton title="Add Admin" url={urlAddAdmin} />
    </ActionBar>
  );

  const headerTitle = <TitleBar title={pageTitle} />;

  return (
    <AnimatedScreen>
      <div className="px-3 md:px-0 md:pb-12">
        <Header
          leftSide={headerLeftSide}
          rightSide={headerRightSide}
          title={headerTitle}
        />

        {/* Error Modal. */}
        <ModalInfo
          isError
          isOpened={!!modalError}
          onClose={() => setModalError(null)}
          title={modalError}
        />

        {/* Main Area. */}
        <div className="my-8 pb-12">{main}</div>
      </div>
    </AnimatedScreen>
  );
}

export default memo(AgencyAdminListScreen);
