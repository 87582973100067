import clsx from 'clsx';
import { memo } from 'react';
import { useContactForm } from '../useContactForm';

function ContactFormPills() {
  const { contacts, activeContactTabIndex, setActiveContactTabIndex } =
    useContactForm();

  if (!contacts || contacts.length === 0) {
    return null;
  }

  return contacts.map((contact, index) => {
    const isActive = activeContactTabIndex === index;

    const key =
      contact.id !== ''
        ? `tab-old-contact-${contact.id}`
        : `tab-new-contact-${contact.newContactKey}`;

    const title = `Contact #${index + 1}`;

    return (
      <div
        className={clsx(
          'py-2 px-4 lg:px-6 flex items-center rounded cursor-pointer',
          {
            'bg-blue-500': isActive,
            'bg-blue-100': !isActive,
          }
        )}
        key={key}
        onClick={() => setActiveContactTabIndex(index)}
      >
        <span
          className={clsx('text-sm font-semibold', {
            'text-gray-50': isActive,
            'text-gray-900': !isActive,
          })}
        >
          {title}
        </span>
      </div>
    );
  });
}

export default memo(ContactFormPills);
