import { memo } from 'react';
import { Link } from 'react-router-dom';

function PrimaryButton(props) {
  const { url, title } = props;
  return (
    <Link
      className="flex flex-auto md:flex-initial items-center justify-center h-12 px-4 lg:px-6 mx-2 bg-primary text-white rounded font-medium hover:bg-opacity-70"
      to={url ?? '/'}
    >
      {title}
    </Link>
  );
}

export default memo(PrimaryButton);
