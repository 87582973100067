import { memo, useContext, useMemo, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';

import ModalInfo from '../../../shared-components/ModalInfo';
import Input from '../../../shared-components/Form/Input';
import Logo from '../../../assets/images/logo-voice-health-with-smart-companion.svg';
import { ApiContext } from '../../../contexts/api';
import SubmitButton from './SubmitButton';

function SignIn() {
  const [isSending, setIsSending] = useState(false);
  const [modal, setModal] = useState(null);
  const navigate = useNavigate();
  const inputAutoFocusRef = useRef();
  const api = useContext(ApiContext);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      email: yup.string().trim().email().required('You must provide an email'),
      password: yup.string().min(4).required('You must provide a password'),
    });
  }, []);

  const initialValues = {
    email: '',
    password: '',
  };

  const validateValues = (values) => {
    return validationSchema.cast(values);
  };

  const submitForm = async (values) => {
    try {
      setIsSending(true);
      const validValues = validateValues(values);
      await api.auth.signIn(validValues);
      navigate('/admin');
    } catch (message) {
      setModal({
        isError: true,
        message,
        onClose: closeModal,
      });
      setIsSending(false);
    }
  };

  const closeModal = () => {
    setModal(null);
    inputAutoFocusRef.current.focus();
  };

  return (
    <div className="flex flex-col flex-grow md:max-w-lg bg-white md:shadow md:rounded-md px-11 pt-12 pb-14 md:mb-28">
      {/* Error and Success Modal. */}
      <ModalInfo
        isError={modal?.isError}
        isOpened={!!modal}
        onClose={modal?.onClose}
        title={modal?.message}
      />

      {/* Logo. */}
      <div className="flex flex-grow justify-center">
        <img alt="Logo" src={Logo} />
      </div>

      {/* Form. */}
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validationSchema={validationSchema}
      >
        <Form id="form-sign-in">
          {/* Email Input. */}
          <Input
            autoFocus
            innerRef={inputAutoFocusRef}
            label="Email address"
            name="email"
            placeholder="Email address"
            required
            type="email"
          />

          {/* Password Input. */}
          <Input
            label="Password"
            name="password"
            placeholder="Enter password"
            required
            type="password"
          />
        </Form>
      </Formik>

      {/* Submit button. */}
      <div className="flex flex-grow justify-center mt-16">
        <SubmitButton isSending={isSending} />
      </div>

      {/* Forgot password? */}
      <div className="flex flex-grow justify-center mt-10">
        <Link className="text-gray-400" to="#">
          Forgot Password?
        </Link>
      </div>
    </div>
  );
}

export default memo(SignIn);
