import { memo } from 'react';

function ProgressBar(props) {
  const { percentageValue } = props;
  const color = 'purple';

  return (
    <div className="relative pt-1">
      <div className="flex mb-2 items-center justify-between">
        <div className="text-right">
          <span
            className={`text-xs font-semibold inline-block text-${color}-600`}
          >
            {`${percentageValue}%`}
          </span>
        </div>
      </div>
      <div
        className={`overflow-hidden h-2 mb-4 text-xs flex rounded bg-${color}-200`}
      >
        <div
          className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-${color}-500`}
          style={{ width: percentageValue + '%' }}
        />
      </div>
    </div>
  );
}

export default memo(ProgressBar);
