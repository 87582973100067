const userNormalization = {
  fromAPI: (user) => ({
    ...user,
    is_alert_enabled: getIsAlertEnabled(user),
    alert_phone: getAlertPhone(user),
  }),

  toAPI: (values) => {
    normalizeAlertPhone(values);
    normalizeIsAlertEnabled(values);
  },
};

const agentNormalization = {
  fromAPI: (agent) => ({
    ...agent,
    user: {
      ...agent.user,
      is_alert_enabled: getIsAlertEnabled(agent.user),
      alert_phone: getAlertPhone(agent.user),
    },
  }),

  toAPI: (values) => {
    normalizeAlertPhone(values);
    normalizeIsAlertEnabled(values);
  },
};

const agencyAdminNormalization = {
  fromAPI: (agencyAdmin) => ({
    ...agencyAdmin,
    user: {
      ...agencyAdmin.user,
      is_alert_enabled: getIsAlertEnabled(agencyAdmin.user),
      alert_phone: getAlertPhone(agencyAdmin.user),
    },
  }),

  toAPI: (values) => {
    normalizeAlertPhone(values);
    normalizeIsAlertEnabled(values);
  },
};

const getIsAlertEnabled = (user) => {
  const is_uninitialized = !user;
  if (is_uninitialized) {
    return '';
  }

  const is_email = user.is_alert_email_enabled;
  const is_sms = user.is_alert_sms_enabled;

  const is_both = is_email && is_sms;
  if (is_both) {
    return 'email-and-sms';
  }

  const is_email_only = is_email;
  if (is_email_only) {
    return 'only-email';
  }

  const is_sms_only = is_sms;
  if (is_sms_only) {
    return 'only-sms';
  }

  return 'no';
};

const getAlertPhone = (user) => {
  const is_uninitialized = !user?.alert_phone;
  if (is_uninitialized) {
    return '';
  }

  const is_american_phone = '+1' === user.alert_phone.substr(0, 2);
  if (is_american_phone) {
    const phone_without_us_code = user.alert_phone.substr(2);
    return phone_without_us_code;
  }

  return user.alert_phone;
};

const normalizeAlertPhone = (values) => {
  if (!values.alert_phone) {
    return '';
  }

  const hasInternationalCode = '+' === values.alert_phone.charAt(0);
  values.alert_phone = values.alert_phone.replace(/[^0-9]/g, '');

  values.alert_phone = hasInternationalCode
    ? `+${values.alert_phone}`
    : `+1${values.alert_phone}`;
};

const normalizeIsAlertEnabled = (values) => {
  switch (values.is_alert_enabled) {
    case 'no':
      values.is_alert_email_enabled = false;
      values.is_alert_sms_enabled = false;
      break;

    case 'email-and-sms':
      values.is_alert_email_enabled = true;
      values.is_alert_sms_enabled = true;
      break;

    case 'only-email':
      values.is_alert_email_enabled = true;
      values.is_alert_sms_enabled = false;
      break;

    case 'only-sms':
      values.is_alert_email_enabled = false;
      values.is_alert_sms_enabled = true;
      break;

    default:
      break;
  }
};

function getBoolean(value) {
  if (value === undefined) {
    return false;
  }

  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
      return true;
    default:
      return false;
  }
}

export {
  userNormalization,
  agentNormalization,
  agencyAdminNormalization,
  getBoolean,
};
