import { memo } from 'react';
import { clsx } from 'clsx';
import { FieldArray } from 'formik';
import ContactFormItem from '../ContactFormItem';
import { useContactForm } from '../useContactForm';

function ContactFormList() {
  const { contacts, activeContactTabIndex } = useContactForm();

  return (
    <FieldArray
      name="contacts"
      render={() =>
        contacts.map((contact, index) => {
          const key =
            contact.id !== ''
              ? `old-contact-${contact.id}`
              : `new-contact-${contact.newContactKey}`;

          return (
            <ContactFormItem
              className={clsx({ hidden: activeContactTabIndex !== index })}
              contact={contact}
              index={index}
              key={key}
            />
          );
        })
      }
    />
  );
}

export default memo(ContactFormList);
