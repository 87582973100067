import { memo } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Navigate, Route, Routes, useLocation } from 'react-router';

import AgencyDetailsScreen from '../../../../screens/admin/agencies/Details';
import AgencyFormScreen from '../../../../screens/admin/agencies/Form';
import PatientDetailsScreen from '../../../../screens/admin/patients/Details';
import AgentFormScreen from '../../../../screens/admin/agents/Form';
import AgentListScreen from '../../../../screens/admin/agents/List';
import AgencyAdminFormScreen from '../../../../screens/admin/agency-admins/Form';
import AgencyAdminListScreen from '../../../../screens/admin/agency-admins/List';
import PatientFormScreen from '../../../../screens/admin/patients/Form';
import SettingsFormScreen from '../../../../screens/admin/settings/Form';
import NotificationListScreen from '../../../../screens/admin/notifications/List';
import Page404Screen from '../../../../screens/misc/Page404';

function UserRoutesAgency(props) {
  const { user } = props;
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes key={location.pathname} location={location}>
        {/* Default: redirect. */}
        <Route element={<Navigate to="/admin/agency/details" />} path="/" />

        {/* Main Menu: Agency Details. */}
        <Route
          element={<AgencyDetailsScreen agencyId={user?.agency.id} />}
          path="/agency/details"
        />

        {/* Main Menu: Agency Form - Edit. */}
        <Route element={<AgencyFormScreen />} path="/agency/edit" />

        {/* Main Menu: Patient Details. */}
        <Route
          element={<PatientDetailsScreen />}
          path="/patients/:patientId/details"
        />

        {/* Main Menu: Patient Form - New. */}
        <Route element={<PatientFormScreen />} path="/patients/new" />

        {/* Main Menu: Patient Form - Edit. */}
        <Route
          element={<PatientFormScreen />}
          path="/patients/:patientId/edit"
        />

        {/* Main Menu: Agent List. */}
        <Route element={<AgentListScreen />} path="/agents" />

        {/* Main Menu: Agent Form - New. */}
        <Route element={<AgentFormScreen />} path="/agents/new" />

        {/* Main Menu: Agent Form - Edit. */}
        <Route element={<AgentFormScreen />} path="/agents/:agentId/edit" />

        {/* Main Menu: Agency Admin List. */}
        <Route element={<AgencyAdminListScreen />} path="/agency-admins" />

        {/* Main Menu: Agency Admin Form - New. */}
        <Route element={<AgencyAdminFormScreen />} path="/agency-admins/new" />

        {/* Main Menu: Agency Admin Form - Edit. */}
        <Route
          element={<AgencyAdminFormScreen />}
          path="/agency-admins/:agencyAdminId/edit"
        />

        {/* Notifications Menu: List. */}
        <Route element={<NotificationListScreen />} path="/notifications" />

        {/* Main Menu: Settings. */}
        <Route element={<SettingsFormScreen />} path="/settings" />

        {/* 404 */}
        <Route element={<Page404Screen />} />
      </Routes>
    </AnimatePresence>
  );
}

export default memo(UserRoutesAgency);
