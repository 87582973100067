import { memo } from 'react';
import IconGender from '../../../../../shared-components/IconGender';

function TableCellGender(props) {
  const { gender } = props;

  return (
    <div className="flex">
      <IconGender className="h-5 pl-2" gender={gender} />
      <span className="pl-3">{gender}</span>
    </div>
  );
}

export default memo(TableCellGender);
