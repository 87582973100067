import { memo } from 'react';

function CardQuestionSkeleton(props) {
  const { cardIndex } = props;

  const daysHtml = [0, 1, 2].map((i) => {
    return (
      <li key={`skeleton_${cardIndex ?? 0}_day_answer_${i}`}>
        <div className="bg-gray-100 h-12 w-12 flex rounded-full" />
        <div className="h-2 mt-4 bg-gray-100 rounded w-full" />
      </li>
    );
  });

  return (
    <div className="py-6 px-8 flex flex-col rounded-lg shadow-custom bg-white">
      {/* Title. */}
      <div className="h-2 bg-gray-100 rounded w-2/3" />

      {/* Three-day. */}
      <ul className="pt-6 flex flex-row place-content-between">
        {/* Days. */}
        {daysHtml}
      </ul>
    </div>
  );
}

export default memo(CardQuestionSkeleton);
