import { memo } from 'react';

function CardAgencySkeleton() {
  return (
    <div className="animate-pulse flex flex-col h-36 rounded-lg shadow-custom overflow-hidden bg-white">
      {/* Three Squares. */}
      <div className="flex flex-row px-7 py-6 space-x-4">
        {/* Avatar. */}
        <div className="flex h-24 w-24 p-1 items-center justify-center rounded border border-gray-100">
          <div className="h-full w-full bg-gray-100 rounded w-1/3" />
        </div>

        {/* Text. */}
        <div className="gap-4 flex flex-col w-3/4">
          <div className="mt-2 h-2 bg-gray-100 rounded w-4/5" />
          <div className="h-2 bg-gray-100 rounded w-1/3" />
        </div>
      </div>
      {/* /Three Squares. */}
    </div>
  );
}

export default memo(CardAgencySkeleton);
