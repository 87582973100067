import React, { memo } from 'react';

function Tabs(props) {
  const { children, classActive, activeTabIndex, onSelect } = props;

  const tabList = React.Children.map(children, (component, index) => {
    const label = component.props.label;
    const isActive = index === activeTabIndex;
    const mayClassActive = isActive ? classActive : '';

    return (
      <li
        className={`py-8 flex-1 cursor-pointer border-b-3 ${mayClassActive}`}
        onClick={() => onSelect(index)}
      >
        {label}
      </li>
    );
  });

  const contentsList = React.Children.map(children, (component, index) => {
    const isActive = index === activeTabIndex;
    const mayClassHidden = isActive ? '' : 'hidden';
    return <div className={mayClassHidden}>{component}</div>;
  });

  return (
    <>
      <ul className="space-x-4 md:pt-8 flex flex-col md:flex-row text-xl text-center text-gray-300 select-none">
        {tabList}
      </ul>
      <div>{contentsList}</div>
    </>
  );
}

export default memo(Tabs);
