import { memo } from 'react';
import useRemoveContact from './useRemoveContact';

function ButtonRemoveContact({ index, ...rest }) {
  const { removeContact } = useRemoveContact();

  return (
    <button onClick={() => removeContact(index)} type="button" {...rest}>
      Delete
    </button>
  );
}

export default memo(ButtonRemoveContact);
