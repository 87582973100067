import { memo } from 'react';
import QuestionCard from '../QuestionCard';

function QuestionGroupItem({ group }) {
  const questionCards = group.questions.map((question) => (
    <QuestionCard key={`question_${question.id}`} question={question} />
  ));

  return (
    <div className="questionGroup mt-4 text-lg font-bold">
      <h3 className="questionGroup__title">{group.label}</h3>
      <div className="questionGroup__questionList">{questionCards}</div>
    </div>
  );
}

export default memo(QuestionGroupItem);
