import { memo } from 'react';

function TableCellRole(props) {
  const { title } = props;

  const getRoleClasses = (role) => {
    const isSuperAdmin = 'super-admin' === role;
    if (isSuperAdmin) {
      return 'bg-gray-600 text-white';
    }

    const isAgent = 'agent' === role;
    if (isAgent) {
      return 'bg-green-400 text-white';
    }

    return 'bg-blue-400 text-white';
  };
  const roleClasses = getRoleClasses(title);

  return <span className={`py-1 px-3 rounded ${roleClasses}`}>{title}</span>;
}

export default memo(TableCellRole);
