import { memo } from 'react';

import IconMore from './../../../../../assets/images/icon-more-horizontal.svg';

function CardAgencySkeleton() {
  return (
    <div className="animate-pulse flex flex-col h-48 rounded-lg shadow-custom overflow-hidden bg-white">
      {/* Header. */}
      <div className="flex flex-row h-12 px-6 border-b border-gray-100 items-center">
        <div className="h-2 bg-gray-100 rounded w-3/4" />
        <img alt="More icon" className="w-7 ml-auto" src={IconMore} />
      </div>
      {/* /Header. */}

      {/* Three Squares. */}
      <div className="flex flex-row place-content-between px-7 py-6 space-x-5">
        {/* Logo. */}
        <div className="flex h-24 w-24 bg-white p-1 items-center justify-center rounded border border-gray-300">
          <div className="h-full bg-gray-100 rounded w-full" />
        </div>

        {/* Patients #. */}
        <div className="flex flex-col h-24 w-24 bg-white p-1 items-center justify-center rounded border border-gray-300">
          <div className="h-2 bg-gray-100 rounded w-1/2" />
          <div className="h-2 mt-4 bg-gray-100 rounded w-1/4" />
        </div>

        {/* Agents #. */}
        <div className="flex flex-col h-24 w-24 bg-white p-1 items-center justify-center rounded border border-gray-300">
          <div className="h-2 bg-gray-100 rounded w-1/2" />
          <div className="h-2 mt-4 bg-gray-100 rounded w-1/4" />
        </div>
      </div>
      {/* /Three Squares. */}
    </div>
  );
}

export default memo(CardAgencySkeleton);
