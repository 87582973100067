import { memo } from 'react';

import IconMore from './../../../../../assets/images/icon-more.svg';

function CardPatientSkeleton() {
  return (
    <div className="animate-pulse flex flex-col rounded-lg overflow-hidden shadow-custom">
      {/* Header. */}
      <div className="flex flex-row h-32 p-7 items-center bg-white">
        {/* Image. */}
        <div className="h-20 w-20 bg-gray-100 rounded-full" />

        {/* Patient's info. */}
        <div className="flex flex-col pl-6 w-3/4 gap-4">
          <div className="h-2 bg-gray-100 rounded w-3/4" />
          <div className="h-2 bg-gray-100 rounded w-1/3" />
        </div>

        {/* More. */}
        <img alt="See more" className="h-7 ml-auto" src={IconMore} />
      </div>
      {/* /Header. */}

      {/* Daily Activity. */}
      <div className="flex gap-8 flex-col h-96 mt-0.5 px-6 md:px-10 py-8 overflow-auto text-xl 3xl:text-2xl text-gray-400 bg-white">
        <div className="h-2 bg-gray-100 rounded w-3/4" />
        <div className="h-2 bg-gray-100 rounded w-2/3" />
        <div className="h-2 bg-gray-100 rounded w-4/5" />
        <div className="h-2 bg-gray-100 rounded w-1/2" />
        <div className="h-2 bg-gray-100 rounded w-3/4" />
        <div className="h-2 bg-gray-100 rounded w-4/5" />
        <div className="h-2 bg-gray-100 rounded w-2/3" />
        <div className="h-2 bg-gray-100 rounded w-3/4" />
      </div>

      {/* Footer. */}
      <div className="flex h-24 items-center justify-center text-xl text-primary bg-white mt-0.5">
        <div className="h-2 bg-gray-100 rounded w-1/2" />
      </div>
    </div>
  );
}

export default memo(CardPatientSkeleton);
