import { memo, useCallback, useLayoutEffect, useRef } from 'react';
import IconCheck from './../../assets/images/icon-check.svg';
import IconClose from './../../assets/images/icon-close-big.svg';

function ModalInfo(props) {
  const { isOpened, isError, title, description, onClose } = props;
  const okayButtonRef = useRef();

  const focusOkayButton = useCallback(() => {
    const isHiddenOrUnitialized = !isOpened || !okayButtonRef.current;
    if (isHiddenOrUnitialized) {
      return;
    }
    okayButtonRef.current.focus();
  }, [isOpened]);

  useLayoutEffect(() => {
    focusOkayButton();
  }, [isOpened, focusOkayButton]);

  const onKeyDown = (event) => {
    const hasPressedEscape = 'escape' === event.key.toLowerCase();
    if (hasPressedEscape) {
      onClose();
    }
  };

  if (!isOpened) {
    return null;
  }

  const icon = isError ? IconClose : IconCheck;
  const bgColor = isError ? 'bg-red-500' : 'bg-primary';
  return (
    <div className="h-screen w-screen top-0 left-0 z-50 fixed flex items-center justify-center pb-14 bg-gray-800 bg-opacity-80">
      {/*  Modal. */}
      <div className="flex flex-col flex-grow md:max-w-xl bg-white shadow rounded-md px-11 pt-12 pb-14 m-3 mb-28 items-center justify-center">
        {/* Icon */}
        <div
          className={`h-24 w-24 rounded-full flex items-center justify-center ${bgColor}`}
        >
          <img alt="" className="w-10" src={icon} />
        </div>

        {/* Title */}
        <p className="pt-8 text-2xl">{title || 'Success!'}</p>

        {/* Description */}
        <p className="pt-4 font-light text-gray-400">{description}</p>

        {/* Button */}
        <button
          className={`h-12 w-1/2 mt-12 px-4 lg:px-6 ${bgColor} text-white rounded font-medium flex items-center justify-center`}
          onClick={onClose}
          onKeyDown={onKeyDown}
          ref={okayButtonRef}
          type="button"
        >
          Okay
        </button>
      </div>
    </div>
  );
}

export default memo(ModalInfo);
