import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';

import SignIn from '../../screens/auth/SignIn';
import Page404 from '../../screens/misc/Page404';

function AuthLayout() {
  return (
    <div className="flex justify-center md:items-center min-h-screen bg-primary-gradient font-medium text-base text-gray-700">
      <Routes>
        <Route element={<SignIn />} path="/" />

        <Route element={<Page404 />} />
      </Routes>
    </div>
  );
}

export default memo(AuthLayout);
