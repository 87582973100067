import {
  memo,
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { CancelToken } from 'axios';
import { useDropzone } from 'react-dropzone';
import { ApiContext } from '../../contexts/api';
import ProgressBar from './ProgressBar';

function UploadAvatarArea(props) {
  const { uploadUrl, isOpened, onClose, onSuccess, header } = props;
  const okayButtonRef = useRef();
  const [isUploading, setIsUploading] = useState(false);
  const api = useContext(ApiContext);
  const [progressValue, setProgressValue] = useState(0);
  const [badge, setBadge] = useState(null);
  const cancelUploadRef = useRef();

  const focusOnOkayButton = useCallback(() => {
    const isHiddenOrUnitialized = !isOpened || !okayButtonRef.current;
    if (isHiddenOrUnitialized) {
      return;
    }
    okayButtonRef.current.focus();
  }, [isOpened]);

  const resetModal = useCallback(() => {
    setBadge(null);
    setIsUploading(false);
    setProgressValue(0);
  }, []);

  const onOpen = useCallback(() => {
    focusOnOkayButton();
    resetModal();
  }, [resetModal, focusOnOkayButton]);

  useLayoutEffect(() => {
    if (!isOpened) {
      return;
    }

    onOpen();
  }, [isOpened, onOpen]);

  const onKeyDown = useCallback((event) => {
    const closeIfEscape = (key) => {
      const hasPressedEscape = 'escape' === key.toLowerCase();
      if (hasPressedEscape) {
        okayButtonRef.current.click();
      }
    };

    closeIfEscape(event.key);
  }, []);

  const uploadFile = useCallback(
    async (uploadUrl, uploadedFile) => {
      const data = new FormData();
      data.append('avatar', uploadedFile);

      const apiResult = await api.http.post(uploadUrl, data, {
        cancelToken: new CancelToken((cancelCallback) => {
          cancelUploadRef.current = cancelCallback;
        }),
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgressValue(progress);
        },
      });

      return apiResult;
    },
    [api]
  );

  const onDrop = useCallback(
    async (files) => {
      try {
        const hasOneFile = 1 === files?.length;
        if (!hasOneFile) {
          throw new Error('Invalid file format.');
        }

        setBadge({ text: 'Uploading', color: 'purple' });
        setIsUploading(true);

        const file = files.shift();
        const avatar = await uploadFile(uploadUrl, file);
        console.log('avatar', avatar);

        onSuccess(avatar.url);
      } catch (error) {
        const errorString = error?.avatar?.[0] ?? error?.message ?? error;
        const errorComponent = (
          <span>
            <strong>ERROR:</strong> {errorString}
          </span>
        );
        setBadge({ text: errorComponent, color: 'red' });
      }

      setIsUploading(false);
    },
    [onSuccess, uploadFile, uploadUrl]
  );

  const dropzoneSettings = {
    accept: ['image/jpeg', 'image/pjpeg', 'image/png', 'image/svg+xml'],
    onDrop,
    multiple: false,
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone(dropzoneSettings);

  const getDragMessage = useCallback(() => {
    if (isDragReject) {
      return 'File type not supported!';
    }

    return 'Drop file to start upload.';
  }, [isDragReject]);

  const onClickButton = useCallback(() => {
    if (isUploading) {
      cancelUploadRef.current('Canceled by the user');
      return;
    }
    onClose();
  }, [isUploading, onClose]);

  const getColorClass = (dropzoneStatus) => {
    const { isDragAccept, isDragActive, isDragReject } = dropzoneStatus;

    if (isDragAccept) {
      return 'blue';
    }
    if (isDragReject) {
      return 'red';
    }
    if (isDragActive) {
      return 'green';
    }

    return 'gray';
  };

  const dropzoneStatus = { isDragActive, isDragReject, isDragAccept };
  const borderColor = getColorClass(dropzoneStatus);

  const uploadingArea = isUploading ? (
    <ProgressBar percentageValue={progressValue} />
  ) : (
    <div
      {...getRootProps({ className: 'dropzone' })}
      className={`border-${borderColor}-300 bg-${borderColor}-100 cursor-pointer flex h-20 rounded border-2 border-dashed items-center justify-center`}
    >
      <span>{getDragMessage()}</span>
      <input {...getInputProps()} />
    </div>
  );

  const buttonLabel = isUploading ? 'Abort' : 'Cancel';

  return (
    <div
      className={`${
        isOpened ? 'opacity-1 visible' : 'opacity-0 invisible'
      } h-screen w-screen top-0 left-0 z-50 fixed flex items-center justify-center pb-14 bg-gray-800 bg-opacity-80 transition-all duration-300`}
    >
      {/*  Modal. */}
      <div className="flex flex-col flex-grow md:max-w-xl bg-white shadow rounded-md m-3 px-11 pt-12 pb-14 mb-28">
        {/* Title. */}
        <p className="text-left text-2xl text-bold">{header}</p>

        {/* Error/success badge. */}
        <div className="mt-8 flex mb-2">
          {badge ? (
            <span
              className={`text-xs font-semibold inline-block py-1 px-4 uppercase rounded-full text-${badge?.color}-600 bg-${badge?.color}-200`}
            >
              {badge?.text}
            </span>
          ) : null}
        </div>

        {/* Main content. */}
        <div className="mt-4 mb-4">{uploadingArea}</div>

        {/* Button. */}
        <div className="flex justify-center">
          <button
            className="h-12 w-1/2 mt-12 px-4 lg:px-6 bg-gray-300 text-white rounded font-medium flex items-center justify-center"
            onClick={onClickButton}
            onKeyDown={onKeyDown}
            ref={okayButtonRef}
            type="button"
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );
}

export default memo(UploadAvatarArea);
