import { useCallback, useRef } from 'react';
import { useContactForm } from '../useContactForm';

function useAddContact() {
  const { contacts, setContacts, setActiveContactTabIndex } = useContactForm();

  const newContactKeyRef = useRef(0);

  const addContact = useCallback(() => {
    const hasReachedMaxContacts = contacts.length >= 5;

    if (hasReachedMaxContacts) {
      alert("You've reached the maximum number of contacts.");

      return;
    }

    newContactKeyRef.current += 1;

    const newContact = {
      newContactKey: newContactKeyRef.current,
      id: '',
      name: '',
      email: '',
      phone: '',
      relationship: '',
    };

    setContacts([...contacts, newContact]);

    setActiveContactTabIndex(contacts.length);
  }, [contacts, setContacts, setActiveContactTabIndex]);

  return { addContact };
}

export default useAddContact;
