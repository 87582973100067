import { memo, useMemo } from 'react';
import CardAgent from '../CardAgent';
import CardAgentSkeleton from '../CardAgentSkeleton';

function CardsArea(props) {
  const { agents, isLoading, isAdmin } = props;

  const cardsSkeleton = (
    <>
      <CardAgentSkeleton />
      <CardAgentSkeleton />
      <CardAgentSkeleton />
    </>
  );

  const cardsList = useMemo(() => {
    const items = agents.map((agent) => {
      const url = isAdmin
        ? `/admin/agencies/${agent.agency.id}/agents/${agent.id}/edit`
        : `/admin/agents/${agent.id}/edit`;

      return <CardAgent agent={agent} key={'agent_' + agent.id} url={url} />;
    });

    return items;
  }, [agents, isAdmin]);

  return (
    <div
      className="grid min-h-screen place-content-start gap-4 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 justify-center"
      style={{ 'justify-content': 'stretch' }}
    >
      {isLoading ? cardsSkeleton : cardsList}
    </div>
  );
}

export default memo(CardsArea);
