import { memo } from 'react';
import HeaderColumn from '../HeaderColumn';

function HeaderColumnSet(props) {
  const {
    headers,
    isTableSortable,
    indexSortedColumn,
    isSortedAscendent,
    onClickColumn,
  } = props;

  return headers.map((item, index) => {
    return (
      <HeaderColumn
        isColumnSorted={isTableSortable ? index === indexSortedColumn : null}
        isSortedAscendent={isSortedAscendent}
        key={`th_${item.label}`}
        label={item.label}
        onClick={() => onClickColumn(index, item.isSortable)}
        wrapperClassName={item.className}
      />
    );
  });
}

export default memo(HeaderColumnSet);
