import { memo } from 'react';

import CardQuestionSkeleton from './CardQuestionSkeleton';

function MainAreaSkeleton() {
  return (
    <div className="animate-pulse">
      {/* Header Card. */}
      <div className="flex pt-6 pb-9 md:py-9 px-7 flex-col md:flex-row text-2xl rounded-lg flex-grow items-center bg-white">
        {/* Avatar. */}
        <div
          alt="patient avatar"
          className="h-20 w-20 mt-4 bg-gray-100 md:mt-0 rounded-full"
        />

        {/* Columns. */}
        <ul className="flex px-6 my-4 md:my-0 flex-col md:flex-row flex-wrap justify-between flex-grow">
          {/* Patient ID. */}
          <li className="md:py-0 py-2 px-8 flex-1">
            <div className="gap-4 flex flex-col w-3/4">
              <div className="h-2 bg-gray-100 rounded w-4/5" />
              <div className="h-2 bg-gray-100 rounded w-1/3" />
            </div>
          </li>

          <li className="hidden md:block border-l border-gray-200" />

          {/* Name. */}
          <li className="md:py-0 py-2 px-8 flex-1">
            <div className="gap-4 flex flex-col w-3/4">
              <div className="h-2 bg-gray-100 rounded w-4/5" />
              <div className="h-2 bg-gray-100 rounded w-1/3" />
            </div>
          </li>

          <li className="hidden md:block border-l border-gray-200" />

          {/* Gender. */}
          <li className="md:py-0 py-2 px-8 flex-1">
            <div className="gap-4 flex flex-col w-3/4">
              <div className="h-2 bg-gray-100 rounded w-4/5" />
              <div className="h-2 bg-gray-100 rounded w-1/3" />
            </div>
          </li>

          <li className="hidden md:block border-l border-gray-200" />

          {/* Birthday. */}
          <li className="md:py-0 py-2 px-8 flex-1">
            <div className="gap-4 flex flex-col w-3/4">
              <div className="h-2 bg-gray-100 rounded w-4/5" />
              <div className="h-2 bg-gray-100 rounded w-1/3" />
            </div>
          </li>

          <li className="hidden md:block border-l border-gray-200" />

          {/* Age. */}
          <li className="md:py-0 py-2 px-8 flex-1">
            <div className="gap-4 flex flex-col w-3/4">
              <div className="h-2 bg-gray-100 rounded w-4/5" />
              <div className="h-2 bg-gray-100 rounded w-1/3" />
            </div>
          </li>

          <li className="" />
        </ul>
        {/* Columns. */}
      </div>
      {/* /Header Card. */}

      {/* Cards Area. */}
      <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 w-full my-9 place-content-start md:gap-11 gap-y-11">
        {/* Main Card. */}
        <div className="col-span-2 row-span-3 p-8 flex flex-col rounded-lg shadow-custom bg-white">
          {/* Card Title. */}
          <h3 className="text-2xl">
            <div className="h-2 bg-gray-100 rounded w-4/5" />
          </h3>

          {/* Graph Bars. */}
          <div className="flex w-full h-full pt-7 items-end justify-center">
            <div className="bg-gray-100 w-full h-3/4" />
          </div>
        </div>

        {/* Cards. */}
        <CardQuestionSkeleton cardIndex={0} />
        <CardQuestionSkeleton cardIndex={1} />
        <CardQuestionSkeleton cardIndex={2} />
      </div>
      {/* /Cards Area. */}
    </div>
  );
}

export default memo(MainAreaSkeleton);
