import { memo } from 'react';

function Avatar(props) {
  const { user } = props;

  const hasNoAvatar = !user.avatar_url;
  if (hasNoAvatar) {
    return (
      <div className="w-12 h-12 block m-auto">
        <span className="w-12 h-12 rounded-full bg-green-600 text-white font-bold flex justify-center items-center">
          {user?.first_name[0]}
          {user?.last_name[0]}
        </span>
      </div>
    );
  }

  return (
    <img
      alt="User's avatar"
      className="h-12 w-12 rounded-full block m-auto"
      src={user.avatar_url}
    />
  );
}

export default memo(Avatar);
