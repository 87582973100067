import { createContext, useMemo, useRef, useState } from 'react';
import axiosService, { CancelToken } from 'axios';
import { useNavigate } from 'react-router';

const ApiContext = createContext({});

function ApiProvider(props) {
  const { children } = props;
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [alertDays, setAlertDays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const cancelRequestsRef = useRef([]);
  const requestCounter = useRef(0);
  const SIGNIN_URL = '/sign-in';

  /** Create axios instance. */
  const axios = axiosService.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  /** Auth. */
  const auth = {
    saveTokenToDisk: (newToken) => {
      localStorage.setItem('token_jwt', newToken);
    },

    saveUserToDisk: (newUserData) => {
      localStorage.setItem('user', JSON.stringify(newUserData));
    },

    signIn: async (params) => {
      try {
        const baseUrl = `/auth/sign-in`;
        const apiResult = await http.post(baseUrl, params);
        auth.saveTokenToDisk(apiResult.access_token);
        auth.saveUserToDisk(apiResult.user);
        setUser(apiResult.user);
      } catch (error) {
        const message =
          401 === http.getLastHttpStatus() ? 'Wrong Credentials.' : error;
        return Promise.reject(message);
      }
    },

    refresh: async () => {
      try {
        const baseUrl = `/auth/me`;
        const apiResult = await http.post(baseUrl);
        auth.saveUserToDisk(apiResult);
        setUser(apiResult);
        return true;
      } catch {
        auth.signOut();
        return false;
      }
    },

    signOut: async () => {
      const clearAuthTokenFromDisk = () => {
        localStorage.clear();
      };

      clearAuthTokenFromDisk();
      setUser(null);
      setToken(null);
    },
  };

  const alerts = {
    refresh: async () => {
      const apiResult = await http.get(`/admin/alerts?unchecked=true`);
      setAlertDays(apiResult?.data);
    },
  };

  const http = {
    request: async (config) => {
      const setAuthTokenOnHeader = () => {
        const getUserFromDisk = () => {
          try {
            const userFromDisk = JSON.parse(localStorage.getItem('user'));
            return userFromDisk;
          } catch {
            return null;
          }
        };

        const getTokenFromDisk = () => {
          const tokenFromDisk = localStorage.getItem('token_jwt');
          return tokenFromDisk;
        };

        const loadTokenAndUser = () => {
          const isTokenNull = null === token;
          const isUserNull = null === user;
          if (!isTokenNull && !isUserNull) {
            return { token, user };
          }

          const tokenFromDisk = getTokenFromDisk();
          setToken(tokenFromDisk);

          const userFromDisk = getUserFromDisk();
          setUser(userFromDisk);

          return { token: tokenFromDisk, user: userFromDisk };
        };

        const { token: currentToken, user: currentUser } = loadTokenAndUser();
        if (currentToken && currentUser) {
          axios.defaults.headers.common.Authorization = `Bearer ${currentToken}`;
        }
      };

      let requestId = null;
      try {
        const hasCancelToken = !!config.cancelToken;
        if (!hasCancelToken) {
          requestCounter.current++;
          requestId = requestCounter.current;
          config.cancelToken = new CancelToken((cancelCallback) => {
            cancelRequestsRef.current.push({ requestId, cancelCallback });
          });
        }

        setIsLoading(true);
        setAuthTokenOnHeader();
        const apiResult = await axios.request(config);
        http.setLastHttpStatus(200);
        return apiResult.data;
      } catch (apiResult) {
        const status = apiResult?.response?.status;
        http.setLastHttpStatus(status);

        const isNetworkError = !status;
        if (isNetworkError) {
          return Promise.reject('Network Error.');
        }

        const isTokenExpired = 403 === status;
        if (isTokenExpired) {
          const goToSignIn = async () => {
            setTimeout(() => {
              navigate(SIGNIN_URL);
            });
          };
          auth.signOut();
          goToSignIn();
          return Promise.reject('Session expired.');
        }

        const errors = apiResult?.response?.data?.errors;
        if (errors) {
          return Promise.reject(errors);
        }

        const error =
          apiResult?.response?.data?.error ??
          apiResult?.response?.data?.message;
        if (error) {
          return Promise.reject(error);
        }

        const httpError = apiResult?.response?.statusText;
        if (httpError) {
          return Promise.reject(httpError);
        }

        return Promise.reject(apiResult);
      } finally {
        const hasRequestId = null !== requestId;
        if (hasRequestId) {
          const removeRequestId = (value) => value.requestId !== requestId;
          cancelRequestsRef.current =
            cancelRequestsRef.current.filter(removeRequestId);
        }

        setIsLoading(false);
      }
    },

    cancelAll: () => {
      cancelRequestsRef.current = [];
    },

    get: async (url, config) => {
      return await http.request({ method: 'get', url, ...config });
    },

    post: async (url, data, config) => {
      return await http.request({ method: 'post', url, data, ...config });
    },

    put: async (url, data, config) => {
      return await http.request({ method: 'put', url, data, ...config });
    },

    lastHttpStatus: null,
    getLastHttpStatus: () => http.lastHttpStatus,
    setLastHttpStatus: (httpStatus) => (http.lastHttpStatus = httpStatus),
  };

  const initialValues = useMemo(
    () => ({
      isLoggedIn: !!user,
      isLoading,
      user,
      auth,
      http,
      alerts,
      alertDays,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alertDays, alerts, isLoading, user]
  );

  return (
    <ApiContext.Provider value={initialValues}>{children}</ApiContext.Provider>
  );
}

export default ApiProvider;
export { ApiContext };
