import { memo, useMemo } from 'react';

import IconSortAZ from './../../../assets/images/icon-sort-az.svg';
import IconSortZA from './../../../assets/images/icon-sort-za.svg';

function HeaderColumn(props) {
  const {
    isColumnSorted,
    isSortedAscendent,
    onClick,
    label,
    wrapperClassName,
  } = props;

  const iconSort = useMemo(() => {
    if (!isColumnSorted) {
      return null;
    }

    if (isSortedAscendent) {
      return <img alt="sorted-az" className="h-5 mr-3" src={IconSortAZ} />;
    }

    return <img alt="sorted-za" className="h-5 mr-3" src={IconSortZA} />;
  }, [isColumnSorted, isSortedAscendent]);

  return (
    <th
      className={['px-8 py-4 cursor-pointer select-none', wrapperClassName]}
      onClick={onClick}
    >
      <div className="flex items-center">
        {/* Sort Icon. */}
        {iconSort}

        {/* Column title. */}
        <span>{label}</span>
      </div>
    </th>
  );
}

export default memo(HeaderColumn);
