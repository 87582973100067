import { memo } from 'react';
import InputSkeleton from './InputSkeleton';

function FormSkeleton() {
  return (
    <div className="animate-pulse flex flex-col gap-6 pt-10 pb-20 max-w-xl">
      <InputSkeleton inputIndex={0} />
      <InputSkeleton inputIndex={1} />
      <InputSkeleton inputIndex={2} />
      <InputSkeleton inputIndex={3} />
      <InputSkeleton inputIndex={4} />
      <InputSkeleton inputIndex={5} />
    </div>
  );
}

export default memo(FormSkeleton);
