import { memo } from 'react';
import { Link } from 'react-router-dom';

import IconMore from './../../../../../assets/images/icon-more.svg';
import IconGenderMale from './../../../../../assets/images/icon-male.svg';
import IconGenderFemale from './../../../../../assets/images/icon-female.png';
import DayList from './DayList';

function CardPatient(props) {
  const { patient, url } = props;
  const isGenderFemale = patient.gender && 'female' === patient.gender;
  const IconGender = isGenderFemale ? IconGenderFemale : IconGenderMale;

  return (
    <Link to={url}>
      <div className="flex flex-col rounded-lg overflow-hidden shadow-custom">
        {/* Header. */}
        <div className="flex flex-row h-32 p-7 items-center bg-white">
          {/* Image. */}
          {patient?.user?.avatar_url ? (
            <img
              alt="Patient avatar"
              className="flex w-20 h-20 rounded-full object-cover"
              src={patient.user.avatar_url}
            />
          ) : (
            <span className="flex w-20 h-20 rounded-full bg-green-600 text-white text-2xl font-bold justify-center items-center">
              {patient.user?.first_name[0]}
              {patient.user?.last_name[0]}
            </span>
          )}

          {/* Patient's info. */}
          <div className="flex flex-col pl-6">
            <p className="flex items-center text-2xl font-extrabold">
              {patient.user?.first_name} {patient.user?.last_name}
              <img
                alt={`Gender ${patient.gender}`}
                className="h-5 pl-2"
                src={IconGender}
              />
            </p>

            <p className="text-xl text-gray-400">
              Patient ID:
              <span className="ml-2 text-primary-light font-bold underline">
                {patient.patient_code}
              </span>
            </p>
          </div>

          {/* More. */}
          <img alt="See more" className="h-7 ml-auto" src={IconMore} />
        </div>
        {/* /Header. */}

        {/* Daily Activity. */}
        <div className="flex flex-col h-96 mt-0.5 px-6 md:px-10 py-8 overflow-auto text-xl 3xl:text-2xl text-gray-400 bg-white">
          <DayList days={patient.days} today_index={patient.today_index} />
        </div>

        {/* Footer. */}
        <div className="flex h-24 items-center justify-center text-xl text-primary bg-white mt-0.5">
          View Patient
        </div>
      </div>
    </Link>
  );
}

export default memo(CardPatient);
