import { memo } from 'react';
import dayjs from 'dayjs';
import HistoryFormattedValue from './HistoryFormattedValue';

function HistoryTable(props) {
  const { isLoading, dayRows, questionsHeader, todayIndex } = props;

  if (isLoading) {
    return 'Loading...';
  }

  const headerCols = questionsHeader.map((question) => {
    return (
      <th className="px-7 py-3 text-center" key={`th-question-${question.id}`}>
        {question.label}
      </th>
    );
  });

  const rows = dayRows.map((day) => {
    const cols = questionsHeader.map((question) => {
      const answer = day.answers.find(
        (answer) => answer.question.id === question.id
      );
      const key = answer
        ? `td-day-answer-${answer?.id}`
        : `td-day-noanswer-${day.id}-${question.id}`;
      return (
        <td className="px-7" key={key}>
          <HistoryFormattedValue
            answer={answer}
            question={answer?.question}
            todayIndex={todayIndex}
          />
        </td>
      );
    });

    const alert = day.is_threshold_hit ? (
      <p className="h-12 mx-auto text-sm flex rounded-full bg-red-100 text-red-600 border-2 border-red-600 font-bold items-center justify-center uppercase">
        Notified
      </p>
    ) : (
      <p className="h-12 mx-auto text-sm flex rounded-full bg-green-100 text-green-600 border-2 border-green-600 font-bold items-center justify-center uppercase">
        Not Notified
      </p>
    );

    const dayFormatted = day.answered_at
      ? dayjs(day.answered_at).format('MMM D, YYYY @ hh:mma')
      : dayjs()
          .subtract(todayIndex - day.day_index, 'day')
          .format('MMM D, YYYY'); // Retrocompability for entries before March 15, 2022 (before `answerd_at` field)
    return (
      <tr className="h-20" key={`tr-day-${day.id}`}>
        <td className="px-7">{dayFormatted}</td>
        {cols}
        <td className="px-7">{alert}</td>
      </tr>
    );
  });

  return (
    <div className="flex flex-col p-8 rounded-lg shadow-custom bg-white">
      <h3 className="text-2xl font-black uppercase">Patient History</h3>

      <div className="overflow-auto">
        <table className="mt-7 w-full">
          <thead>
            <tr className="bg-gray-100 text-sm font-extrabold uppercase text-left">
              <th className="px-7 py-3">Date</th>
              {headerCols}
              <th className="px-7 py-3 text-center">Alert</th>
            </tr>
          </thead>

          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );
}

export default memo(HistoryTable);
