import { memo } from 'react';
import { useField } from 'formik';
import Select from 'react-select';

function SelectFormik(props) {
  const [field, meta, helpers] = useField(props);
  const { options } = props;
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <Select
      options={options}
      {...field}
      {...props}
      menuPortalTarget={document.body}
      onChange={(option) => setValue(option.value)}
      styles={styles}
      value={options.find((item) => item.value === value)}
    />
  );
}

const styles = {
  control: (css) => ({
    ...css,
    border: 'none',
    height: '100%',
    borderRadius: 'inherit',
    paddingLeft: '0.750rem',
    paddingRight: '0.25rem',
  }),

  // fix overflow issue
  menuPortal: (css) => ({
    ...css,
    zIndex: 9999,
  }),
};

export default memo(SelectFormik);
