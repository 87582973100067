import { memo } from 'react';

import IconSearch from './../../../assets/images/icon-search.svg';
import IconChevronDown from './../../../assets/images/icon-chevron-down.svg';

function TitleBar(props) {
  const {
    title,
    enabledFilter,
    enabledSearch,
    onSearchChange,
    searchPlaceholder,
  } = props;

  const searchBox = enabledSearch ? (
    <div className="flex flex-grow max-w-xl relative items-center">
      <input
        className="h-12 w-full px-6 pr-11 rounded border border-gray-300 bg-transparent focus:ring-2 focus:ring-primary focus:bg-blue-100"
        onChange={(event) => onSearchChange(event?.target?.value)}
        placeholder={
          searchPlaceholder ?? 'Search by Patient ID, name, and birthdate'
        }
        type="text"
      />
      <img alt="" className="h-5 w-5 absolute right-5" src={IconSearch} />
    </div>
  ) : null;

  const filterBox = enabledFilter ? (
    <div className="flex ml-7 text-gray-400">
      {/* Filter Label. */}
      <p className="mr-2.5">Filter By:</p>

      {/* Filter Select. */}
      <p>Recently Added</p>
      <img alt="" className="ml-2.5" src={IconChevronDown} />
    </div>
  ) : null;

  return (
    <div className="hidden md:flex flex-col md:flex-row py-4 items-center">
      {/* Page Title. */}
      <h2 className="mr-16 text-2xl font-black">{title}</h2>

      {/* Search Box. */}
      {searchBox}

      {/* Filter. */}
      {filterBox}
    </div>
  );
}

export default memo(TitleBar);
