import { memo } from 'react';
import UserRoutesAdmin from './UserRoutesAdmin';
import UserRoutesAgency from './UserRoutesAgency';
import UserRoutesAgent from './UserRoutesAgent';
import UserRoutesPatient from './UserRoutesPatient';

function UserRoutes(props) {
  const { role, user } = props;

  const is_superadmin = 'super-admin' === role;
  const is_admin = 'admin' === role;
  if (is_superadmin || is_admin) {
    return <UserRoutesAdmin user={user} />;
  }

  const is_agency = 'agency-admin' === role;
  if (is_agency) {
    return <UserRoutesAgency user={user} />;
  }

  const is_patient = 'patient' === role;
  if (is_patient) {
    return <UserRoutesPatient user={user} />;
  }

  const is_agent = 'agent' === role;
  if (is_agent) {
    return <UserRoutesAgent user={user} />;
  }

  return <p>Unknown Role</p>;
}

export default memo(UserRoutes);
