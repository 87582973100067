import { memo } from 'react';
import { Link } from 'react-router-dom';

import IconGear from './../../../../assets/images/icon-gear.svg';

function SettingsButton(props) {
  const { to } = props;
  return (
    <Link
      className="flex flex-auto md:flex-initial items-center justify-center h-12 px-4 lg:px-6 ml-2 bg-gray-300 rounded hover:bg-opacity-70"
      to={to ?? '/'}
    >
      <img alt="" className="mx-auto w-6" src={IconGear} />
    </Link>
  );
}

export default memo(SettingsButton);
