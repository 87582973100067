import { memo } from 'react';
import CardAgency from '../CardAgency';
import CardAgencySkeleton from '../CardAgencySkeleton';

function CardsArea(props) {
  const { agencies, isLoading } = props;

  const getCardsSkeleton = () => {
    return (
      <>
        <CardAgencySkeleton />
        <CardAgencySkeleton />
        <CardAgencySkeleton />
      </>
    );
  };

  const getCardsList = () => {
    const cardsList = agencies.map((item) => (
      <CardAgency agency={item} key={`agency_${item.id}`} />
    ));
    return cardsList;
  };

  const cardsList = isLoading ? getCardsSkeleton() : getCardsList();
  return (
    <div className="grid min-h-screen place-content-start gap-4 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 justify-center">
      {cardsList}
    </div>
  );
}

export default memo(CardsArea);
