import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import Header from '../../../../shared-components/Header';
import ActionBar from '../../../../shared-components/Header/ActionBar';
import AgentList from '../../../../shared-components/Header/ActionBar/AgentList';
import NormalButton from '../../../../shared-components/Header/ActionBar/NormalButton';
import PrimaryButton from '../../../../shared-components/Header/ActionBar/PrimaryButton';
import SettingsButton from '../../../../shared-components/Header/ActionBar/SettingsButton';
import BackBar from '../../../../shared-components/Header/BackBar';
import TitleBar from '../../../../shared-components/Header/TitleBar';
import ModalInfo from '../../../../shared-components/ModalInfo';
import AgencyLogo from '../../../../shared-components/AgencyLogo';
import useEffectOnce from '../../../../utils/useEffectOnce';
import { ApiContext } from '../../../../contexts/api';
import AnimatedScreen from '../../../../shared-components/AnimatedScreen';
import { GlobalContext } from '../../../../contexts/global';
import CardsArea from './CardsArea';

function AgencyDetailsScreen(props) {
  const { agencyId: propsAgencyId } = props;
  const params = useParams();
  const [patients, setPatients] = useState([]);
  const [agency, setAgency] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [modalError, setModalError] = useState(null);
  const api = useContext(ApiContext);
  const globalContext = useContext(GlobalContext);

  const agencyId = params.agencyId ?? propsAgencyId;
  const role = useMemo(() => api?.user.role, [api]);
  const isAdmin = useMemo(
    () => 'super-admin' === role || 'admin' === role,
    [role]
  );

  const fetchAgency = async (id) => {
    const apiResult = await api.http.get(`/admin/agencies/${id}`);
    setAgency(apiResult);
  };

  const fetchPatients = async (agencyId) => {
    const apiResult = await api.http.get(
      `/admin/agencies/${agencyId}/patients`
    );
    setPatients(apiResult);
  };

  const urlBack = useMemo(() => {
    if (isAdmin) {
      return `/admin/agencies`;
    }

    return null;
  }, [isAdmin]);

  useEffect(() => {
    const setGlobalToolbar = () => {
      globalContext.setGlobalTitle(agency?.name ?? '...');
      globalContext.setUrlBack(urlBack);
      globalContext.setEnabledSearch(false);
    };
    setGlobalToolbar();
  }, [agency, globalContext, urlBack]);

  const onScreenLoad = async (agencyId) => {
    try {
      await fetchAgency(agencyId);
      await fetchPatients(agencyId);
    } catch (error) {
      setModalError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    onScreenLoad(agencyId);

    return () => api.http.cancelAll();
  });

  const headerLeftSide = (
    <div className="hidden md:flex">
      <BackBar
        logo={<AgencyLogo agency={agency} />}
        title={agency?.name}
        url={urlBack}
      />
    </div>
  );

  const headerRightSide = useMemo(() => {
    const urlAgentList = isAdmin
      ? `/admin/agencies/${agency?.id}/agents`
      : `/admin/agents`;
    const urlAdminList = isAdmin
      ? `/admin/agencies/${agency?.id}/agency-admins`
      : `/admin/agency-admins`;
    const urlAddPatient = isAdmin
      ? `/admin/agencies/${agency?.id}/patients/new`
      : `/admin/patients/new`;
    const urlAgencyEdit = isAdmin
      ? `/admin/agencies/${agency?.id}/edit`
      : `/admin/agency/edit`;
    return (
      <ActionBar>
        <div className="w-full mb-4 md:mb-0 md:w-auto">
          <AgentList
            avatarUrls={agency?.agent_avatar_urls}
            url={urlAgentList}
          />
        </div>
        <div className="hidden md:flex">
          <NormalButton title="Agents" url={urlAgentList} />
        </div>
        <NormalButton title="Admins" url={urlAdminList} />
        <PrimaryButton title="Add Patient" url={urlAddPatient} />
        <SettingsButton to={urlAgencyEdit} />
      </ActionBar>
    );
  }, [agency, isAdmin]);

  const headerTitle = <TitleBar title="Patients" />;

  return (
    <AnimatedScreen>
      <div className="px-3 md:px-0 md:pb-12">
        <Header
          leftSide={headerLeftSide}
          rightSide={headerRightSide}
          title={headerTitle}
        />

        {/* Error Modal. */}
        <ModalInfo
          isError
          isOpened={!!modalError}
          onClose={() => setModalError(null)}
          title={modalError}
        />

        {/* Main Area. */}
        <div className="my-8 pb-12">
          <CardsArea
            isAdmin={isAdmin}
            isLoading={isLoading}
            patients={patients}
          />
        </div>
      </div>
    </AnimatedScreen>
  );
}

export default memo(AgencyDetailsScreen);
