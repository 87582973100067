import { memo, useMemo, useState, useCallback } from 'react';
import classes from './index.module.css';
import HeaderColumnSet from './HeaderColumnSet';

function Table(props) {
  const { headers, data, isLoading, isSortable: isTableSortable } = props;

  const [indexSortedColumn, setIndexSortedColumn] = useState(0);
  const [isSortedAscendent, setIsSortedAscendent] = useState(true);

  const onClickColumn = useCallback(
    (columnIndex, isColumnSortable) => {
      if (!isColumnSortable || !isTableSortable) {
        return;
      }

      const hasChangedColumn = indexSortedColumn !== columnIndex;
      if (hasChangedColumn) {
        setIndexSortedColumn(columnIndex);
        setIsSortedAscendent(true);
        return;
      }

      setIsSortedAscendent((value) => !value);
    },
    [indexSortedColumn, isTableSortable]
  );

  const skeleton = useMemo(() => {
    const rows = [1, 2, 3, 4, 5, 6];
    const dataItems = rows.map((rowIndex) => {
      const widthClasses = [
        'w-2/3',
        'w-1/2',
        'w-4/5',
        'w-1/3',
        'w-full',
        'w-3/4',
        'w-1/4',
        'w-2/4',
        'w-1/5',
        'w-3/5',
      ];
      const tds = headers.map((value, colIndex) => {
        const width = widthClasses[(colIndex + rowIndex) % widthClasses.length];
        return (
          // eslint-disable-next-line react/no-array-index-key
          <td className="px-8 py-4" key={`td_skeleton_${rowIndex}_${colIndex}`}>
            <div className={`h-2 bg-gray-100 rounded ${width}`} />
          </td>
        );
      });

      return <tr key={`tr_skeleton_${rowIndex}`}>{tds}</tr>;
    });

    return dataItems;
  }, [headers]);

  const sortedData = useMemo(() => {
    if (!data) {
      return null;
    }

    if (!isTableSortable) {
      return data;
    }

    // sort questions by title
    const sortedQuestions = [...data];
    sortedQuestions.sort((a, b) => {
      if (
        a[indexSortedColumn].toLowerCase() < b[indexSortedColumn].toLowerCase()
      )
        return -1;
      if (
        a[indexSortedColumn].toLowerCase() > b[indexSortedColumn].toLowerCase()
      )
        return +1;
      return 0;
    });

    // reverse
    if (!isSortedAscendent) {
      sortedQuestions.reverse();
    }

    return sortedQuestions;
  }, [data, isSortedAscendent, indexSortedColumn, isTableSortable]);

  const tableWithData = useMemo(() => {
    if (!sortedData) {
      return null;
    }

    return sortedData.map((row, rowIndex) => {
      const tds = row.map((value, colIndex) => {
        const customClassName = headers[colIndex].tdClassName;
        const tdClassName = customClassName ?? 'px-8 py-4';
        return (
          // eslint-disable-next-line react/no-array-index-key
          <td className={tdClassName} key={`td_${rowIndex}_${colIndex}`}>
            {value}
          </td>
        );
      });

      return (
        // eslint-disable-next-line react/no-array-index-key
        <tr className="hover:bg-blue-50" key={`tr_${rowIndex}`}>
          {tds}
        </tr>
      );
    });
  }, [headers, sortedData]);

  const dataItems = useMemo(() => {
    return isLoading ? skeleton : tableWithData;
  }, [isLoading, skeleton, tableWithData]);

  return (
    <>
      {/* Card. */}
      <div className="bg-white rounded overflow-auto shadow-custom overflow-auto">
        <table className={`table-fixed min-w-full ${classes.customTable}`}>
          <thead>
            <tr className="text-xl bg-blue-100 text-left">
              <HeaderColumnSet
                headers={headers}
                indexSortedColumn={indexSortedColumn}
                isSortedAscendent={isSortedAscendent}
                isTableSortable={isTableSortable}
                onClickColumn={onClickColumn}
              />
            </tr>
          </thead>
          <tbody className={isLoading ? 'animate-pulse' : ''}>
            {dataItems}
          </tbody>
        </table>
      </div>
      {/* /Card. */}
    </>
  );
}

export default memo(Table);
