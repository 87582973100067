import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Header from '../../../../shared-components/Header';
import ActionBar from '../../../../shared-components/Header/ActionBar';
import PrimaryButton from '../../../../shared-components/Header/ActionBar/PrimaryButton';
import TitleBar from '../../../../shared-components/Header/TitleBar';
import ModalInfo from '../../../../shared-components/ModalInfo';
import useEffectOnce from '../../../../utils/useEffectOnce';
import Table from '../../../../shared-components/Table';
import Avatar from '../../../../shared-components/Table/Avatar';
import ButtonEdit from '../../../../shared-components/Table/ButtonEdit';
import Agency from '../../../../shared-components/Table/Agency';
import { ApiContext } from '../../../../contexts/api';
import AnimatedScreen from '../../../../shared-components/AnimatedScreen';
import { GlobalContext } from '../../../../contexts/global';
import TableCellGender from './TableCellGender';

function PatientListScreen() {
  const [patients, setPatients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [filtered, setFiltered] = useState([]);
  const api = useContext(ApiContext);
  const globalContext = useContext(GlobalContext);

  const pageTitle = 'Patients';

  const fetchPatients = async () => {
    const apiResult = await api.http.get('/admin/patients');
    setPatients(apiResult);
  };

  useEffect(() => {
    const setGlobalToolbar = () => {
      globalContext.setGlobalTitle(pageTitle);
      globalContext.setUrlBack(null);
      globalContext.setEnabledSearch(true);
      globalContext.setSearchValue('');
    };
    setGlobalToolbar();
  }, [globalContext, pageTitle]);

  const onScreenLoad = async () => {
    try {
      await fetchPatients();
      setIsLoading(false);
    } catch (message) {
      setModal({
        isError: true,
        message,
        onClose: () => setModal(null),
      });
    }
  };

  useEffectOnce(() => {
    onScreenLoad();

    return () => api.http.cancelAll();
  });

  useEffect(() => {
    setSearchValue(globalContext.searchValue);
  }, [globalContext.searchValue]);

  const onChangeSearch = useCallback(() => {
    const filtered = patients.filter((patient) => {
      const lowerSearchValue = searchValue.toLowerCase();

      const isOnPatientID = patient.patient_code
        .toLowerCase()
        .includes(lowerSearchValue);
      if (isOnPatientID) {
        return true;
      }

      const isOnFirstName = patient.user.first_name
        .toLowerCase()
        .includes(lowerSearchValue);
      if (isOnFirstName) {
        return true;
      }

      const isOnLastName = patient.user.last_name
        .toLowerCase()
        .includes(lowerSearchValue);
      if (isOnLastName) {
        return true;
      }

      const isOnEmail = patient.user.email
        .toLowerCase()
        .includes(lowerSearchValue);
      if (isOnEmail) {
        return true;
      }

      const isOnAgency = patient.agency.name
        .toLowerCase()
        .includes(lowerSearchValue);
      if (isOnAgency) {
        return true;
      }

      return false;
    });
    setFiltered(filtered);
  }, [patients, searchValue]);

  useEffect(() => {
    onChangeSearch();
  }, [onChangeSearch, searchValue, patients]);

  const headerRightSide = (
    <ActionBar>
      <PrimaryButton title="Create New Patient" url="/admin/patients/new" />
    </ActionBar>
  );

  const headerTitle = (
    <TitleBar
      enabledFilter={false}
      enabledSearch
      onSearchChange={setSearchValue}
      title={pageTitle}
    />
  );

  const getTableRows = useCallback((patients) => {
    return patients.map((patient) => [
      <Avatar key={patient?.id} user={patient?.user} />,
      patient.patient_code,
      `${patient?.user?.last_name}, ${patient?.user?.first_name}`,
      <Agency agency={patient?.agency} key={patient?.agency} />,
      <TableCellGender gender={patient.gender} key={patient?.gender} />,
      patient?.user?.email,
      patient.age,
      <ButtonEdit
        key={patient?.id}
        label="Details"
        to={`/admin/patients/${patient.id}/details`}
      />,
    ]);
  }, []);
  const tableData = useMemo(
    () => getTableRows(filtered),
    [filtered, getTableRows]
  );

  const headers = [
    { label: '', className: 'w-auto', tdClassName: 'py-3 px-6' },
    { label: 'Patient ID#', className: 'w-auto' },
    { label: 'Name', className: 'w-auto' },
    { label: 'Agency', className: 'w-auto', tdClassName: 'py-3 px-6 max-w-xs' },
    {
      label: 'Gender',
      className: 'w-auto',
      tdClassName: 'capitalize px-8 py-4',
    },
    { label: 'Email', className: 'w-auto' },
    { label: 'Age', className: 'w-auto' },
    { label: 'Actions', className: 'w-auto text-center' },
  ];

  return (
    <AnimatedScreen>
      <div className="px-3 md:px-0 md:pb-12">
        <Header leftSide={headerTitle} rightSide={headerRightSide} />

        {/* Error and Success Modal. */}
        <ModalInfo
          isError={modal?.isError}
          isOpened={!!modal}
          onClose={modal?.onClose}
          title={modal?.message}
        />

        {/* Main Area. */}
        <div className="my-8 pb-12">
          <Table data={tableData} headers={headers} isLoading={isLoading} />
        </div>
      </div>
    </AnimatedScreen>
  );
}

export default memo(PatientListScreen);
