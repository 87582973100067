import { memo } from 'react';
import { Link } from 'react-router-dom';

function ButtonEdit(props) {
  const { to, label } = props;

  return (
    <div className="text-center">
      <Link className="bg-primary text-white px-4 py-2 rounded" to={to}>
        {label ?? 'Edit'}
      </Link>
    </div>
  );
}

export default memo(ButtonEdit);
