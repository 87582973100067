import { memo } from 'react';
import dayjs from 'dayjs';

function ChangelogCard({ changelog }) {
  const user = changelog.action_by_user;
  return (
    <div className="changelogCard p-4 flex flex-col rounded-lg shadow-custom-40 overflow-hidden bg-white justify-center">
      <p className="font-bold">
        {dayjs(changelog.action_at).format('MMM D, YYYY @ hh:mma')}
      </p>
      <p>
        <span className="font-bold">
          {user.first_name} {user.last_name}
        </span>{' '}
        <span className="italic" />
      </p>
      {/* <p>Action: {changelog.action}</p> */}
    </div>
  );
}

export default memo(ChangelogCard);
