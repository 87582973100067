/** TODO: enable the linter rules below */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-unstable-nested-components */
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import Logo from './../../assets/images/logo-voice-health-with-smart-decreased-margin.svg';
import IconLoading from './../../assets/images/icon-loading-dual-ring.svg';
import IconHamburger from './../../assets/images/icon-hamburger.svg';
import IconChevronDown from './../../assets/images/icon-chevron-down-700.svg';
import { ReactComponent as IconHome } from './../../assets/images/icon-home.svg';
import { ReactComponent as IconPatients } from './../../assets/images/icon-patients.svg';
import { ReactComponent as IconQuestions } from './../../assets/images/icon-checklist.svg';
import { ReactComponent as IconUsers } from './../../assets/images/icon-users.svg';
import { ReactComponent as IconSettings } from './../../assets/images/icon-settings.svg';
import { ReactComponent as IconNotifications } from './../../assets/images/icon-notifications.svg';
import { ReactComponent as IconLogout } from './../../assets/images/icon-logout.svg';
import ChrevronLeft from './../../assets/images/icon-chevron-left.svg';
import IconBell from './../../assets/images/icon-bell.svg';
// import IconFilter from './../../assets/images/icon-filter.svg';
import { ReactComponent as IconSearch } from './../../assets/images/icon-search-dialog.svg';
import IconNotificationsTop from './../../assets/images/icon-bell-outline.svg';
import IconBellBlue from './../../assets/images/icon-bell-blue.svg';
import IconChecked from './../../assets/images/icon-checked.svg';
// import LogoAgencyHHN from './../../assets/images/logo-agency-hhn.svg';
import LogoMobile from './../../assets/images/logo-voice-health-mobile.svg';
import classes from './index.module.css';
import { ApiContext } from '../../contexts/api';
import { GlobalContext } from '../../contexts/global';
import MenuDialog from './MenuDialog';
import useEffectOnce from '../../utils/useEffectOnce';
import UserRoutes from './UserRoutes';
import ModalSearch from '../../shared-components/ModalSearch';

function AdminLayout() {
  const [isNotificationOpened, setIsNotificationOpened] = useState(false);
  const [isProfileOpened, setIsProfileOpened] = useState(false);
  const [isLoadingAlerts, setIsLoadingAlerts] = useState(true);
  const api = useContext(ApiContext);
  const globalContext = useContext(GlobalContext);
  const profileToggleRef = useRef();
  const notificationToggleRef = useRef();
  const { pathname } = useLocation();

  const role = api?.user?.role;
  const isAdmin = 'super-admin' === role || 'admin' === role;
  const isAgency = 'agency-admin' === role;
  // const isAgent = 'agent' === role;
  const isPatient = 'patient' === role;

  const hasAlerts = !!api.alertDays && 0 !== api.alertDays.length;

  const onScreenLoad = async () => {
    try {
      await api.alerts.refresh();
      setIsLoadingAlerts(false);
    } catch (message) {
      console.log(message);
    }
  };

  useEffectOnce(() => {
    onScreenLoad();

    return () => api.http.cancelAll();
  });

  const AlertList = memo((props) => {
    const { alertDays, isLoading } = props;

    if (isLoading) {
      return 'Loading ...';
    }

    const hasNoAlerts = !alertDays || 0 === alertDays.length;
    if (hasNoAlerts) {
      return (
        <li className="flex flex-col h-40 py-6 px-10 items-center justify-center italic text-gray-500 cursor-default">
          <p>You have no notifications.</p>
        </li>
      );
    }

    return alertDays.slice(0, 4).map((day) => {
      const { patient } = day;

      return (
        <Link
          key={`menu-alert-${day.id}`}
          to={`/admin/patients/${patient?.id}/details`}
        >
          <li className="flex h-20 py-6 px-10 gap-6 items-center hover:bg-gray-200 cursor-pointer">
            {/* Avatar. */}
            <Avatar user={day.patient} />

            {/* Name/Message. */}
            <div className="flex flex-col w-48">
              <p className="font-bold text-primary">
                {patient?.first_name} {patient?.last_name}
              </p>
              <p className="text-sm font-medium line-clamp-2">
                {getAlertMessages(day)}
              </p>
            </div>

            {/* Time. */}
            <p className="ml-auto self-start text-xs font-light text-gray-400 italic">
              {day.answered_ago}
            </p>
          </li>
        </Link>
      );
    });
  });

  const urlsDashboardMenu = useMemo(() => {
    if (isAdmin) {
      return ['/admin/agencies'];
    }

    if (isAgency) {
      return [
        '/admin/agency',
        '/admin/patients',
        '/admin/agents',
        '/admin/agency-admin',
      ];
    }

    if (isPatient) {
      return ['/admin/patient'];
    }

    return ['/'];
  }, [isAdmin, isAgency, isPatient]);

  const isDashboardMenuActive = useMemo(() => {
    const reducer = (accumulator, url) => {
      const isMatch = pathname.startsWith(url);
      return accumulator || isMatch;
    };
    return urlsDashboardMenu.reduce(reducer, false);
  }, [pathname, urlsDashboardMenu]);

  const MobileMenu = memo(() => {
    const [isSearchOpened, setIsSearchOpened] = useState(false);
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const menuRef = useRef();
    const menuWrapperRef = useRef();

    const onMouseDown = (event) => {
      const isClickInside = menuRef.current.contains(event.target);
      if (isClickInside) {
        return;
      }

      setIsMenuOpened(false);
    };

    useEffect(() => {
      setTimeout(() => {
        const wrapper = menuWrapperRef.current;
        const menu = menuRef.current;
        if (!wrapper || !menu) {
          return;
        }

        if (isMenuOpened) {
          const showMenu = () => {
            wrapper.classList.remove('invisible', 'opacity-0');
            wrapper.classList.add('visible', 'opacity-1');
            menu.classList.remove('-translate-x-full');
          };
          showMenu();
          return;
        }

        const hideMenu = () => {
          wrapper.classList.remove('visible', 'opacity-1');
          wrapper.classList.add('invisible', 'opacity-0');
          menu.classList.add('-translate-x-full');
        };
        hideMenu();
      }, 200);
    }, [isMenuOpened]);

    useEffect(() => {
      if (isMenuOpened) {
        document.addEventListener('mousedown', onMouseDown);
      } else {
        document.removeEventListener('mousedown', onMouseDown);
      }

      return () => {
        document.removeEventListener('mousedown', onMouseDown);
      };
    }, [isMenuOpened]);

    const hasSearchValue = '' !== globalContext.searchValue;
    const searchIconBg = hasSearchValue ? 'bg-blue-100' : '';
    const searchIconColor = hasSearchValue ? 'text-primary' : 'text-gray-300';

    return (
      <div className="z-40">
        {/* Search modal. */}
        <ModalSearch
          isOpened={isSearchOpened}
          onCancel={() => setIsSearchOpened(false)}
          onOkay={(value) => {
            setIsSearchOpened(false);
            globalContext.setSearchValue(value);
          }}
        />

        {/* Menu: Mobile (top + side menu). */}
        <div className="flex md:hidden flex-col shadow-md">
          {/* Top Bar. */}
          {/* <div className="flex h-44 p-4 pb-5 flex-col text-white bg-primary-gradient"> */}
          <div className="flex p-4 pb-5 flex-col text-white bg-primary-gradient">
            {/* First Row. */}
            <div className="flex flex-grow items-center">
              {/* Hamburguer. */}
              <img
                alt="menu hamburger"
                className="h-5 cursor-pointer"
                onClick={() => setIsMenuOpened(!isMenuOpened)}
                src={IconHamburger}
              />

              {/* Alerts. */}
              {/* <div className="pr-6 ml-auto">
                            <div className="h-3.5 w-3.5 ml-2 -mt-1 bg-primary-light rounded-full absolute"></div>
                            <img className="h-5" src={IconBell} alt="alerts" />
                        </div> */}
              <NavLink className="pr-6 ml-auto" to="/admin/notifications">
                {hasAlerts ? (
                  <div className="h-3.5 w-3.5 ml-2 -mt-1 bg-primary-light rounded-full absolute" />
                ) : null}
                <img alt="alerts" src={IconBell} />
              </NavLink>

              {/* User's Avatar. */}
              <div className="flex">
                {api?.user?.avatar_url ? (
                  <img
                    alt="User's avatar"
                    className="w-11 h-11 rounded-full border-2 border-gray-300"
                    src={api?.user?.avatar_url}
                  />
                ) : (
                  <div className="flex w-11 h-11 bg-green-600 text-white font-bold rounded-full items-center justify-center">
                    {api?.user?.first_name?.charAt(0)}
                    {api?.user?.last_name?.charAt(0)}
                  </div>
                )}
              </div>
            </div>
            {/* /First Row. */}

            {/* <p className="pt-2.5 text-3xl font-extrabold">{globalContext?.globalTitle}</p>
                    <p className="pt-1 text-sm font-light">Hi, {api?.user?.first_name}</p> */}
          </div>
          {/* /Top Bar. */}

          {/* Filter Bar. */}
          <div className="h-16 flex md:hidden bg-white px-5 py-3 items-center">
            {/* Back button. */}
            <Link
              className="flex flex-row items-center"
              to={globalContext?.urlBack ?? '/'}
            >
              {globalContext?.urlBack ? (
                <img alt="Back button" className="h-5" src={ChrevronLeft} />
              ) : null}
              <h1 className="pl-4 text-xl font-extrabold">
                {globalContext?.globalTitle}
              </h1>
            </Link>

            {/* <img className="ml-auto h-4" src={IconFilter} alt="filter icon" /> */}
            {globalContext.enabledSearchBar ? (
              <div
                className={`ml-auto h-10 w-10 rounded-full flex items-center justify-center ${searchIconBg}`}
              >
                <IconSearch
                  alt="search icon"
                  className={`h-6 fill-current ${searchIconColor} cursor-pointer`}
                  onClick={() => setIsSearchOpened(true)}
                />
              </div>
            ) : null}
          </div>
          {/* /Filter Bar. */}
        </div>
        {/* /Menu: Mobile (top + side menu). */}

        {/* Opened Mobile Menu. */}
        <div
          className="fixed w-full top-0 invisible opacity-0 md:hidden transition-all duration-200"
          ref={menuWrapperRef}
        >
          {/* Black Overlay. */}
          <div className="fixed top-0 bottom-0 w-full bg-gray-800 bg-opacity-60" />

          {/* Menu. */}
          <div
            className="flex fixed top-0 bottom-0 w-full overflow-y-scroll max-w-xs transform-gpu -translate-x-full flex-col transition-all duration-300"
            ref={menuRef}
          >
            {/* First Row. */}
            <div className="flex h-36 items-center justify-center bg-primary-gradient p-4 pb-5 text-white">
              {/* Logo Agency. */}
              {/* <div className="flex h-20 w-20 p-1 rounded-lg border border-gray-300 bg-white">
                            <img src={LogoAgencyHHN} alt="logo agency" />
                        </div> */}

              {/* User's Name */}
              {/* <p className="pl-4 font-extrabold text-lg">Angels In Call</p> */}

              {/* Logo VoiceHealth. */}
              <img alt="logo voicehealth" src={LogoMobile} />
            </div>
            {/* /First Row. */}

            <div className="flex flex-grow bg-white">
              <ul className="flex flex-col w-full">
                {isAdmin || isAgency || isPatient ? (
                  <li>
                    {/* Item 1. */}
                    <NavLink
                      className={({ isActive }) =>
                        [
                          'py-6 pl-9 flex flex-row items-center',
                          isActive || isDashboardMenuActive
                            ? `bg-primary-lightest font-extrabold ${classes.active}`
                            : '',
                        ].join(' ')
                      }
                      end
                      to="/admin"
                      // isActive={() => isDashboardMenuActive}
                    >
                      <IconHome className="h-6 w-6 fill-current text-gray-400" />
                      <span className="pl-5 text-base text-gray-400">
                        Dashboard
                      </span>
                    </NavLink>
                  </li>
                ) : null}

                {isAdmin ? (
                  <>
                    {/* Item 2. */}
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          [
                            'py-6 pl-9 flex flex-row items-center',
                            isActive
                              ? `bg-primary-lightest font-extrabold ${classes.active}`
                              : '',
                          ].join(' ')
                        }
                        to="/admin/patients"
                      >
                        <IconPatients className="h-6 w-6 fill-current text-gray-400" />
                        <span className="pl-5 text-base text-gray-400">
                          Patients
                        </span>
                      </NavLink>
                    </li>

                    {/* Item 3. */}
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          [
                            'py-6 pl-9 flex flex-row items-center',
                            isActive
                              ? `bg-primary-lightest font-extrabold ${classes.active}`
                              : '',
                          ].join(' ')
                        }
                        to="/admin/questions"
                      >
                        <IconQuestions className="h-6 w-6 fill-current text-gray-400 stroke-current" />
                        <span className="pl-5 text-base text-gray-400">
                          Questions
                        </span>
                      </NavLink>
                    </li>

                    {/* Item 4. */}
                    <li>
                      <NavLink
                        className={({ isActive }) =>
                          [
                            'py-6 pl-9 flex flex-row items-center',
                            isActive
                              ? `bg-primary-lightest font-extrabold ${classes.active}`
                              : '',
                          ].join(' ')
                        }
                        to="/admin/users"
                      >
                        <IconUsers className="h-6 w-6 fill-current text-gray-400 stroke-current" />
                        <span className="pl-5 text-base text-gray-400">
                          Users
                        </span>
                      </NavLink>
                    </li>
                  </>
                ) : null}

                {isAdmin || isAgency ? (
                  <li>
                    {/* Item 5. */}
                    <NavLink
                      className={({ isActive }) =>
                        [
                          'py-6 pl-9 flex flex-row items-center',
                          isActive
                            ? `bg-primary-lightest font-extrabold ${classes.active}`
                            : '',
                        ].join(' ')
                      }
                      to="/admin/notifications"
                    >
                      <IconNotifications className="h-6 w-6 fill-current text-gray-400 stroke-current" />
                      <span className="pl-5 text-base text-gray-400">
                        Notifications
                      </span>
                    </NavLink>
                  </li>
                ) : null}

                <li>
                  {/* Item 6. */}
                  <NavLink
                    className={({ isActive }) =>
                      [
                        'py-6 pl-9 flex flex-row items-center',
                        isActive
                          ? `bg-primary-lightest font-extrabold ${classes.active}`
                          : '',
                      ].join(' ')
                    }
                    to="/admin/settings"
                  >
                    <IconSettings className="h-6 w-6 fill-current text-gray-400" />
                    <span className="pl-5 text-base text-gray-400">
                      Settings
                    </span>
                  </NavLink>
                </li>

                {/* <li className="pl-9 py-5">Patients</li>
                            <li className="pl-9 py-5">Users</li>
                            <li className="pl-9 py-5">Settings</li> */}

                {/* Last Item. */}
                <li
                  className="py-6 pl-9 flex flex-row items-center mt-auto cursor-pointer"
                  onClick={() => api.auth.signOut()}
                >
                  <IconLogout className="h-6 w-6 fill-current text-red-400" />
                  <span className="pl-5 text-base text-red-400">Logout</span>
                </li>
              </ul>
            </div>
          </div>
          {/* /Menu. */}
        </div>
        {/* /Opened Mobile Menu. */}
      </div>
    );
  });

  return (
    <div className="flex flex-col min-h-screen font-medium text-base text-gray-700 bg-gray-100">
      {' '}
      {/** Main container. */}
      <MobileMenu />
      {/* Top Menu: Desktop. */}
      <div className="md:flex flex-row hidden bg-white max-h-20 flex-grow pr-20">
        {/* Logo. */}
        <div className="flex flex-col p-5 items-center justify-center">
          <img alt="" src={Logo} />

          {/* Spinner. */}
          {/* {api?.isLoading ? (
                    <img src={IconLoading} className="ml-6 h-10 w-10 animate-spin" alt="loading" />
                    ): null} */}
        </div>

        {/* Right Top Menu. */}
        <div className="flex ml-auto items-center justify-center">
          {/* Notifications. */}
          <div className="flex h-full relative">
            {/* Toggle Icon. */}
            <div
              className={`flex h-full cursor-pointer ${
                isNotificationOpened ? 'bg-gray-50' : ''
              }`}
              onClick={() => {
                setIsNotificationOpened(!isNotificationOpened);
                setIsProfileOpened(false);
              }}
              ref={notificationToggleRef}
            >
              <div className="flex relative px-6 self-center">
                <img alt="" src={IconNotificationsTop} />

                {hasAlerts ? (
                  <span className="absolute right-5 -top-1 h-4 w-4 rounded-full border-2 border-white bg-primary" />
                ) : null}
              </div>
            </div>

            {/* Opened menu. */}
            <MenuDialog
              classWrapper="max-w-md w-screen "
              isOpened={isNotificationOpened}
              onClose={() => setIsNotificationOpened(false)}
              toggleRef={notificationToggleRef}
            >
              {/* Title. */}
              <div className="flex py-6 px-10 items-center justify-between border-b border-gray-200">
                <img alt="" className="w-4" src={IconBellBlue} />
                <h2 className="font-notosans text-center text-lg font-bold">
                  Notifications
                </h2>
                <img alt="" className="w-4" src={IconChecked} />
              </div>

              {/* Items. */}
              <ul className="w-full">
                <AlertList
                  alertDays={api.alertDays}
                  isLoading={isLoadingAlerts}
                />
              </ul>

              {/* Footer. */}
              <div className="flex py-4 px-10 items-center justify-center border-t border-gray-200">
                <Link
                  className="font-notosans text-primary text-primary text-sm font-bold"
                  to="/admin/notifications"
                >
                  See all notifications
                </Link>
              </div>
            </MenuDialog>
          </div>

          {/* Profile menu (avatar + name). */}
          <div
            className={`flex relative h-full p-5 ${
              isProfileOpened ? 'bg-gray-50' : ''
            }`}
          >
            {/* Toggle */}
            <div
              className="flex items-center cursor-pointer"
              onClick={() => {
                setIsProfileOpened(!isProfileOpened);
                setIsNotificationOpened(false);
              }}
              ref={profileToggleRef}
            >
              {/* User's Avatar. */}
              <div className="flex">
                {api?.user?.avatar_url ? (
                  <img
                    alt="User's avatar"
                    className="w-11 h-11 rounded-full border-2 border-gray-300"
                    src={api?.user?.avatar_url}
                  />
                ) : (
                  <div className="flex w-11 h-11 bg-green-600 text-white font-bold rounded-full items-center justify-center">
                    {api?.user?.first_name?.charAt(0)}
                    {api?.user?.last_name?.charAt(0)}
                  </div>
                )}
              </div>

              {/* User's Name. */}
              <div className="font-notosans flex text-gray-700 font-bold text-lg pl-4">
                <span>{api?.user?.first_name}</span>

                {/* Chevron down. */}
                <img
                  alt="open profile menu"
                  className="w-3 mt-1 ml-1.5"
                  src={IconChevronDown}
                />
              </div>
            </div>

            {/* Opened menu. */}
            <MenuDialog
              classWrapper="w-60 py-5 px-1"
              isOpened={isProfileOpened}
              onClose={() => setIsProfileOpened(false)}
              toggleRef={profileToggleRef}
              trianglePosition="right"
            >
              <nav className="w-full">
                <ul>
                  <li>
                    <Link
                      className="px-8 py-3 h-full w-full inline-block hover:bg-gray-200"
                      to="/admin/settings"
                    >
                      Edit Profile
                    </Link>
                  </li>
                  <li>
                    <span
                      className="px-8 py-3 h-full w-full inline-block hover:bg-gray-200 cursor-pointer"
                      onClick={() => api.auth.signOut()}
                    >
                      Logout
                    </span>
                  </li>
                </ul>
              </nav>
            </MenuDialog>
          </div>
        </div>
      </div>
      {/* /Top Menu: Desktop. */}
      {/* Under top menu. */}
      <div className="flex flex-row flex-grow">
        {/* Side Menu: Desktop. */}
        <div className="md:flex hidden flex-col w-24 xl:w-36 md:h-screen-wo-header bg-white">
          {isAdmin || isAgency || isPatient ? (
            <>
              {/* Item 1. */}
              <NavLink
                className={({ isActive }) =>
                  [
                    'flex flex-col w-full h-24 xl:h-32 items-center justify-center',
                    isActive || isDashboardMenuActive
                      ? `bg-primary-lightest ${classes.active}`
                      : '',
                  ].join(' ')
                }
                end
                to="/admin"
              >
                <IconHome className="h-10 fill-current text-gray-400" />
                <span className="pt-4 text-sm text-gray-400">Dashboard</span>
              </NavLink>
            </>
          ) : null}

          {isAdmin ? (
            <>
              {/* Item 2. */}
              <NavLink
                className={({ isActive }) =>
                  [
                    'flex flex-col w-full h-24 xl:h-32 items-center justify-center',
                    isActive ? `bg-primary-lightest ${classes.active}` : '',
                  ].join(' ')
                }
                to="/admin/patients"
              >
                <IconPatients className="h-10 fill-current text-gray-400" />
                <span className="pt-4 text-gray-400 text-sm">Patients</span>
              </NavLink>

              {/* Item 3. */}
              <NavLink
                className={({ isActive }) =>
                  [
                    'flex flex-col w-full h-24 xl:h-32 items-center justify-center',
                    isActive ? `bg-primary-lightest ${classes.active}` : '',
                  ].join(' ')
                }
                to="/admin/questions"
              >
                <IconQuestions className="h-10 fill-current text-gray-400 stroke-current" />
                <span className="pt-4 text-gray-400 text-sm">Questions</span>
              </NavLink>

              {/* Item 4. */}
              <NavLink
                className={({ isActive }) =>
                  [
                    'flex flex-col w-full h-24 xl:h-32 items-center justify-center',
                    isActive ? `bg-primary-lightest ${classes.active}` : '',
                  ].join(' ')
                }
                to="/admin/users"
              >
                <IconUsers className="h-10 fill-current text-gray-400 stroke-current" />
                <span className="pt-4 text-gray-400 text-sm">Users</span>
              </NavLink>
            </>
          ) : null}

          {isAdmin || isAgency ? (
            <>
              {/* Item 5. */}
              <NavLink
                className={({ isActive }) =>
                  [
                    'flex flex-col w-full h-24 xl:h-32 items-center justify-center',
                    isActive ? `bg-primary-lightest ${classes.active}` : '',
                  ].join(' ')
                }
                to="/admin/notifications"
              >
                <IconNotifications className="h-10 fill-current text-gray-400 stroke-current" />
                <span className="pt-4 text-gray-400 text-sm">
                  Notifications
                </span>
              </NavLink>
            </>
          ) : null}

          {/* Last Item. */}
          <NavLink
            className={({ isActive }) =>
              [
                'flex flex-col w-full h-24 xl:h-32 items-center justify-center mt-auto',
                isActive ? `bg-primary-lightest ${classes.active}` : '',
              ].join(' ')
            }
            to="/admin/settings"
          >
            <IconSettings className="h-10 fill-current text-gray-400" />
            <span className="pt-4 text-gray-400 text-sm">Settings</span>
          </NavLink>
        </div>
        {/* /Side Menu. */}

        <div
          className="flex flex-col w-full px-0 lg:px-10 xl:px-20 md:h-screen-wo-header md:overflow-auto"
          id="main-area"
        >
          {/* Main Area. */}
          <UserRoutes role={api?.user?.role} user={api?.user} />
          {/* /Main Area. */}
        </div>
      </div>
      {/* /Under top menu. */}
      {/* Spinner. */}
      {api?.isLoading ? (
        <div className="flex absolute py-2 px-8 bottom-5 right-5 bg-purple-100 rounded justify-center items-center">
          <span className="ml-3 font-bold text-purple-600">Loading ...</span>
          <img
            alt="loading"
            className="ml-4 h-10 w-10 animate-spin"
            src={IconLoading}
          />
        </div>
      ) : null}
      {/** /Main container. */}
    </div>
  );
}

const Avatar = memo((props) => {
  const { user } = props;

  const hasNoAvatar = !user.avatar_url;
  if (hasNoAvatar) {
    return (
      <div className="w-14 h-14">
        <span className="w-14 h-14 rounded-full text-xl bg-green-600 text-white font-bold flex justify-center items-center">
          {user?.first_name[0]}
          {user?.last_name[0]}
        </span>
      </div>
    );
  }

  return (
    <img
      alt="User's avatar"
      className="h-14 w-14 rounded-full"
      src={user.avatar_url}
    />
  );
});

const getAlertMessages = (alertDay) => {
  const messages = alertDay.answers.map((answer) => {
    const hasHitThreshold = answer.is_threshold_hit;
    if (!hasHitThreshold) {
      return null;
    }

    const isWeight = 1 === answer.question.id;
    if (isWeight) {
      return `Weight Change`;
    }

    const isBoolean = 'boolean' === answer.question.type;
    if (isBoolean) {
      const isTrue = !!answer.value;
      if (isTrue) {
        return answer.question.label;
      }
      return `no ${answer.question.label}`;
    }

    return `${answer.question.label} to ${answer.value}`;
  });

  return `Has ` + messages.filter((n) => n).join(', ');
};

export default memo(AdminLayout);
