import { memo } from 'react';
import { Link } from 'react-router-dom';

import IconArrowLeft from './../../../assets/images/icon-left-arrow.svg';

function BackBar(props) {
  const { logo, title, url } = props;

  return (
    <Link to={url ?? '/'}>
      <div className="flex flex-row my-4 items-center">
        {/* Back Icon. */}
        {url ? (
          <img alt="Back button" className="w-6 mr-4" src={IconArrowLeft} />
        ) : null}

        {/* Logo. */}
        {logo ? (
          <div className="flex h-14 w-14 rounded border border-gray-300 bg-white items-center justify-center p-1">
            {logo}
          </div>
        ) : null}

        {/* Page Back Title */}
        <h1 className="ml-4 text-2xl">{title}</h1>
      </div>
    </Link>
  );
}

export default memo(BackBar);
