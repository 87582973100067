import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

import Input from '../../../../shared-components/Form/Input';
import SelectFormik from '../../../../shared-components/Form/Input/SelectFormik';
import ModalInfo from '../../../../shared-components/ModalInfo';
import ChrevronLeft from './../../../../assets/images/icon-chevron-left.svg';
import useEffectOnce from '../../../../utils/useEffectOnce';
import ActionButtons from '../../../../shared-components/Form/ActionButtons';
import { ApiContext } from '../../../../contexts/api';
import FormSkeleton from '../../../../shared-components/Form/FormSkeleton';
import AnimatedScreen from '../../../../shared-components/AnimatedScreen';
import Tabs from '../../../../shared-components/Form/Tabs';
import { GlobalContext } from '../../../../contexts/global';
import { getBoolean } from '../../../../utils/apiNormalization';

function QuestionFormScreen() {
  const { questionId } = useParams();
  const isEditing = !!questionId;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [questionGroups, setQuestionGroups] = useState([]);
  const [question, setQuestion] = useState();
  const [modal, setModal] = useState(null);
  const inputAutoFocusRef = useRef();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const api = useContext(ApiContext);
  const globalContext = useContext(GlobalContext);

  const pageTitle = isEditing ? 'Edit Question' : 'Add Question';

  const fetchQuestion = async (id) => {
    const apiResult = await api.http.get(`/admin/questions/${id}`);
    setQuestion(apiResult);
  };

  const saveQuestion = async ({ id, ...params }) => {
    const baseUrl = '/admin/questions';
    const apiResult = id
      ? await api.http.put(`${baseUrl}/${id}`, params)
      : await api.http.post(baseUrl, params);
    setQuestion(apiResult);
  };

  const fetchQuestionGroups = async () => {
    const apiResult = await api.http.get('/admin/question-groups');
    setQuestionGroups(apiResult);
  };

  const closeModal = () => {
    setModal(null);
    inputAutoFocusRef.current.focus();
  };

  const urlBack = useMemo(() => {
    return `/admin/questions`;
  }, []);

  const goBack = () => {
    navigate(urlBack);
  };

  useEffect(() => {
    const setGlobalToolbar = () => {
      globalContext.setGlobalTitle(pageTitle);
      globalContext.setUrlBack(urlBack);
      globalContext.setEnabledSearch(false);
    };
    setGlobalToolbar();
  }, [globalContext, urlBack, pageTitle]);

  const onScreenLoad = async () => {
    try {
      await fetchQuestionGroups();

      if (isEditing) {
        await fetchQuestion(questionId);
      }

      setIsLoading(false);
    } catch (message) {
      setModal({
        isError: true,
        message,
        onClose: goBack,
      });
    }
  };

  useEffectOnce(() => {
    onScreenLoad();

    return () => api.http.cancelAll();
  });

  const validationSchema = useMemo(() => {
    const requiredText = 'This is a required field.';
    return yup.object().shape({
      is_editing: yup.boolean(),
      id: yup.number().positive().integer(),
      label: yup.string().trim().min(2).required(requiredText),
      assistant_label: yup.string().trim().min(2).required(requiredText),
      type: yup.string().trim().min(2).required(requiredText),
      question_group_id: yup
        .number()
        .positive()
        .integer()
        .required(requiredText),
      is_alert_enabled: yup.boolean().required(),
      alert_condition_operator: yup
        .string()
        .trim()
        .when(['is_alert_enabled', 'type'], {
          is: (is_alert_enabled, type) =>
            is_alert_enabled && type === 'integer',
          then: yup.string().trim().required(requiredText),
        }),
      alert_condition_value1: yup
        .string()
        .trim()
        .when(['is_alert_enabled', 'type'], {
          is: (is_alert_enabled, type) =>
            is_alert_enabled && (type === 'boolean' || type === 'integer'),
          then: yup.string().trim().required(requiredText),
        }),
      alert_condition_value2: yup
        .string()
        .trim()
        .when(['is_alert_enabled', 'type', 'alert_condition_operator'], {
          is: (is_alert_enabled, type, alert_condition_operator) =>
            is_alert_enabled &&
            type === 'integer' &&
            (alert_condition_operator === 'inside_range' ||
              alert_condition_operator === 'outside_range'),
          then: yup.string().trim().required(requiredText),
        }),
    });
  }, []);

  const isAlertEnabled = question?.global_alert_settings?.is_alert_enabled;
  const initialValues = {
    is_editing: isEditing,
    id: questionId,
    label: isEditing ? question?.label : '',
    assistant_label: isEditing ? question?.assistant_label : '',
    type: isEditing ? question?.type : '', // boolean, weight, integer
    question_group_id: isEditing ? question?.group_id : '',
    is_alert_enabled: isEditing
      ? question?.global_alert_settings?.is_alert_enabled ?? false
      : false,
    alert_condition_operator:
      isEditing && isAlertEnabled
        ? question?.global_alert_settings?.alert_condition_operator
        : '',
    alert_condition_value1:
      isEditing && isAlertEnabled
        ? question?.global_alert_settings?.alert_condition_value1 ?? ''
        : '',
    alert_condition_value2:
      isEditing && isAlertEnabled
        ? question?.global_alert_settings?.alert_condition_value2 ?? ''
        : '',
  };

  const submitForm = async (values, { setErrors }) => {
    const validateValues = (values) => {
      const clonedValues = { ...values };
      return validationSchema.cast(clonedValues);
    };

    const removeValuesIfBlank = (values) => {
      for (const field in values) {
        const isBlank = '' === `${values[field]}`.trim();
        if (isBlank) {
          delete values[field];
        }
      }
      return values;
    };

    const appendValuesConditionally = (values) => {
      const isWeight = values.type === 'weight';
      if (isWeight) {
        const weightNumber = Math.abs(values.alert_condition_value2) ?? 0;
        values.alert_condition_operator = 'diff_outside_range';
        values.alert_condition_value1 = -weightNumber;
        values.alert_condition_value2 = +weightNumber;
        return;
      }

      const isBoolean = values.type === 'boolean';
      if (isBoolean) {
        values.alert_condition_operator = 'boolean_eq';
        values.alert_condition_value1 = getBoolean(
          values.alert_condition_value1
        );
        values.alert_condition_value2 = null;
        return;
      }

      const isBloodPressure = values.type === 'blood_pressure';
      if (isBloodPressure) {
        values.alert_condition_operator =
          'uppervalue_greaterthan_or_lowervalue_lessthan';
        return;
      }

      return values;
    };

    try {
      const clonedValues = removeValuesIfBlank({ ...values });
      const validValues = validateValues(clonedValues);
      appendValuesConditionally(validValues);

      await saveQuestion(validValues);

      setModal({
        message: 'Success!',
        onClose: goBack,
      });
    } catch (errors) {
      console.log(errors);
      const isInternalError = 500 === api.http.getLastHttpStatus();
      const message = isInternalError
        ? errors
        : 'There are errors on the form.';
      setModal({
        isError: true,
        message: message,
        onClose: closeModal,
      });
      setErrors(errors);
    }
  };

  const getQuestionGroupSelectOptions = (groups) => {
    return groups.map((group) => ({
      label: group.label,
      value: group.id,
    }));
  };
  const questionGroupOptions = useMemo(
    () => getQuestionGroupSelectOptions(questionGroups),
    [questionGroups]
  );

  const typeOptions = [
    { label: 'Numerical Response', value: 'integer' },
    { label: 'Yes/No Response', value: 'boolean' },
    { label: 'Weight', value: 'weight' },
    { label: 'Blood Pressure', value: 'blood_pressure' },
  ];

  const enabledOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  const integerConditionOptions = [
    { label: 'More than', value: 'greater_than' },
    { label: 'Less than', value: 'less_than' },
    { label: 'In the range', value: 'inside_range' },
    { label: 'Out the range', value: 'outside_range' },
  ];

  const getMainArea = () => {
    if (isLoading) {
      return <FormSkeleton />;
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={submitForm}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, values }) => (
          <Form className="max-w" id="form-question">
            {/* Tabs. */}
            <Tabs
              activeTabIndex={activeTabIndex}
              classActive="text-primary border-primary font-extrabold"
              onSelect={(index) => setActiveTabIndex(index)}
            >
              {/* Tab Content. */}
              <div
                className="flex pb-20 flex-col max-w-xl"
                label="Question Information"
              >
                {/* Section */}
                <h2 className="pt-10 text-2xl">Question Information</h2>

                <Input
                  autoFocus
                  innerRef={inputAutoFocusRef}
                  label="Label"
                  name="label"
                  placeholder="ex. Swelling"
                  required
                />

                <Input
                  label="Alexa Question"
                  name="assistant_label"
                  placeholder="ex. Did you experience any Swelling yesterday?"
                  required
                />

                <Input
                  as={SelectFormik}
                  label="Type"
                  name="type"
                  options={typeOptions}
                  required
                />

                <Input
                  as={SelectFormik}
                  label="Group"
                  name="question_group_id"
                  options={questionGroupOptions}
                  required
                />
              </div>
              {/* /Tab Content.  */}

              {/* Tab Content. */}
              <div
                className="flex pb-20 flex-col max-w-xl"
                label="Notification Settings"
              >
                {/* Section */}
                <h2 className="pt-10 text-2xl">Notification Settings</h2>

                <Input
                  as={SelectFormik}
                  label="Trigger Notification?"
                  name="is_alert_enabled"
                  options={enabledOptions}
                  required
                />

                <div className={values.is_alert_enabled ? '' : 'hidden'}>
                  <div className={'weight' === values.type ? '' : 'hidden'}>
                    <Input
                      label="Notify when difference from previous day is greater than"
                      name="alert_condition_value2"
                      type="number"
                    />
                  </div>

                  <div className={'boolean' === values.type ? '' : 'hidden'}>
                    <Input
                      as={SelectFormik}
                      label="Notify when answer is"
                      name="alert_condition_value1"
                      options={enabledOptions}
                    />
                  </div>

                  <div
                    className={'blood_pressure' === values.type ? '' : 'hidden'}
                  >
                    <Input
                      label="Upper value (systolic) is greater than"
                      name="alert_condition_value1"
                      type="number"
                    />

                    <Input
                      label="OR Lower value (diastolic) is less than"
                      name="alert_condition_value2"
                      type="number"
                    />
                  </div>

                  <div className={'integer' === values.type ? '' : 'hidden'}>
                    <Input
                      as={SelectFormik}
                      label="Notify when answer is"
                      name="alert_condition_operator"
                      options={integerConditionOptions}
                    />

                    {'greater_than' === values.alert_condition_operator && (
                      <Input
                        label="Value"
                        name="alert_condition_value1"
                        type="number"
                      />
                    )}

                    {'less_than' === values.alert_condition_operator && (
                      <Input
                        label="Value"
                        name="alert_condition_value1"
                        type="number"
                      />
                    )}

                    {'inside_range' === values.alert_condition_operator && (
                      <>
                        <Input
                          label="More than"
                          name="alert_condition_value2"
                          type="number"
                        />

                        <Input
                          label="AND Less than"
                          name="alert_condition_value1"
                          type="number"
                        />
                      </>
                    )}

                    {'outside_range' === values.alert_condition_operator && (
                      <>
                        <Input
                          label="Less than"
                          name="alert_condition_value1"
                          type="number"
                        />

                        <Input
                          label="OR More than"
                          name="alert_condition_value2"
                          type="number"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* /Tab Content.  */}
            </Tabs>

            <ActionButtons
              formId="form-question"
              isSubmitting={isSubmitting}
              labelSubmit={isEditing ? 'Save Changes' : 'Create Question'}
              onClickCancel={goBack}
            />
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <AnimatedScreen>
      <div className="md:my-8 md:pb-12">
        {/* Error and Success Modal. */}
        <ModalInfo
          isError={modal?.isError}
          isOpened={!!modal}
          onClose={modal?.onClose}
          title={modal?.message}
        />

        {/* Card. */}
        <div className="flex flex-col px-5 md:px-14 pt-8 pb-16 md:rounded-lg bg-white shadow-custom">
          {/* Back button. */}
          <Link className="hidden md:flex flex-row items-center" to={urlBack}>
            <img alt="Back button" className="h-5" src={ChrevronLeft} />
            <h1 className="pl-6 text-2xl font-black uppercase">{pageTitle}</h1>
          </Link>

          {getMainArea()}
        </div>
        {/* /Card. */}
      </div>
    </AnimatedScreen>
  );
}

export default memo(QuestionFormScreen);
